<script>
    import Vue from 'vue'
    import {mapState} from "vuex";

    export default{
        props: {
            dietPlanInfo: {
                default: () => {
                    return {}
                },
                type: Object
            },
        },

      computed:{
        ...mapState({
          sports: state => state.sports._sports
        })
      },

        data(){
            return{
                dayExercises: [],
                addingSport: false,
                rendered: false,
            }
        },
        created(){
            // fill
            for(let i=0;i<7;i++){
                this.dayExercises[i] = {};
            }
            this.fillExercisesArray();
        },

        methods:{

            fillExercisesArray(){

                for(let i=0;i<7;i++){
                        Vue.set(this.dayExercises, i, this.dietPlanInfo.exercises.find(exercise => {return exercise.day === i}));
                        this.fetchExerciseSports(this.dayExercises[i].id, i);
                    }
                this.rendered = true;
            },


            fetchExerciseSports(exerciseId, day){

                this.$axios.get(`/exercises/${exerciseId}/sports`).then(result =>{
                    let sports = result.data.exerciseSports;
                    for(let sport of sports){
                        sport.sport = JSON.parse(JSON.stringify(this.sports.find(sp => sp.id === sport.sportId )));
                    }

                    Vue.set(this.dayExercises[day], 'sports', sports);
                })

            },
        }
    }
</script>
<template>

    <div id="diet-exercises" class="card-body">

        <div v-if="rendered" class="row" style="font-size:13px;">
            <div v-for="ex in dayExercises" :key="ex.id" class="col-md-6 exercise" style="padding:10px;">

                <div class="title-exercise">{{dayNames[ex.day]}}  <span v-if="dietPlanInfo.dietPlanFunc.caloricNeeds[ex.day] > 0">({{ dietPlanInfo.dietPlanFunc.caloricNeeds[ex.day].toFixed(2)}} kcal) </span></div>
                   <div v-if="ex.sports && ex.sports.length > 0">
                        <div v-for="sport in ex.sports" :key="sport.id" class="sport">
                            <div class="row">
                                <div class="col-md-8">
                                    <i class="icon-energy"></i> Άθλημα: <strong> {{sport.sport.name}}</strong><br>
                                    <i class="icon-clock"></i> Διάρκεια: <strong>{{sport.duration}} λεπτά</strong>
                                </div>
                            </div>
                        </div>
                   </div>
                <div v-else>
                    -
                </div>


            </div>


        </div>

    </div>

</template>
