<template>

  <b-overlay :show="isLoading" rounded="sm">
    <b-form @submit.prevent="$emit('updateDietPlan')" @change="$emit('autoUpdateDietPlan')">
      <div class="row">
        <div class="col-md-7">
          <b-form-group label="Όνομα διατροφικού πλάνου:" label-for="edit-name">
            <b-input-group>
              <b-form-input id="edit-name" v-model="dietPlanInfo.name" type="text" :disabled="dietPlanInfo.isSystem" ></b-form-input>
            </b-input-group>
          </b-form-group>
          <div>
            <label for="showOptions"> Προβολή <strong>επιλογών</strong> έναντι ημερών; </label>
            <b-form-checkbox id="showOptions" v-model="dietPlanInfo.showOptions" name="showOptions" switch size="lg" inline :disabled="dietPlanInfo.isSystem" ></b-form-checkbox>

          </div>
          <div v-if="!dietPlanInfo.isTextOnly">
            <label for="shareToMobile"> Κοινοποίηση σε Mobile εφαρμογή; </label>
            <b-form-checkbox id="shareToMobile" v-model="dietPlanInfo.shareToMobile"
                             name="shareToMobile" switch size="lg" inline
                             :disabled="dietPlanInfo.isSystem || !dietPlanInfo.athleteId" ></b-form-checkbox>

          </div>

        </div>
        <div  v-if="!dietPlanInfo.isTextOnly" class="col-md-5">
          <strong>Ρυθμίσεις:</strong><br>
          <div>
            <label for="veganDietPlan"><img src="@assets/images/icons/icon-vegan.png" width="50px" alt="icon-vegan"/> Vegan διατροφολόγιο; </label>
            <b-form-checkbox id="veganDietPlan" v-model="dietPlanInfo.isVegan" name="veganDietPlan" switch size="lg" inline :disabled="dietPlanInfo.isSystem" ></b-form-checkbox>

          </div>
          <div>
            <label for="fastingDP"><img src="@assets/images/icons/icon-fasting.png" width="50px" alt="icon-fasting"/> Νηστεία; </label>
            <b-form-checkbox id="fastingDP" v-model="dietPlanInfo.isFasting" name="fastingDP" switch size="lg" inline :disabled="dietPlanInfo.isSystem" ></b-form-checkbox>

          </div>
          <div>
            <label for="carbohydration"><img src="@assets/images/icons/icon-carbohydration.png" width="50px" alt="icon-carbohydration"/> Υδατανθράκωση; </label>
            <b-form-checkbox id="carbohydration" v-model="dietPlanInfo.isCarbohydration" name="carbohydration" switch size="lg" inline :disabled="dietPlanInfo.isSystem"></b-form-checkbox>
            <b-form-group v-if="dietPlanInfo.isCarbohydration">
              Επιλέξτε ημέρα υδατανθράκωσης:
              <b-form-radio-group id="carb-day" v-model="dietPlanInfo.carbohydrationDay" :disabled="dietPlanInfo.isSystem" >
                <b-form-radio value='0'>Δευτέρα</b-form-radio>
                <b-form-radio value="1">Τρίτη</b-form-radio>
                <b-form-radio value="2">Τετάρτη</b-form-radio>
                <b-form-radio value="3">Πέμπτη</b-form-radio>
                <b-form-radio value="4">Παρασκευή</b-form-radio>
                <b-form-radio value='5'>Σάββατο</b-form-radio>
                <b-form-radio value='6'>Κυριακή</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-alert :show="dietPlanInfo.isCarbohydration && !dietPlanInfo.dietPlanFunc.weight" variant="danger">
              <img src="@assets/images/icons/icon-carbohydration.png" width="50px" alt="icon-carbohydration"/>
              Δεν έχετε εισάγει το βάρος του πελάτη ώστε να λειτουργήσει ορθά η υδατανθράκωση.
              <span class="link" @click="$emit('updateTab', 1)"><strong> Κάντε κλικ εδώ για να το εισάγετε!</strong></span>
            </b-alert>
            <div>
              <label for="ketoDiet"><img src="@assets/images/icons/keto-icon.png" width="50px" alt="icon-fasting"/> Κετογονική διατροφή; </label>
              <b-form-checkbox id="ketoDiet" v-model="dietPlanInfo.isKeto" name="ketoDiet" switch size="lg" inline :disabled="dietPlanInfo.isSystem" ></b-form-checkbox>
              <b-alert :show="dietPlanInfo.isKeto" variant="warning">Μην ξεχάσετε να ρυθμίσετε τα ποσοστά κατανομής για την κέτωση κατάλληλα
                <span class="link"@click="$emit('updateTab', 2)"><strong>κάνοντας κλικ εδώ.</strong></span></b-alert>
            </div>

            <div>
              <label for="diabetes"><img src="@assets/images/icons/icon-diabetes.svg" width="50px" style="padding: 0 7.5px; " alt="icon-fasting"/> Ινσουλινοεξαρτόμενος διαβήτης; </label>
              <b-form-checkbox id="diabetes" v-model="dietPlanInfo.is_diabetes" name="diabetes" switch size="lg" inline :disabled="dietPlanInfo.isSystem" ></b-form-checkbox>

              <div v-if="dietPlanInfo.is_diabetes" class="pt-2">
                <label class="mb-0" style="font-size:0.6rem">
                  Εισάγετε την αναλογία υδατάνθρακα για κάθε μονάδα ινσουλίνης. Π.χ. για 1:15 εισάγετε 15
                </label>
                <b-form-input v-model="dietPlanInfo.insulin_ratio" class="mb-3"
                              placeholder="π.χ. 15"></b-form-input>
                <b-alert show variant="warning">Μην ξεχάσετε να ρυθμίσετε την αναλογία ινσουλίνης:υδατάνθρακα.
                  Συμβουλευτείτε τις οδηγίες του ιατρού του διαιτώμενου σχετικά με την αναλογία. Μπορείτε να μάθετε περισσότερες πληροφορίες στις οδηγίες για διατροφολόγους του Pathisis.</b-alert>
              </div>

            </div>
          </div>


        </div>
      </div>
    </b-form>


    <div>
      <div class="meal-options mt-3ed">


        <div class="row">
          <div class="meals-ordering col-md-6">
            <label class="font-weight-bold">{{$t('diet_plan.meals.settings_title')}}</label>
            <draggable v-model="dietPlanInfo.dietPlanMealOptions.mealsOrder" @update="updateDietPlanMealOptions" handle=".handle">

              <div v-if="meal < dietPlanInfo.dietPlanMealOptions.totalMeals" v-for="(meal) in dietPlanInfo.dietPlanMealOptions.mealsOrder" class="handle d-flex align-items-center border my-2 px-3 py-1 rounded">

                <span class="icon-menu"></span>
                <span v-if="meal >=5" class="flex-shrink-0 mx-2">
                  ({{$t('diet_plan.meals.extra')}} #{{meal === 5 ? '1' : '2'}})
                </span>
                <b-form-input
                    v-model="dietPlanInfo.dietPlanMealOptions.mealNames[meal]"
                    type="text" :disabled="dietPlanInfo.isSystem"
                    :placeholder="$t('diet_plan.meals.' + meal)" class="border-0" @change="updateDietPlanMealOptions"></b-form-input>



              </div>
            </draggable>

            <div class="meals-count">
              <b-button v-if="dietPlanInfo.dietPlanMealOptions.totalMeals > 5" variant="outline-primary" class="my-3 mr-2" @click="removeMeal"  >
                {{$t('diet_plan.meals.remove.btn')}}
              </b-button>
              <b-button v-if="dietPlanInfo.dietPlanMealOptions.totalMeals <7" variant="primary" class="my-3" @click="addMeal" >
                {{$t('diet_plan.meals.add')}}
              </b-button>

            </div>
          </div>

        </div>
      </div>
    </div>
  </b-overlay>

</template>

<script>
import draggable from 'vuedraggable'
export default{

  components: {draggable},
  props:{
    dietPlanInfo: {
      type: Object,
      required: true
    },
    isLoading: Boolean
  },
  data(){
    return{
      initialMealCount: this.dietPlanInfo.dietPlanMealOptions.totalMeals,
      mealId: null,
      updateMealsCount: false
    }
  },

  methods:{

    addMeal(){
      if(this.dietPlanInfo.dietPlanMealOptions.totalMeals === 7) return;
      this.dietPlanInfo.dietPlanMealOptions.totalMeals += 1;
      this.updateMealsCount = true;
      this.updateDietPlanMealOptions();
    },
    removeMeal(){
      if(this.dietPlanInfo.dietPlanMealOptions.totalMeals === 5) return;
      let message = {
        title: this.$t('diet_plan.meals.remove.modal_title'),
        body: this.$t('diet_plan.meals.remove.modal_body')
      };
      this.$dialog.confirm(message, {
        html: true,
        loader: true,
        okText: this.$t('diet_plan.meals.remove.delete_button'),
        cancelText: this.$t('diet_plan.meals.remove.cancel'),
        backdropClose: true
      }).then((dialog) => {
        this.dietPlanInfo.dietPlanMealOptions.totalMeals -= 1;
        this.updateMealsCount = true;
        this.updateDietPlanMealOptions();
        dialog.close();
      }).catch(()=>{});

    },
    updateDietPlanMealOptions(){
      this.isLoading = true;
      this.$axios.put(`/dietPlan/${this.dietPlanInfo.id}/meal-options`,
          {dietPlanMealOptions: this.dietPlanInfo.dietPlanMealOptions, initialMealCount: this.initialMealCount}).then(res=>{

        if(this.updateMealsCount) this.$emit('mealsCountUpdated');
        this.initialMealCount = res.data.totalMeals;
        this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: this.$t('success.text')});

      }).catch(e=>{
        this.dietPlanInfo.dietPlanMealOptions.totalMeals = this.initialMealCount;
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      }).finally(()=>{
        this.isLoading = false;
        this.updateMealsCount = false;
      })
    },
  }
}
</script>