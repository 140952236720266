<template>
  <table v-if="!onePagePerDay" id="dietPlan-table" class="table table-responsive table-borderless" style="table-layout:fixed;">
    <tbody id="print-diet-plan-table">
    <td style="border:0!important;background-color:transparent!important;" class="table-meal-day-name"></td>
    <td v-for="id in getActualHeaders" :key="id" class="meal-type-name" :style="`background-color:${options.colors.tableHeader};
            color:${options.colors.text.leftTitles};
              letter-spacing: ${options.letterSpacing.leftTitles}px;font-size:${options.sizes.text.leftTitles}px;padding:10px!important;`">
      {{getMealName(dietPlanInfo, id)}}
    </td>
    <tr v-for="(day,index) in getDietPlanDays" :key="`day-`+index" :style="getTRStyles(index)">
      <th class="table-meal-day-name" :style="`background-color:${options.colors.tableHeader};color:${options.colors.text.leftTitles};
              letter-spacing: ${options.letterSpacing.leftTitles}px;font-size:${options.sizes.text.leftTitles}px;`">

        {{showNames ? options.translations.dayNames[day.day] : dayNamesAlt[day.day]}}</th>

      <td v-for="(col, idx2) in getActualRows(day.rows)" :key="idx2" class="table-meal" :style="getTDStyles(index, idx2)">
        <div :class="options.showCategoryColors ? 'category-colored' :''" style="padding:3px">

          <div v-for="mealfood in dietPlan[day.day][col.type].foods" :key="mealfood.id" class="table-meal-food" :class="'meal-category-' + mealfood.category"
               :style="`font-size: ${options.sizes.text.foodText}px;color: ${options.colors.text.foodText};
                                    letter-spacing: ${options.letterSpacing.foodText}px; padding-bottom: ${options.bottomSpacing.foodText}px}`">
            {{formatName(mealfood)}} <span v-if="options.showQuantities && !mealfood.hideQuantity">{{ formatFoodUnits(mealfood.food)}}</span>


          </div>
          <div v-if="dietPlan[day.day][col.type].notes && !dietPlanInfo.isTextOnly" class="table-meal-food">
            <strong>Σημειώσεις:</strong> {{dietPlan[day.day][col.type].notes}}
          </div>
          <div v-if="dietPlan[day.day][col.type].notes && dietPlanInfo.isTextOnly" :style="`font-size: ${options.sizes.text.foodText}px!important`"
               v-html="dietPlan[day.day][col.type].notes">
          </div>
          <div v-if="dietPlanInfo.is_diabetes" class="border-top mt-2 py-1">
            <div class="pl-1 table-meal-food" :style="`font-size: ${options.sizes.text.foodText}px!important;color: ${options.colors.text.foodText};`">
            Μονάδες ινσουλίνης: {{getInsulinRatio(day.day, col.type)}}</div>
          </div>

        </div>

      </td>
    </tr>
    </tbody>
  </table>
  <div v-else>
      <div v-for="(day,index) in getDietPlanDays" :key="`day-`+index">
        <div v-if="index > 0" style="page-break-before:always; clear:both"></div>

        <section class="pdf-item">
          <div class="table-meal-day-name" :style="`padding:10px!important;padding-right:initial!'}important;width:100%!important;background-color:${options.colors.tableHeader};color:${options.colors.text.leftTitles};
            letter-spacing: ${options.letterSpacing.leftTitles}px;font-size:${options.sizes.text.leftTitles}px;`">
            <span>{{showNames ? options.translations.dayNames[day.day] : dayNamesAlt[day.day]}}</span>
          </div>

          <div v-for="(col, idx2) in getActualRows(day.rows)" :key="idx2" class="table-meal" style="padding-top:20px;padding-left:20px;">

            <h3 style="font-size:14px">{{getMealName(dietPlanInfo,col.type )}}</h3>
<!--            {{// options.translations.dayMeals[col.type]}}-->
            <hr>

            <div v-for="mealfood in dietPlan[day.day][col.type].foods" :key="mealfood.id" class=""
                 :style="`font-size: ${options.sizes.text.foodText}px;color: ${options.colors.text.foodText};
                                letter-spacing: ${options.letterSpacing.foodText}px; padding-bottom: ${options.bottomSpacing.foodText}px}`">
              {{formatName(mealfood)}} <span v-if="options.showQuantities  && !mealfood.hideQuantity">{{ formatFoodUnits(mealfood.food)}}</span>

            </div>
            <div v-if="dietPlan[day.day][col.type].notes && !dietPlanInfo.isTextOnly" :style="`font-size: ${options.sizes.text.foodText}px`">
              <strong>Σημειώσεις:</strong> {{dietPlan[day.day][col.type].notes}}
            </div>
            <div v-if="dietPlan[day.day][col.type].notes && dietPlanInfo.isTextOnly" :style="`font-size: ${options.sizes.text.foodText}px!important`"
                 v-html="dietPlan[day.day][col.type].notes">
            </div>
            <div v-if="dietPlanInfo.is_diabetes" class="border-top mt-2 py-1">
              <div class="pl-1" :style="`font-size: ${options.sizes.text.foodText}px!important;color: ${options.colors.text.foodText};`">
                Μονάδες ινσουλίνης: {{getInsulinRatio(day.day, col.type)}}</div>
            </div>
          </div>
        </section>


      </div>
    </div>
</template>


<script>

import {mapState} from "vuex";
import DietPlanMixin from "@src/mixins/DietPlanMixin";

export default{
  props:{
      textOnly:{
        type:Boolean,
        default: false
      },
      options:{
        type: Object,
        required: true
      },
      showNames: {
        type: Boolean,
        defaultValue: true
      },
    onePagePerDay: {
        type: Boolean,
        defaultValue: false
      },
    user: {
      type: Object,
      defaultValue: () => {return {}}
    },
  },
  mixins: [DietPlanMixin],
  computed: {
    ...mapState({
      dietPlan: state => state.pdf.dietPlan,
      dietPlanInfo: state => state.pdf.dietPlanInfo,
      dietPlanId: state => state.pdf.id,
    }),
    getActualHeaders(){
      // let days = JSON.parse(JSON.stringify(this.options.translations.dayMeals));
      //
      // console.log(days);
      let days = [];
      for(let dayId of this.dietPlanInfo.dietPlanMealOptions.mealsOrder){
        if(this.ignoredTypes.includes(dayId)) continue;
        if(dayId >= this.dietPlanInfo.dietPlanMealOptions.totalMeals) continue;
        days.push(dayId);
      }
      return days;
    },
    getDietPlanDays(){

      let days = [];
      for(let day=0;day < this.dietPlan.length;day++){

        if(!this.ignoredDays.includes(day)){
          days.push({
            day: day,
            rows: this.dietPlan[day]
          })
        }

      }
      return days;
    },
  },

  data(){
    return {
      dayNamesAlt: ['1η Επιλογή', '2η Επιλογή', '3η Επιλογή', '4η Επιλογή', '5η Επιλογή', '6η Επιλογή', '7η Επιλογή'],
      types: [0,1,2,3,4,5,6],
      ignoredTypes: [0,1,2,3,4,5,6],
      ignoredDays: [0,1,2,3,4,5,6]
    }
  },
  mounted(){
    this.findEmptyTypes();
  },

  methods:{

    findEmptyTypes(){
      for(let day=0; day < this.dietPlan.length;day++){

        for(let type of this.types){

          if(!this.dietPlan[day][type]) continue;
          if((this.dietPlan[day][type].foods.length > 0) || (this.dietPlan[day][type].isTextOnly && this.dietPlan[day][type].notes && this.dietPlan[day][type].notes.length > 0)){

            // mark day as "have foods"
            if(this.ignoredDays.includes(day)) this.ignoredDays = this.ignoredDays.filter(x => x !== day)

            // mark type as "have type!"
            if(this.ignoredTypes.includes(type)) this.ignoredTypes = this.ignoredTypes.filter(x => x!== type);
          }
        }
      }

    },
    getTRStyles(idx1, idx2){
      if(this.options.stripes === 'rows'){
        if(idx1 % 2 === 0){
          return `background-color:${this.options.colors.stripes};`;
        }
      }

      return '';

    },
    getInsulinRatio(idx1, idx2){

      let statistics = this.dietPlan[idx1][idx2].statistics;

      let amount = statistics['carbs'] / this.dietPlanInfo.insulin_ratio;
      return (Math.round(amount * 10) / 10).toString().replace('.', ',');

    },
    getTDStyles(idx1, idx2){

      let returnable = '';
      if(this.options.stripes === 'mix'){
        if(idx1 % 2 !== 0 && idx2 % 2 === 0){
          returnable = `background-color:${this.options.colors.stripes};`;
        }
        if(idx1 % 2 === 0 && idx2 % 2 !== 0){
          returnable = `background-color:${this.options.colors.stripes};`;
        }
      }

      if(this.options.stripes === 'columns'){
        if(idx2 % 2 !== 0 )  returnable = `background-color:${this.options.colors.stripes};`;
      }

      if(this.options.hasBorder) returnable += `border: 1px solid ${this.options.colors.border}!important;}`;

      return returnable;

    },
    getActualRows(rows){

      let rowsOrderFixed = [];

      for(let mealId of this.dietPlanInfo.dietPlanMealOptions.mealsOrder){

        let row = rows.find(x => x.type === mealId);

        if(!row) continue; // if we don't have this item.
        if(this.ignoredTypes.length > 0){
          if(this.ignoredTypes.includes(row.type)) continue;
        }

        rowsOrderFixed.push(row);
        // let row = ro
      }
      return rowsOrderFixed;
    }
  }
}
</script>

<style lang="scss" scoped>
.table-meal{
  width:18%;
}
.table-meal-day-name{
  width:10%;
}
#print-diet-plan-table{
  .table-meal-food{
    margin:1px;
    padding:1px;
    background-color:transparent;
    &:hover{
      background-color:unset;
    }
  }
  .table-meal{
    cursor:initial;
    &:hover{
      transform:none;
      box-shadow: none;
    }
  }
  .meal-category-5{
    background-color:transparent!important;
  }
  .category-colored{
    .meal-category-1 {
      color: #fff!important;
      background-color: #1abc9c!important;
      margin: 2px;
      padding: 1px;
    }

    .meal-category-2 {
      color:#fff!important;
      background-color:#f7b84b!important;
      margin: 2px;
      padding: 1px;
    }
    .meal-category-3 {
      color:#fff!important;
      background-color:#f672a7!important;
      margin: 2px;
      padding: 1px;
    }
    .meal-category-4 {
      color:#fff!important;
      background-color:$color_primary!important;
      margin: 2px;
      padding: 1px;
    }
    .meal-category-5{
      background-color:#d3d3d3!important;
    }
  }
}
</style>

