<script>
import searchBar from '@components/searchBar'
import addFoodModal from "../details/addFoodModal/addFoodModal";
import {mapState} from "vuex";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import Vue from "vue";
import PaginationRow from "@components/global/PaginationRow";

export default {
  components: {PaginationRow, searchBar, addFoodModal},
  mixins: [PaginatedDataMixin],
  props:{
      isTextOnly:{
        default: 'all',
        type: String
      },
      shouldInsert:{
          default: false,
          type: Boolean
      },
      insertId: {
          default: null,
          type: Number
      },

      openCategory:{
          default: -1,
          type: Number
      }
  },

  data() {
    return {
      showTagList:false,
      types: ['Πρωϊνό', 'Δεκατιανό', 'Μεσημεριανό', 'Απογευματινό', 'Βραδινό'],
      isTextOnlyOptions: [
          {value: 'all', text: 'Προβολή όλων'},
          {value: 'false', text: 'Πρότυπα τροφίμων'},
          {value: 'true', text: 'Ελεύθερα πρότυπα'}
        ],
      loading: false,
      addFoodModel:{
          id: null
      }
    }
  },
  watch:{
     'api.rows'(){
       this.setupDataStatistics();
     }
  },

  computed:{
    ...mapState({
      mealTemplateTags: state => state.tags._mealTemplateTags
    }),
      foods(){
          return this.$store.getters['food/foods'];
      }
  },

  async created(){
    this.api.url = '/meals';
    this.api.query.limit = 5;
    Vue.set(this.api.filters, 'tagId', null);
    Vue.set(this.api.filters, 'type', -1);
    Vue.set(this.api.filters, 'isTextOnly', this.isTextOnly);
    await this.fetchFoods(); // load foods from vuex store or await for api call!
    await this.fetchFoodMeasures(); // load food measures from vuex store or await api call!
    this.api.filters.type = this.openCategory < 5 ? this.openCategory : -1;
    this.getData();
  },

  methods: {

    fetchMealTemplatesByTag(tagId){
      this.api.filters.tagId = tagId;
      this.getData();
    },
    setupDataStatistics(){
      for(let meal of this.api.rows){
        meal.statistics = {
          carbs: 0,
          fats: 0,
          proteins: 0,
          count: 0
        };

        for(let food of meal.foods){
          let fd = this.foods.find(x => x.id === food.foodId );
          meal.statistics.carbs += fd.carbohydrates * food.quantity;
          meal.statistics.fats += fd.fats * food.quantity;
          meal.statistics.proteins += fd.proteins * food.quantity;
          meal.statistics.count++;
        }
        meal.statistics.kcal = meal.statistics.carbs * 4 + meal.statistics.proteins * 4 + meal.statistics.fats * 9;
      }
    },
    insertTemplateMeal(meal){

        if(!this.shouldInsert){
            this.addFoodModal(meal);
            return;
        }
        this.loading=true;

        this.$axios.post(`/meals/insert-template`, {mealTo: this.insertId, mealFrom: meal.id}).then(result => {
            this.loading=false;
            this.$emit('exit', true);
        }).catch(e=>{
            console.log(e);
            this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η εισαγωγή απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            this.loading=false;
        })
    },
    deleteTemplateMeal(mealId){
        let message = {
            title: 'Θέλετε σίγουρα να διαγράψετε το πρότυπο γεύμα;',
            body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτό το γεύμα.'
        };
        this.$dialog.confirm(message, {
            loader: true,
            okText: 'Διαγραφή',
            cancelText: 'Ακύρωση',
            backdropClose: true
        }).then((dialog) => {
            this.$axios.delete(`/meals/${mealId}`).then(result => {
                dialog.close();
                this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Το πρότυπο γεύμα διαγράφηκε επιτυχώς!'});
                this.getData();

            }).catch(e => {
                this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            });
        }).catch(()=>{});
    },
    addFoodModal(meal){
        this.$refs['meal-template-food-modal'].show();
        this.addFoodModel.id = meal.id;

    },
    closeFoodModal(){
        this.$refs['meal-template-food-modal'].hide();
    },

  }
}

</script>
<style lang="scss">
@import '@src/design/custom/pages/_dietPlanList.scss';
.card-body{
    padding:1rem;
}

</style>
<template>
<div id="dp-list-container">
  <b-overlay :show="loading" rounded="sm">
    <searchBar>
      <template v-slot:forms>
        <form class="form-inline">
            <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
                <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400"/>
            </div>
            <div class="form-group mx-sm-3">
                <label for="status-select" class="mr-2">Επιλέξτε τύπο:</label>
                <select id="status-select" v-model="api.filters.type" class="custom-select" @change="getData">
                    <option value='-1'>Προβολή όλων</option>
                    <option value='0'>Πρωινό</option>
                    <option value='1'>Δεκατιανό</option>
                    <option value='2'>Μεσημεριανό</option>
                    <option value='3'>Απογευματινό</option>
                    <option value='4'>Βραδινό</option>
                </select>
            </div>
          <div v-if="!shouldInsert" class="form-group">
            <label for="text-only-select" class="mr-2">Επιλέξτε είδος:</label>
            <b-form-select id="text-only-select" v-model="api.filters.isTextOnly" :options="isTextOnlyOptions" @change="getData"></b-form-select>
          </div>
          <div class="form-group mx-sm-3">
            <b-button v-if="mealTemplateTags && mealTemplateTags.length > 0" variant="light" @click="showTagList = !showTagList">Φιλτράρισμα ανά ετικέτα <i v-if="!showTagList" class="fe-arrow-down"></i> <i v-if="showTagList" class="fe-arrow-up"></i></b-button>
          </div>
        </form>
      </template>
    </searchBar>
    <div v-if="showTagList" class="choose-from-tags row">
    <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <b-button variant="outline-primary" class="tag-btn" :class=" (null === api.filters.tagId) ? 'active-tag-btn' : '' " @click="fetchMealTemplatesByTag(null)">Προβολή όλων</b-button>
        <span v-for="tag in mealTemplateTags" :key="tag.id">
                  <b-button variant="outline-primary" class="tag-btn" :class=" (tag.id === api.filters.tagId) ? 'active-tag-btn' : '' " @click="fetchMealTemplatesByTag(tag.id)"><i class="fe-tag"></i> {{tag.text}}</b-button>
              </span>
      </div>
    </div>
    </div>
    </div>
    <div v-if="api.rows.length === 0 && !api.loading && api.filters.search === '' && api.page === 1">
      <div class="card dp-not-found">
          <div class="card-body text-center">
              <h2 class="headline-not-found">ΔΕΝ ΒΡΕΘΗΚΑΝ ΠΡΟΤΥΠΑ ΓΕΥΜΑΤΑ</h2>
              <p>Δεν έχετε αποθηκεύσει ακόμα κάποιο πρότυπο γεύμα.</p>
              <div class="pt-3"><img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/></div>
          </div>
      </div>
    </div>

    <div v-if="api.rows.length > 0 " class="row">
      <div class="col-md-12">
        <div v-for="meal in api.rows" :key="meal.id" @click="insertTemplateMeal(meal)">
            <b-card  class="shadow-sm diet-plan-item">
                <b-card-text>
                    <div class="row">
                        <div class="col-6 col-md-2">
                             <span v-if="meal.isTextOnly" class="text-muted system-dp-label" style="border-radius:6px">
                                   Ελεύθερο πρότυπο (μόνο κείμενο) <br>
                              </span>
                            <span class="text-muted">Όνομα</span>  <br>
                            <span class="dp-name">
                                {{meal.name}}
                            </span>
                        </div>
                        <div class="col-6 col-md-1">
                            <span class="text-muted">Είδος</span>  <br>
                            <span class="dp-name">
                                {{types[meal.type]}}
                            </span>
                        </div>
                      <div class="col-12 col-md-1">
                        <span class="text-muted">Ετικέτες</span>  <br>
                        <div v-if="meal.mealTags && meal.mealTags.length > 0" class="tag-container">
                          <div v-for="tag in meal.mealTags" :key="tag.id" class="ti-tag ti-valid">
                            <div class="ti-content">
                              <div class="ti-tag-center">
                                {{tag.text}}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-else>-</div>
                      </div>
                        <div v-if="!meal.isTextOnly && meal.statistics" class="col-4 col-md-1">
                            <span class="text-muted">Τρόφιμα</span>  <br>
                            <span class="dp-name">
                                {{meal.statistics.count}}
                            </span>
                        </div>
                        <div v-if="!meal.isTextOnly && meal.statistics" class="col-4 col-md-1">
                            <span class="text-muted">Υδατ.</span>  <br>
                            <span class="dp-name">
                                {{(meal.statistics.carbs).toFixed(2)}} gr
                            </span>
                        </div>
                        <div v-if="!meal.isTextOnly && meal.statistics" class="col-4 col-md-1">
                            <span class="text-muted">Πρωτ.</span>  <br>
                            <span class="dp-name">
                                {{(meal.statistics.proteins).toFixed(2)}} gr
                            </span>
                        </div>
                        <div v-if="!meal.isTextOnly && meal.statistics" class="col-4 col-md-1">
                            <span class="text-muted">Λίπη</span>  <br>
                            <span class="dp-name">
                                {{(meal.statistics.fats).toFixed(2)}} gr
                            </span>
                        </div>
                        <div v-if="!meal.isTextOnly && meal.statistics" class="col-4 col-md-2">
                            <span class="text-muted">Ενέργεια</span>  <br>
                            <span class="dp-name">
                                {{(meal.statistics.kcal).toFixed(2)}} kcal
                            </span>
                        </div>
                        <div v-if="meal.isTextOnly" class="col-12 col-md-6"/>
                        <div v-if="!shouldInsert" class="col-md-2 ">
                            <b-button variant="primary" class="btn-xs align-middle m-1" @click="addFoodModal(meal)"><i class="fe-eye"></i></b-button>

                            <b-button v-if="!shouldInsert" variant="danger" class="btn-xs align-middle m-1"
                                      @click.stop="deleteTemplateMeal(meal.id)"><i class="icon-trash"></i></b-button>

                            <b-button v-if="shouldInsert" variant="danger" class="btn-xs align-middle m-1"
                                      @click.stop="insertTemplateMeal(meal.id)"><i class="fas fa-paper-plane"></i></b-button>
                        </div>
                    </div>


                </b-card-text>
            </b-card>
        </div>

        <pagination-row :api="api" @fetchData="getData"></pagination-row>

      </div>
    </div>
  </b-overlay>

  <b-modal id="meal-template-food-modal" ref="meal-template-food-modal" size="full" title="Επεξεργασία γεύματος"
            scrollable hide-footer  body-class="p-3" @hidden="getData">

      <add-food-modal :meal-id="addFoodModel.id"
                      :foods="foods"
                      :is-meal-template="true"
                      @closeDialog="closeFoodModal"></add-food-modal>

  </b-modal>
</div>
</template>
