<template>
  <div v-if="isPrintingWindow" class="print-intro row" :style="`${getGenericPrintIntroStyles};background-color:${options.colors.headerBackground};`">
    <div class="col-md-6 col-2-3" :style="`color:${options.colors.headerTextColor};margin:5px 20px;font-size:11px;`">
      <span v-if="options.showDate"><strong> {{options.translations.titles.date}}: </strong>{{ new Date().getDate() + '/' + (parseInt(new Date().getMonth()) + 1) + '/' + new Date().getFullYear() }}<br></span>
      <span v-if="options.showDietitianName"><strong>{{ options.translations.titles.dietitian }}:</strong> {{user.firstName + ' ' + user.lastName}}       <br></span>

      <span v-if="options.showDietPlanName"><strong > {{ options.translations.titles.plan }}: </strong>{{dietPlanName}}</span>
    </div>
    <div v-if="options.showLogo" class="col-md-6 col-1-3 text-right" style="margin:5px 20px;">
      <img :src="user.logo ? user.logo+'?crossorigin' : 'https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-icon-printing.png'"
           style="width:auto;max-width:125px;max-height:35px;" class="pr-3" crossorigin="anonymous"/>
    </div>
  </div>

  <div v-else class="print-intro" :style="`${getGenericPrintIntroStyles};background-color:${options.colors.headerBackground};`">
    <div class="row">
      <div class="col-md-6 col-2-3" :style="`color:${options.colors.headerTextColor};margin:5px 20px;`">
        <span v-if="options.showDate"><strong> {{options.translations.titles.date}}: </strong>{{ new Date().getDate() + '/' + (parseInt(new Date().getMonth()) + 1) + '/' + new Date().getFullYear() }}<br></span>
        <span v-if="options.showDietitianName"><strong>{{ options.translations.titles.dietitian }}:</strong> {{user.firstName + ' ' + user.lastName}}       <br></span>
        <span v-if="options.showDietPlanName"><strong > {{ options.translations.titles.plan }}: </strong>{{dietPlanName}}</span>
      </div>
      <div v-if="options.showLogo" class="col-md-6 col-1-3 text-right" >
        <img :src="user.logo ? user.logo +'?crossorigin' : 'https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-icon-printing.png?crossorigin'"
             style="width:auto;max-width:125px;max-height:35px;" class="pr-3" crossorigin="anonymous"/>
      </div>
    </div>
  </div>
</template>

<script>

export default{

  props:{
    options: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    isPrintingWindow: {
      type: Boolean,
      defaultValue: false
    },
    dietPlanName: {
      type: String,
      defaultValue: 'Πρότυπο διατροφικό πλάνο'
    }
  },
  computed:{
    getGenericPrintIntroStyles(){
      return 'margin:5px;align-items:center;padding:5px 10px'
    }
  }
}
</script>
