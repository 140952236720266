<script>
import PageHeader from '@components/page-header'
import dietPlanBase from '@views/dietPlan/details/dietPlanBase'


export default {
    components: {PageHeader, dietPlanBase},
    props: {},
    page: {title: 'Διατροφικά πλάνα'},
    data() {
        return {
            edits: {
                isEdited: false,
                isFuncEdited: false
            },
            athleteId: this.$route.params.athleteId,
            tabId: 2
        }
    },
  watch:{
      '$route.params.athleteId'(val){
        this.athleteId = val;
      }
  },
  methods:{
    redirectToAthlete(){
        switch(this.tabId){
            case 0:
                this.$router.push('/athletes/' + this.athleteId);
                break;
            case 1:
                this.$router.push('/athletes/' + this.athleteId + '#progress-list');
                break;
            case 2:
                this.$router.push('/athletes/' + this.athleteId + '#dietplans');
                break;
            case 3:
                this.$router.push('/athletes/' + this.athleteId + '#measurements');
                break;
            case 4:
                this.$router.push('/athletes/' + this.athleteId + '#supplements');
                break;
            case 5:
                this.$router.push('/athletes/' + this.athleteId + '#questionnaire');
                break;
            case 6:
                this.$router.push('/athletes/' + this.athleteId + '#mobile-app');
                break;
            case 7:
              this.$router.push('/athletes/' + this.athleteId + '#files');
              break;
            default:
                break;
        }
    }
  }
}

</script>
<template>

  <div id="athlete-dp-view-layout" :key="athleteId">
      <b-tabs v-model="tabId" pills content-class="mt-1" justified class="navtab-bg athlete-tabs" @input="redirectToAthlete">
          <b-tab>
              <template v-slot:title>
                  <div class="tab-icon"><i class="icon-user"></i></div>
                  <div class="break"></div>
                  <div class="tab-title">Στοιχεία</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Βασικές πληροφορίες επικοινωνίας
                  </div>
              </template>
          </b-tab>
          <b-tab>
              <template v-slot:title>
                  <div class="tab-icon"><i class="icon-graph"></i></div>
                  <div class="break"></div>
                  <div class="tab-title"> Φύλλο παρακολούθησης</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Καταγραφή της προόδου ανά ραντεβού
                  </div>
              </template>
          </b-tab>
          <b-tab>
              <template v-slot:title><div class="tab-icon"><i class="icon-calender"></i></div>
                  <div class="break"></div>
                  <div class="tab-title">Διατροφικά Πλάνα</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Εβδομαδιαία προγράμματα διατροφής
                  </div></template>
              <b-button variant="primary" class="mb-3" @click="$router.push('/athletes/' + athleteId + '#dietplans')"><i class="icon-arrow-left"></i> Επιστροφή στα διατροφικά πλάνα του πελάτη</b-button>
              <diet-plan-base
                  :athlete-id="athleteId" :athlete-view="true" :edits="edits"></diet-plan-base>
          </b-tab>
          <b-tab>
              <template v-slot:title><div class="tab-icon"><i class="icon-chemistry"></i></div> <div class="break"></div> <div class="tab-title">Μετρήσεις</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Σωματομετρικές, βιταμίνες, αιματολογικές...
                  </div></template>
          </b-tab>

          <b-tab>
              <template v-slot:title>
                  <div class="tab-icon"><i class="icon-energy"></i></div> <div class="break"></div> <div class="tab-title">Άσκηση & Συμπληρώματα</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Άσκηση και συνιστώμενα συμπληρώματα
                  </div>
              </template>
          </b-tab>
          <b-tab>
              <template v-slot:title><div class="tab-icon"><i class="icon-question"></i></div><div class="break"></div><div class="tab-title">Ερωτηματολόγιο</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Σύντομο καθώς και αναλυτικό ερωτηματολόγιο
                  </div>
              </template>
          </b-tab>
          <b-tab>
              <template v-slot:title><div class="tab-icon"><i class="icon-screen-smartphone"></i></div><div class="break"></div><div class="tab-title">Mobile Εφαρμογή</div>
                  <div class="break"></div>
                  <div class="tab-subtitle">
                      Ημερολόγιο καταγραφής - στόχοι
                  </div>
              </template>
          </b-tab>
        <b-tab>
          <template v-slot:title><div class="tab-icon"><i class="icon-docs"></i></div><div class="break"></div><div class="tab-title">Αρχεία</div>
            <div class="break"></div>
            <div class="tab-subtitle">
              Λίστα αποθηκευμένων αρχείων πελάτη
            </div>
          </template>
        </b-tab>
      </b-tabs>
  </div>
</template>
