<template>
  <b-overlay v-if="!isEducational" :show="autoGenerationOptions.isLoading" rounded="sm">
    <b-alert variant="warning" :show="true" :dismissible="true">
      <p class="font-weight-bold mb-2 pb-0" style="font-size:0.9rem;">Αυτόματη δημιουργία διατροφικού πλάνου - Δοκιμαστική λειτουργία.</p>
      <p>
        Η αυτόματη δημιουργία διατροφικού πλάνου λειτουργεί με βάση τα δικά σας πρότυπα γεύματα. Για να έχετε καλύτερη εμπειρία χρήσης, θα χρειαστεί να φτιάξετε
        σωστά και ισορροπημένα πρότυπα ώστε το τελικό διαιτολόγιο να έχει σωστές συνθέσεις τόσο σε συνδυασμούς τροφίμων όσο και σε κατανομή μακροθρεπτικών συστατικών.
      </p>
      <p>
        Η ακρίβεια των ποσοτήτων καθορίζεται ανά 10 γραμμάρια σε τρόφιμα που έχουν μονάδα μέτρησης τα γραμμάρια και ανά
        μισό (τεμάχιο, κουταλάκι, φλυτζάνι κ.λπ.) για τις υπόλοιπες μονάδες μέτρησης.
      </p>
      <p>
        Το σύστημα δημιουργήθηκε για να σας βοηθήσει να κάνετε γρηγορότερα τη δουλειά σας και όχι να σας αντικαταστήσει.
        <strong>Παρακαλούμε να δώσετε προσοχή στο διαιτολόγιο και τις επιλογές του πριν το δώσετε σε κάποιον διαιτώμενο.</strong>

      </p>
      <p>
        <strong>Εάν δεν έχετε πρότυπα γεύματα, δεν μπορεί να λειτουργήσει η αυτόματη παραγωγή διαιτολογίων.</strong>
      </p>
    </b-alert>

    <div>
      <label>Πόσες θερμίδες;</label>

      <b-form-input type="number" v-model="autoGenerationOptions.calories"></b-form-input>
    </div>

    <div class="my-3">
      <b-form-checkbox v-model="autoGenerationOptions.main_meals_repeat" switch>Ίδιο μεσημεριανό/βραδινό;<br> (επιλέγει πάντα το μεσημεριανό ως κατηγορία προτύπου)</b-form-checkbox>
    </div>

    <div class="my-3">
      <label>Ποιες ημέρες θέλετε να παραχθούν; (Θα διαγραφούν τα περιεχόμενα αυτών των ημερών)</label>
      <b-button variant="link" @click="toggleAutoGenerationDays">{{autoGenerationOptions.days.length === 0 ? 'Ενεργοποίηση όλων' : 'Απενεργοποίηση όλων'}}</b-button>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="autoGenerationOptions.days"
            :options="autoGenerationOptions.day_options"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="my-3">
      <label>Πώς θέλετε να καταμεριθούν ποσοστιαία οι θερμίδες; (γράψτε 0 για να αγνοήσετε το γεύμα)</label>
      <b-button variant="link" @click="resetAutoGenerationPercentages">Επαναφορά</b-button>
      <div class="row my-1 meal-percentages">

        <div class="col-6 col-lg-2">
          <label>Πρωϊνό;</label>
          <b-input-group append="%" >
            <b-form-input v-model="autoGenerationOptions.meal_percentages[0]" type="number"
                          @input="autoGenerationOptions.meal_percentages[0] = replaceCommaWithDot(autoGenerationOptions.meal_percentages[0])"></b-form-input>
          </b-input-group>

          <!--              <b-form-input type="number" v-model="autoGenerationOptions.meal_percentages[0]"></b-form-input>-->
        </div>
        <div class="col-6 col-lg-2">
          <label>Δεκατιανό;</label>
          <b-input-group append="%" >
            <b-form-input v-model="autoGenerationOptions.meal_percentages[1]" type="number"
                          @input="autoGenerationOptions.meal_percentages[1] = replaceCommaWithDot(autoGenerationOptions.meal_percentages[1])"></b-form-input>
          </b-input-group>
        </div>
        <div class="col-6 col-lg-2">
          <label>Μεσημεριανό;</label>
          <b-input-group append="%" >
            <b-form-input v-model="autoGenerationOptions.meal_percentages[2]" type="number"
                          @input="autoGenerationOptions.meal_percentages[2] = replaceCommaWithDot(autoGenerationOptions.meal_percentages[2])"></b-form-input>
          </b-input-group>
        </div>
        <div class="col-6 col-lg-2">
          <label>Απογευματινό;</label>
          <b-input-group append="%" >
            <b-form-input v-model="autoGenerationOptions.meal_percentages[3]" type="number"
                          @input="autoGenerationOptions.meal_percentages[3] = replaceCommaWithDot(autoGenerationOptions.meal_percentages[3])"></b-form-input>
          </b-input-group>
        </div>
        <div class="col-6 col-lg-2">
          <label>Βραδινό;</label>
          <b-input-group append="%" >
            <b-form-input v-model="autoGenerationOptions.meal_percentages[4]" type="number"
                          @input="autoGenerationOptions.meal_percentages[4] = replaceCommaWithDot(autoGenerationOptions.meal_percentages[4])"></b-form-input>
          </b-input-group>
        </div>
        <div class="col-6 col-lg-2">
          <label>Σύνολο</label>

          <b-input-group append="%" >
            <b-form-input :value="autoGenerationTotalPercentage" type="number" :disabled="true"
                          :style="`color:white;background:${autoGenerationTotalPercentage === 100 ? '#16a34a;' : '#F1556C;'}`"></b-form-input>
          </b-input-group>

        </div>
      </div>


    </div>

    <div class="my-3 text-right">
      <b-button variant="primary" :disabled="autoGenerationDisabled" @click="dietPlanGeneration"><span class="fa fa-brain"></span> Δημιουργία</b-button>
    </div>

    <b-alert v-if="error" show variant="danger">{{$t('errors.'+error)}}</b-alert>
  </b-overlay>
  <educational-unavailable v-else/>
</template>

<script>
export default{

  watch:{
    'dietPlanInfo.dietPlanFunc.requiredKcal'(val){
      this.autoGenerationOptions.calories = val;
    },
  },

  props:{
    dietPlanInfo: Object
  },

  created(){
    this.autoGenerationOptions.calories = this.dietPlanInfo.dietPlanFunc.requiredKcal;
  },

  computed:{
    autoGenerationDisabled(){

      if(this.autoGenerationOptions.isLoading) return true;
      if(this.autoGenerationTotalPercentage !== 100) return true;
      if(this.autoGenerationOptions.days.length === 0) return true;

      if(this.autoGenerationOptions.calories < 500) return true;
      return false;
    },
    autoGenerationTotalPercentage(){
      let total = 0;
      for(let value of Object.values(this.autoGenerationOptions.meal_percentages)){
        total += parseInt(value);
      }
      return total;
    }
  },
  data(){
    return{
      autoGenerationOptions:{
        isLoading: false,
        day_options: [
          {text:this.$t('days.monday'),value:0},
          {text:this.$t('days.tuesday'),value:1},
          {text:this.$t('days.wednesday'),value:2},
          {text:this.$t('days.thursday'),value:3},
          {text:this.$t('days.friday'),value:4},
          {text:this.$t('days.saturday'),value:5},
          {text:this.$t('days.sunday'),value:6},
        ],
        calories: null,
        main_meals_repeat: false,
        days: [0,1,2,3,4,5,6],
        meal_percentages: {
          0: 20,
          1: 10,
          2: 30,
          3: 10,
          4: 30
        }
      },
      error: null
    }
  },
  methods:{
    dietPlanGeneration(){

      this.autoGenerationOptions.isLoading = true;
      this.error = null;

      let options = JSON.parse(JSON.stringify(this.autoGenerationOptions));

      for(let [key, value] of Object.entries(options.meal_percentages)){
        options.meal_percentages[key] = value / 100;
      }
      this.$axios.post(`/dietPlan/${this.$route.params.dietPlanId}/ai-generation`, options).then(res=>{

        setTimeout(()=>{
          this.autoGenerationOptions.isLoading = false;
          this.$emit('dietPlanGenerated');

        }, 1000);

      }).catch(e=>{
        if(e.response.data.error === 'NO_MEAL_TYPE_TEMPLATES') this.error = e.response.data.error;
        this.autoGenerationOptions.isLoading = false;
        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});
      });
    },
    toggleAutoGenerationDays(){
      if(this.autoGenerationOptions.days.length === 0 ){
        this.autoGenerationOptions.days = [0,1,2,3,4,5,6]
      }else{
        this.autoGenerationOptions.days = [];
      }
    },
    resetAutoGenerationPercentages(){
      this.autoGenerationOptions.meal_percentages[0] = 20;
      this.autoGenerationOptions.meal_percentages[1] = 10;
      this.autoGenerationOptions.meal_percentages[2] = 30;
      this.autoGenerationOptions.meal_percentages[3] = 10;
      this.autoGenerationOptions.meal_percentages[4] = 30;
    },
  }
}
</script>