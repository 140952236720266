<script>
    import Vue from "vue";
    import {mapState} from "vuex";

    export default{
        props: {
            options:{
              type: Object,
              required: true
            },
            showNames: {
              type: Boolean,
              defaultValue: true
            },
            edits: {
                default: () => {
                    return {}
                },
                type: Object
            },
            isLandscape: {
                type: Boolean,
                default: false
            }
        },

        data(){
            return{
                loadingDietPlan: true,
                dayNamesAlt: ['1η Επ.', '2η Επ.', '3η Επ.', '4η Επ.', '5η Επ.', '6η Επ.', '7η Επ.'],
                diagrams: {
                    stroke:{
                        width: 2,
                        curve: 'smooth'
                    },
                    grid:{
                        show: false
                    }
                },
                pieOptions:{
                    chart: {
                        // width: 250,
                        type: 'pie',
                      animations:{
                        enabled: false
                      },
                    },
                    labels: [this.options.translations.texts.freskies,
                        this.options.translations.texts.amylouxes,
                        this.options.translations.texts.fats,
                        this.options.translations.texts.zoikes
                        , this.options.translations.texts.sintheta, 'Ποτά / Γενικά'],
                      colors:['#1abc9c', '#3155A6','#f7b84b','#f1556c', '#d3d3d3', '#0891b2' ],
                      legend:{
                      offsetY: 16,
                          position: 'bottom',
                          // floating:false,
                          fontSize: '7px'
                      },
                      stroke:{
                          width:0
                      },
                      tooltip:{
                          enabled: false
                      }
                },
                foodStatistics: [],
                chartSize:{
                    height:this.isLandscape ? '250px' : '300px',
                    width:this.isLandscape ? '345px' : '385px',
                }
            }

        },
        computed:{
          ...mapState({
            dietPlanInfo: state => state.pdf.dietPlanInfo,
            days: state => state.pdf.dietPlan,
            loadingDone: state => state.pdf.loadingDone
          })
        },
        watch: {
          loadingDone(val){
            if(val) this.displayAndCalculateFoods();
          }
        },
        created(){
            if(this.loadingDone) this.displayAndCalculateFoods();
        },

        methods:{
            getOptions(type){
                let color;

                if(type === 'CALORIES'){
                    color =  ['#3155A6'];
                }

                if(type==='CARBS'){
                    color = ['#1abc9c'];
                }

                if(type==='PROTEINS'){
                    color = ['#f7b84b'];
                }

                if(type==='FATS'){
                    color=['#f1556c'];

                }

                if(type==='CARBS-PROTEINS'){
                    color=['#1abc9c', '#f7b84b'];
                }

                let diagramOpt = {
                    chart: {
                        toolbar: {
                            show: false,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset:false,
                                customIcons: []
                            },
                        },
                      animations:{
                        enabled: false
                      },

                    },
                    stroke: this.diagrams.stroke,
                    xaxis: {
                        categories: this.showNames ? this.dayNamesAlt : this.dayNamesShort
                    },
                    yaxis: {
                        forceNiceScale: true
                    },

                    scales: {
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                            }
                        }]
                    },
                    axisBorder: {
                        show: false
                    },
                    legend:{
                        position:'top',
                        floating:false,
                    },
                    tooltip:{
                        enabled: false
                    },
                };

                diagramOpt.chart.id = type;
                diagramOpt.colors = color;
                return diagramOpt;
            },
            getSeries(type){
                if(type==='CALORIES'){
                    return [{
                        name: this.options.translations.texts.energy,
                        data:  [...this.days.map(x => {return x.totalStats.calories })]
                    }]
                }else if(type==='PROTEINS'){
                    return [{
                        name: this.options.translations.texts.proteins,
                        data:  [...this.days.map(x => {return x.totalStats.proteins })]
                    }]
                }else if(type==='CARBS'){
                    return [{
                        name: this.options.translations.texts.carbs,
                        data:  [...this.days.map(x => {return x.totalStats.carbs })]
                    }]
                }else if(type==='FATS'){
                    return [{
                        name: this.options.translations.texts.fats,
                        data:  [...this.days.map(x => {return x.totalStats.fats })]
                    }]
                }else if(type==='CARBS-PROTEINS'){
                    return [{
                        name: this.options.translations.texts.carbs,
                        data:  [...this.days.map(x => {return x.totalStats.carbs })]
                    },
                        {
                            name: this.options.translations.texts.proteins,
                            data: [...this.days.map(x => {return x.totalStats.proteins })]
                        }]
                }else if(type==='FOOD-PIE'){
                    return this.foodStatistics
                }

            },
            displayAndCalculateFoods(){
            this.loadingDietPlan=true;
            let foodStats = [0,0,0,0,0,0];
            for(let i=0; i<7;i++){
              let dayStats = {calories: 0, carbs: 0, proteins: 0, fats: 0};
              for(let j=0;j<this.dietPlanInfo.dietPlanMealOptions.totalMeals;j++){
                let totalStats = this.days[i][j].statistics;

                // calculate total foods per day
                for(let fd of this.days[i][j].foods){
                  fd.category = fd.food.category;
                  foodStats[fd.category-1] += 1;
                }

                dayStats.calories += totalStats.calories;
                dayStats.carbs += totalStats.carbs;
                dayStats.proteins += totalStats.proteins;
                dayStats.fats += totalStats.fats;
              }

              dayStats.calories = dayStats.calories.toFixed(0);
              dayStats.carbs = dayStats.carbs.toFixed(0);
              dayStats.proteins = dayStats.proteins.toFixed(0);
              dayStats.fats = dayStats.fats.toFixed(0);
              Vue.set(this.days[i], 'totalStats', dayStats);

            }
            this.foodStatistics = foodStats;
            this.loadingDietPlan=false;

          },
        },
    }
</script>

<template>

    <div id="program-analysis-print" class="card-body" style="padding:10px;">
        <div>
            <div class="row">
                <div class="col-md-4">
                    <h5> {{ this.options.translations.texts.energy }}</h5>
                    <apexchart :height="chartSize.height" :width="chartSize.width" type="line" :options="getOptions('CALORIES')" :series="getSeries('CALORIES')"></apexchart>
                </div>
                <div class="col-md-4">
                    <h5>{{ this.options.translations.texts.carbs }} / {{ this.options.translations.texts.proteins }}</h5>
                    <apexchart :height="chartSize.height" :width="chartSize.width" type="line" :options="getOptions('CARBS-PROTEINS')" :series="getSeries('CARBS-PROTEINS')"></apexchart>
                </div>
                <div class="col-md-4">
                    <h5>{{ this.options.translations.texts.stats }}</h5>
                    <apexchart :height="chartSize.height" :width="chartSize.width" type="pie" :options="pieOptions" :series="getSeries('FOOD-PIE')"></apexchart>

                </div>
                <div class="col-md-4">
                    <h5>{{ this.options.translations.texts.proteins }}</h5>
                    <apexchart :height="chartSize.height" :width="chartSize.width" type="line" :options="getOptions('PROTEINS')" :series="getSeries('PROTEINS')"></apexchart>
                </div>
                <div class="col-md-4">
                    <h5>{{ this.options.translations.texts.carbs }}</h5>
                    <apexchart :height="chartSize.height" :width="chartSize.width" type="line" :options="getOptions('CARBS')" :series="getSeries('CARBS')"></apexchart>
                </div>
                <div class="col-md-4">
                    <h5>{{ this.options.translations.texts.fats }}</h5>
                    <apexchart :height="chartSize.height" :width="chartSize.width" type="line" :options="getOptions('FATS')" :series="getSeries('FATS')"></apexchart>
                </div>
            </div>
        </div>
    </div>

</template>
