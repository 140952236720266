<script>

    export default{
        props: {
            dietPlanInfo: {
                default: () => {
                    return {}
                },
                type: Object
            },
            edits: {
                default: () => {
                    return {}
                },
                type: Object
            }
        },

        data(){
            return{
                hasError: false,
                errorMsg: '',
                customPAL: this.dietPlanInfo.exerciseVar,
                minPAL: 1,
                maxPAL: 2.5,
                hasChanged: false,
                isLoading: false
            }
        },
        computed:{
            validateSex(){
                return this.dietPlanInfo.dietPlanFunc.sex === 'M' || this.dietPlanInfo.dietPlanFunc.sex === 'F';
            },
            validateAge(){
                return this.dietPlanInfo.dietPlanFunc.age > 0;
            },

            validateWeight(){
                return this.dietPlanInfo.dietPlanFunc.weight > 0;
            },

            validateHeight(){
                return this.dietPlanInfo.dietPlanFunc.height > 0;
            },
            validateFat(){
                return parseFloat(this.dietPlanInfo.dietPlanFunc.fat) > 0 ;
            },
            validateFatPerKg(){
              return parseFloat(this.dietPlanInfo.dietPlanFunc.fatsPerKg) >= 0 ;
            },
            validateCarbsPerKg(){
              return parseFloat(this.dietPlanInfo.dietPlanFunc.carbsPerKg) >= 0 ;
            },
            validateProteinsPerKg(){
              return parseFloat(this.dietPlanInfo.dietPlanFunc.proteinsPerKg) >= 0 ;
            }

        },
        created(){
            this.calculateEquationMinMaxPAL();
        },

        methods:{
            disableExerciseVariable(val){
              if(val) this.dietPlanInfo.dietPlanFunc.useExercise = false;
            },

            updateDietPlanFunctions(){
                this.hasError = false;
                let info = this.dietPlanInfo.dietPlanFunc;

                if(info.manualKcal){
                    info.useExercise = false;
                }

                if(!info.manualKcal && (!this.validateAge || !this.validateWeight || !this.validateHeight)){
                    return;
                }
                if(this.dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations &&
                    (!this.validateWeight || !this.validateCarbsPerKg || !this.validateFatPerKg || !this.validateProteinsPerKg) ){
                  return;
                }
                if(this.dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations){
                  this.fixMacroNutrientsPercentages();
                }
                this.$axios.put(`/dietPlan/${this.dietPlanInfo.id}/functions`, {dietPlanFunc: this.dietPlanInfo.dietPlanFunc}).then(result => {
                    this.dietPlanInfo.dietPlanFunc = result.data.dietPlanFunc;
                    this.dietPlanInfo.dietPlanFunc.caloricNeeds = result.data.caloricNeeds;
                    this.edits.isFuncEdited =  false;
                    this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: 'Το διατροφικό πλάνο ανανεώθηκε επιτυχώς!'});
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });


            },

            fixMacroNutrientsPercentages(){
              let nutrients = this.dietPlanInfo.dietPlanFunc.nutrients;

              let totalCarbs = parseFloat(this.dietPlanInfo.dietPlanFunc.carbsPerKg) * parseFloat(this.dietPlanInfo.dietPlanFunc.weight) * 4;
              let totalFats = parseFloat(this.dietPlanInfo.dietPlanFunc.fatsPerKg) * parseFloat(this.dietPlanInfo.dietPlanFunc.weight) * 9;
              let totalProteins = parseFloat(this.dietPlanInfo.dietPlanFunc.proteinsPerKg) * parseFloat(this.dietPlanInfo.dietPlanFunc.weight) * 4;

              let totalCalories = totalCarbs + totalFats + totalProteins;

              nutrients.carbs.total = totalCarbs / totalCalories * 100;
              nutrients.fats.total = totalFats / totalCalories * 100;
              nutrients.proteins.total = totalProteins / totalCalories * 100;


              this.$axios.put(`/dietPlan/${this.dietPlanInfo.id}/functions/nutrients`, {nutrients: nutrients}).then(()=>{}).catch(e=>{
                this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
            },

            calculateCustomPAL(){

                if(this.dietPlanInfo.dietPlanFunc.useExercise){
                    this.dietPlanInfo.dietPlanFunc.customPAL = 1.2;
                }else{
                    this.dietPlanInfo.dietPlanFunc.customPAL = parseFloat(this.dietPlanInfo.dietPlanFunc.exerciseVar);
                }
                this.calculateEquationMinMaxPAL();
            },

            calculateEquationMinMaxPAL(){
                if(this.dietPlanInfo.dietPlanFunc.useExercise){
                    this.minPAL = 1;
                    this.maxPAL = 1.39;
                }else{
                    switch(parseFloat(this.dietPlanInfo.dietPlanFunc.exerciseVar)){
                        case 1.3:
                            this.minPAL = 1;
                            this.maxPAL = 1.39;
                            break;
                        case 1.55:
                            this.minPAL = 1.4;
                            this.maxPAL = 1.59;
                            break;
                        case 1.7:
                            this.minPAL = 1.6;
                            this.maxPAL = 1.89;
                            break;
                        case 1.9:
                            this.minPAL = 1.9;
                            this.maxPAL = 2.5;
                            break;
                        default:
                            break;
                    }
                }
            },

            markAsDirty(){
                this.edits.isFuncEdited = true;
                this.autoUpdateValues();
            },

            autoUpdateValues() {
                if(!this.dietPlanInfo.dietPlanFunc.manualKcal && (!this.validateAge || !this.validateWeight || !this.validateHeight)){
                    return;
                }
                if(this.dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations && (!this.validateCarbsPerKg ||
                    !this.validateFatPerKg || !this.validateProteinsPerKg) ){
                  return;
                }
                this.isLoading = true;
                setTimeout(() => {
                    this.updateDietPlanFunctions();
                    this.isLoading = false
                }, 500)
            }
        }
    }
</script>

<template>

    <div id="program-functions" class="card-body">
        <b-overlay :show="isLoading" rounded="sm">
            <b-alert :show="dietPlanInfo.dietPlanFunc.useExercise" variant="warning">Οι θερμίδες που αναγράφονται δεν περιλαμβάνουν τα αθλήματα και αφορούν τον βασικό μεταβολισμό επί 1.2!</b-alert>
            <b-form v-if="dietPlanInfo.dietPlanFunc"  @submit.prevent="updateDietPlanFunctions" @change="markAsDirty">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group label="Ρυθμίσεις" label-for="manual-kcal">
                            <b-form-checkbox v-if="!dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" id="manual-kcal" v-model="dietPlanInfo.dietPlanFunc.manualKcal" name="checkbox-manual-kcal" :disabled="dietPlanInfo.isSystem">Εισαγωγή θερμίδων χειροκίνητα;</b-form-checkbox>

                            <b-form-checkbox
                                   v-if="!dietPlanInfo.dietPlanFunc.manualKcal && !dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" id="use-exercise" v-model="dietPlanInfo.dietPlanFunc.useExercise" name="checkbox-exercise" :disabled="dietPlanInfo.isSystem" @input="calculateCustomPAL">Προχωρημένη εξίσωση; (χρήση αθλημάτων για υπολογισμό ενέργειας ανά ημέρα)
                            </b-form-checkbox>

                            <b-form-checkbox
                                    v-if="!dietPlanInfo.dietPlanFunc.manualKcal && !dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" id="manual-bmr" v-model="dietPlanInfo.dietPlanFunc.manualBMR" :disabled="dietPlanInfo.isSystem" name="checkbox-exercise"> Εισαγωγή βασικού μεταβολισμού (BMR) χειροκίνητα;
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-if="!dietPlanInfo.dietPlanFunc.manualKcal" id="macro-calculations" v-model="dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" :disabled="dietPlanInfo.isSystem" name="checkbox-exercise" @change="disableExerciseVariable"> Υπολογισμός θερμιδικών αναγκών με συντελεστή άθλησης
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group v-if="!dietPlanInfo.dietPlanFunc.manualKcal" label="Επιλέξτε στόχο:">
                            <b-form-radio-group id="goal" v-model="dietPlanInfo.dietPlanFunc.goal" :disabled="dietPlanInfo.isSystem">
                                <b-form-radio value="0"><span class="icon-arrow-right"></span> Συντήρηση</b-form-radio>
                                <b-form-radio value="1"><span class="icon-arrow-down"></span> Μείωση βάρους</b-form-radio>
                                <b-form-radio value="2"><span class="icon-arrow-up"></span> Αύξηση βάρους</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <div v-if="!dietPlanInfo.dietPlanFunc.manualKcal">
                            <label v-if="parseInt(dietPlanInfo.dietPlanFunc.goal) === 1"> <span class="icon-arrow-down"></span> Πόσα κιλά θέλετε να μειωθούν ανά μήνα;</label>
                            <label v-if="parseInt(dietPlanInfo.dietPlanFunc.goal) === 2"><span class="icon-arrow-up"></span> Πόσα κιλά θέλετε να προστεθούν ανά μήνα;</label>
                            <b-input-group v-if="parseInt(dietPlanInfo.dietPlanFunc.goal) !== 0" append="kg">
                                <b-form-input id="goalValue" v-model="dietPlanInfo.dietPlanFunc.goalValue" type="text" @input="dietPlanInfo.dietPlanFunc.goalValue = replaceCommaWithDot(dietPlanInfo.dietPlanFunc.goalValue)"></b-form-input>
                            </b-input-group>
                            <b-alert :show="parseFloat(dietPlanInfo.dietPlanFunc.BMR) > parseFloat(dietPlanInfo.dietPlanFunc.requiredKcal)" variant="danger" class="mt-2 text-center">
                                ΠΡΟΣΟΧΗ! Η πρόσληψη ενέργειας δεν πρέπει ποτέ να είναι μικρότερη από τον βασικό μεταβολισμό
                            </b-alert>
                        </div>


                    </div>
                </div>
                <hr>
              <div v-if="dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" class="row total-stats pb-3">
                <div class="col-md-4">
                  <label>Υδατάνθρακες:</label>
                  <b-input-group append="/ kg σωματικού βάρους">
                    <b-form-input  v-model="dietPlanInfo.dietPlanFunc.carbsPerKg"
                                   type="text" placeholder="Υδατάνθρακες ανά κιλό σωματικού βάρους"
                                   @input="dietPlanInfo.dietPlanFunc.carbsPerKg = replaceCommaWithDot(dietPlanInfo.dietPlanFunc.carbsPerKg)"
                                   :disabled="dietPlanInfo.isSystem"></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-4">
                  <label>Πρωτεϊνες:</label>
                  <b-input-group append="/ kg σωματικού βάρους">
                    <b-form-input v-model="dietPlanInfo.dietPlanFunc.proteinsPerKg"
                                  type="text" placeholder="Πρωτεϊνες ανά κιλό σωματικού βάρους"
                                  @input="dietPlanInfo.dietPlanFunc.proteinsPerKg = replaceCommaWithDot(dietPlanInfo.dietPlanFunc.proteinsPerKg)"
                                  :disabled="dietPlanInfo.isSystem"></b-form-input>
                  </b-input-group>
                </div>
                <div class="col-md-4">
                  <label>Λίπη:</label>
                  <b-input-group append="/ kg σωματικού βάρους">

                    <b-form-input v-model="dietPlanInfo.dietPlanFunc.fatsPerKg"
                                  type="text" placeholder="Λίπος ανά κιλό σωματικού βάρους"
                                  @input="dietPlanInfo.dietPlanFunc.fatsPerKg = replaceCommaWithDot(dietPlanInfo.dietPlanFunc.fatsPerKg)"
                                  :disabled="dietPlanInfo.isSystem"></b-form-input>
                  </b-input-group>
                </div>

              </div>

                <div class="row">
                    <div v-if="!dietPlanInfo.dietPlanFunc.manualKcal && !dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" class="col-md-6">
                        <b-form-group label="Βασικός Μεταβολισμός (kcal):" label-for="bmr">
                            <b-form-input id="bmr" v-model="dietPlanInfo.dietPlanFunc.BMR" type="text" :disabled="!dietPlanInfo.dietPlanFunc.manualBMR || dietPlanInfo.isSystem"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label="Απαραίτητη ενέργεια (kcal):" label-for="required-kcal">
                            <b-form-input id="required-kcal" v-model="dietPlanInfo.dietPlanFunc.requiredKcal" type="text" :disabled="!dietPlanInfo.dietPlanFunc.manualKcal || dietPlanInfo.isSystem"></b-form-input>
                        </b-form-group>
                    </div>
                </div>


                <div v-if="!dietPlanInfo.dietPlanFunc.manualKcal && !dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" class="row">
                    <div class="col-md-6">
                        <b-form-group label="Φύλο:" label-for="edit-name">
                            <b-form-select v-model="dietPlanInfo.dietPlanFunc.sex" :state="validateSex" :disabled="dietPlanInfo.isSystem">
                                <option value="M">Άνδρας</option>
                                <option value="F">Γυναίκα</option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label="Επιλέξτε συνάρτηση:" label-for="edit-name">
                            <b-form-select v-model="dietPlanInfo.dietPlanFunc.functionToUse" :disabled="dietPlanInfo.isSystem">
                                <option value="1">Harris & Benedict</option>
                                <option value="2">Αναθεωρημένη Harris & Benedict</option>
                                <option value="3">Owen</option>
                                <option value="4">Miffin</option>
                                <option value="5">Katch-McArdle</option>
                                <option value="6">Cunningham</option>
                                <option value="7">FAO/WHO/UNU</option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>

                <div v-if="!dietPlanInfo.dietPlanFunc.manualKcal" class="row">
                    <div class="col-md-3">
                        <b-form-group label="Ηλικία:" label-for="edit-age">
                            <b-input-group append="ετών" >
                            <b-form-input id="edit-age" v-model="dietPlanInfo.dietPlanFunc.age" :state="validateAge" class="no-validate" type="text"
                                          placeholder="Εισάγετε την ηλικία του πελάτη" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                                <b-form-invalid-feedback :state="validateAge">
                                Η ηλικία πρέπει να είναι έγκυρη
                            </b-form-invalid-feedback>

                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                        <b-form-group label="Ύψος:" label-for="edit-height">
                            <b-input-group append="cm" >
                            <b-form-input id="edit-height" v-model="dietPlanInfo.dietPlanFunc.height" type="text" :state="validateHeight"
                                          placeholder="Εισάγετε το ύψος του πελάτη σε cm"
                                          :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                                <b-form-invalid-feedback :state="validateHeight">
                                Το ύψος πρέπει να είναι έγκυρο
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                        <b-form-group label="Βάρος:" label-for="edit-weight">
                            <b-input-group append="kg" >
                                <b-form-input id="edit-weight" v-model="dietPlanInfo.dietPlanFunc.weight" type="text" :state="validateWeight"
                                              placeholder="Εισάγετε το βάρους του πελάτη σε kg" :disabled="dietPlanInfo.isSystem" @input="dietPlanInfo.dietPlanFunc.weight = replaceCommaWithDot(dietPlanInfo.dietPlanFunc.weight)"></b-form-input>
                            </b-input-group>
                                <b-form-invalid-feedback :state="validateWeight">
                                Το βάρος πρέπει να είναι έγκυρο
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                        <b-form-group label="Λίπος:" label-for="edit-fat">
                            <b-input-group append="%" >
                                 <b-form-input id="edit-fat" v-model="dietPlanInfo.dietPlanFunc.fat" type="text"
                                                placeholder="Εισάγετε το λίπος του πελάτη (π.χ. 10%)" :disabled="dietPlanInfo.isSystem"
                                               @input="dietPlanInfo.dietPlanFunc.fat = replaceCommaWithDot(dietPlanInfo.dietPlanFunc.fat)"></b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback :state="validateFat">
                                Το λίπος πρέπει να είναι έγκυρο
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div v-if="!dietPlanInfo.dietPlanFunc.manualKcal && !dietPlanInfo.dietPlanFunc.useExercise && !dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" class="col-md-6" >
                        <b-form-group label="Επιλέξτε επίπεδο άσκησης:" label-for="edit-name">
                            <b-form-select v-model="dietPlanInfo.dietPlanFunc.exerciseVar" :disabled="dietPlanInfo.isSystem" @change="calculateCustomPAL">
                                <option value="1.3">(1.00-1.39) Τυπικές καθημερινές δραστηριότητες (Οικιακά καθήκοντα, χαλαρό περπάτημα, καθιστική εργασία)</option>
                                <option value="1.55">(1.4-1.59) Χαμηλή δραστηριότητα, τυπικές καθημερινές δραστηριότητες και 30-60 λεπτά καθημερινής μέτριας δραστηριότητας (περπάτημα με 5-7 χλμ. / Ώρα)</option>
                                <option value="1.7">(1.6-1.89) Ενεργοί άνθρωποι, τυπικές καθημερινές δραστηριότητες συν 60 λεπτά καθημερινής μέτριας δραστηριότητας</option>
                                <option value="1.9">(1.9-2.5) Πολύ δραστήριοι, τυπικές καθημερινές δραστηριότητες συν τουλάχιστον 60 λεπτά καθημερινής μέτριας δραστηριότητας συν επιπλέον 60 λεπτά έντονης ή 120 λεπτά μέτριας δραστηριότητας</option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div v-if="!dietPlanInfo.dietPlanFunc.manualKcal && !dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" class="col-md-6">
                        <label>Χειροκίνητος συντελεστής PAL: {{dietPlanInfo.dietPlanFunc.customPAL}}</label>
                        <br>(Εύρος: από {{minPAL}} έως {{maxPAL}})
                        <b-form-input id="custom-pal" v-model="dietPlanInfo.dietPlanFunc.customPAL" type="range" :min="minPAL" :max="maxPAL" step="0.01" :disabled="dietPlanInfo.isSystem"></b-form-input>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-12 p-2">
                        <b-alert v-model="hasError" variant="danger" dismissible>{{errorMsg}}</b-alert>
                    </div>

                </div>

            </b-form>
        </b-overlay>
    </div>

</template>
<style lang="scss">
</style>
