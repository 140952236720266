<script>
    import moment from "moment"
    import PrintDietPlanAnalysis from "./partials/analysis";
    import PrintDietPlanExercises from "./partials/exercises";
    import ShoppingList from "@views/dietPlan/details/print/partials/shoppingList";
    import FoodTable from "@views/dietPlan/details/print/partials/foodTable";
    import Header from "@views/dietPlan/details/print/partials/header";
    import Vue from 'vue'
    import printJS from "print-js"
    import SendToEmailForm from "@components/print/SendToEmailForm";


    export default{
        components: {SendToEmailForm, Header, FoodTable, ShoppingList, PrintDietPlanExercises, PrintDietPlanAnalysis},
        props:{
            dietPlan:{
                default: () => {return {}},
                type: Object
            },
          athleteId:String
        },
      computed:{
          isSafari(){
            return navigator.vendor === "Apple Computer, Inc.";
          }
      },
        data(){
            return {
                user: this.$store ? this.$store.state.auth.currentUser : {} || {},
                printingEnabled: false,
                isPrinting: false,
                templates: this.$store ? (this.$store.state.auth.printTemplates || []) : [] || [],
                options:null,
                selectedTemplate: 1,
                savingAsPdf: false,
                printTypes:{
                    print: 'print',
                    save: 'save',
                    email: 'email'
                },
                email:{
                  to: '',
                  subject: null,
                  content: ''
                },
                showEmailForm: false,
                emailSent: false
            }
        },
        async created(){
            setTimeout(() => {this.printingEnabled=true}, 500);
            if(this.templates.length === 0){
                await this.$store.dispatch('auth/getPrintTemplates');
                this.templates = this.$store.state.auth.printTemplates;
            }
            this.selectedTemplate = this.templates.find(i => i.isStarred) ? this.templates.find(i => i.isStarred).id : 1;
            this.changeOption();

            this.getAthleteEmail();
        },

        methods:{
          getAthleteEmail(){
            if(!this.athleteId) return;
            this.$axios.get(`/athlete/${this.athleteId}`).then( result => {
              this.email.to = result.data.email
            }).catch(e => {});
          },
            printDietPlan(type){
                this.isPrinting = true;

                this.$nextTick(()=>{
                  let divToPrint = this.$refs['print-diet-plan'].outerHTML;
                  this.isPrinting=false;
                  this.savingAsPdf = true;

                  let content = '<html><head><title>Εκτύπωση: ' + this.dietPlan.name + '</title>';

                  content += '<link rel="stylesheet" type="text/css" href="https://edlservices.s3.eu-north-1.amazonaws.com/athlisis-print-v2.css">';
                  content +=  '<style>' +
                      // page stylings
                      '@page{margin:.03in!important;}tr,.recipes-list,.not-forget-list{break-inside: avoid-page;} tr:nth-child(even){background-color:initial}' +
                      // landscape stylings
                      `${ this.options.orientation === 'landscape' && !this.options.onePagePerDay ? `@page{size: A4 landscape;} body{width:297mm}.col-md-4{width:33%}` : ``}`+
                      // body stylings
                      `body{-webkit-print-color-adjust: exact !important;margin:0!important;background-color:${this.options.colors.background}!important;}`+
                      // table intro
                      `.print-intro{background-color:${this.options.colors.headerBackground}; padding:0;margin-bottom:20px;color:${this.options.colors.headerTextColor}}`+
                      // table stylings
                      `${this.options.hasBorder ? `#dietPlan-table, td {border: 1px solid ${this.options.colors.border}!important;}` : ''}`+
                      // stripes stylings
                      `${this.options.stripes === 'rows' ? `tr:nth-child(even){background-color:${this.options.colors.stripes}}`: `` }`+
                      `${this.options.stripes === 'columns' ? `td:nth-child(odd){background-color:${this.options.colors.stripes}}`: `` }`+
                      `${this.options.stripes === 'mix' ? `tr:nth-child(even) td:nth-child(odd){background-color:${this.options.colors.stripes}}
                    tr:nth-child(odd) td:nth-child(even){background-color:${this.options.colors.stripes}}`: `` }`+
                      // header stylings
                      `.table-meal-day-name{background-color:${this.options.colors.tableHeader};color:${this.options.colors.text.leftTitles};
                        letter-spacing: ${this.options.letterSpacing.leftTitles}px;font-size:${this.options.sizes.text.leftTitles}px}`+
                      // food stylings
                      `.table-meal-food{font-size: ${this.options.sizes.text.foodText}px;color: ${this.options.colors.text.foodText};
                        letter-spacing: ${this.options.letterSpacing.foodText}px; padding-bottom: ${this.options.bottomSpacing.foodText}}`+
                      // table header stylings
                      `.row-header{font-size: ${this.options.sizes.text.topTitles}px;letter-spacing: ${this.options.letterSpacing.topTitles}px;
                        margin-bottom: ${this.options.bottomSpacing.topTitles};background-color:${this.options.colors.topTitleBg};
                        color: ${this.options.colors.text.topTitles};text-align:center;}`+
                      `${this.options.showCategoryColors ? '' +
                          '.meal-category-1{color:#fff!important;background-color: #1abc9c!important;margin: 2px;padding: 1px;}' +
                          '.meal-category-2{color:#fff!important;background-color:#f7b84b!important;margin: 2px;padding: 1px;}' +
                          '.meal-category-3{color:#fff!important;background-color:#f672a7!important;margin: 2px;padding: 1px;}' +
                          '.meal-category-4{color:#fff!important;background-color:#3155A6!important;margin: 2px;padding: 1px;}' +
                          '.meal-category-5{color:#242424!important;background-color:#d3d3d3!important;margin: 2px;padding: 1px;}': ''}`+

                      'gridLines: { display:false } table td{width:20%!important} ' +
                      '.table-meal-day-name img{display:none}.table-meal{padding:0px;} ' +
                      'p{margin-block:0!important;}' +
                      '.apexcharts-svg{overflow:visible} .apexcharts-svg foreignObject{overflow:visible}'
                      +`${this.options.orientation === 'landscape' ? '.table-meal-day-name{min-width:55px;width:10%!important}' : ''}`
                      +'</style>';

                  content += '</head><body>';
                  content += divToPrint;
                  content += '</body></html>';

                  this.$axios.post('/general/print-pdf', {html: content, isLandscape: this.options.orientation === 'landscape', send_to_customer_email: type === this.printTypes.email, email: this.email},
                      {'responseType': 'blob'}).then(async res => {

                    const url = URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));

                    if(type === this.printTypes.save){
                      let link = document.createElement('a');
                      link.href = url;
                      link.download= this.dietPlan.name + ".pdf";
                      link.click();

                    }
                    if(type === this.printTypes.print){
                      // safari sepcfiic behavior.
                      if(this.isSafari){
                        try{
                          let newWin = window.open(url, "","toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=900,height=900");
                          newWin.focus();
                          setTimeout( function (){ newWin.print(); }, 500);
                        }catch(e){
                          this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                            text: 'Παρακαλώ ακολουθήστε τις οδηγίες που υπάρχουν για σωστή εκτύπωση στο Safari.'});
                        }

                      }else{
                        printJS(url);
                      }
                    }
                    if(type === this.printTypes.email){
                      this.emailSent = true;
                    }

                  }).catch(e=>{
                    if(e && e.response && e.response.status === 429){
                      this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.rateLimitEmail')});
                      return;
                    }
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: this.$t('errors.text')});

                  }).finally(()=>{
                    this.savingAsPdf=false;
                  });
                })

            },
            getDate(){
                return moment().format('DD-MM-YYYY');
            },
            changeOption(){
                let template = JSON.parse(JSON.stringify(this.templates.find(x => {return x.id === parseInt(this.selectedTemplate)})));
                this.options = template.options;
                if(!this.options.hasOwnProperty('showNotes')) Vue.set(this.options, 'showNotes', true);
               if(!this.options.hasOwnProperty('showLogo')) Vue.set(this.options, 'showLogo', true);
                if(!this.options.hasOwnProperty('translations') || !this.options.translations.hasOwnProperty('measures') || !this.options.translations.hasOwnProperty('texts')) Vue.set(this.options, 'translations', {
                dayMeals: {
                  0: 'Πρωϊνό',
                  1: 'Δεκατιανό',
                  2: 'Μεσημεριανό',
                  3: 'Απογευματινό',
                  4: 'Βραδινό'
                },
                titles:{
                  plan: 'Όνομα πλάνου',
                  date: 'Ημερομηνία',
                  dietitian: 'Διατροφολόγος',
                },
                dayNames: {
                  0: 'Δευτέρα',
                  1: 'Τρίτη',
                  2: 'Τετάρτη',
                  3: 'Πέμπτη',
                  4:'Παρασκευή',
                  5:'Σάββατο',
                  6:'Κυριακή'
                },
                measures: {
                  1:'τεμ. (μεγ.)',
                  2:'τεμ. (μικ.)',
                  3:'τεμ.',
                  4:'φλ.',
                  6:'κ.γ.',
                  7:'κ.σ.',
                  8:'γρ.',
                  9:'φλ. τσ.',
                  10:'τεμ. (μετρ.)',
                  11:'ml',
                  12:'scoop',
                },
                texts:{
                  energy: 'Ενέργεια',
                  carbs: 'Υδατάνθρακες',
                  proteins: 'Πρωτεϊνες',
                  fats: 'Λίπη',
                  freskies: 'Φρέσκιες',
                  amylouxes: 'Αμυλούχες',
                  lipi: 'Λίπη',
                  zoikes: 'Ζωϊκές',
                  sintheta:'Σύνθετα',
                  notes:'Σημειώσεις',
                  shoppingList: 'Λίστα με ψώνια',
                  dontForget: 'Μην ξεχάσετε επίσης τα υλικά των συνταγών...',
                  doNotForget: 'Μην ξεχάσω...',
                  stats: 'Στατιστικά τροφίμων'
                }
              });
                if(!this.options.hasOwnProperty('onePagePerDay')) Vue.set(this.options, 'onePagePerDay', false);
            }
        }
    }

</script>

<template>
<div id="print-diet-plan-wrapper" ref="printSpace">

    <b-alert variant="primary" show>Αυτή η εκτύπωση έγινε η βασική και μοναδική διαθέσιμη στις <strong>11/12/2022</strong>.
      <br> Εάν αντιμετωπίζετε κάποιο πρόβλημα ή σας φαίνεται κάτι περίεργο, παρακαλούμε ενημερώστε μας στο info@athlisis.com</b-alert>

  <b-alert v-if="isSafari" variant="warning" show>
    Επειδή χρησιμοποιείτε Safari, <a href="https://athlisis-new.s3.eu-north-1.amazonaws.com/athlisis_safari_print_e2dbe5442e.pdf" target="_blank">
    παρακαλώ ακολουθήστε τις οδηγίες για ορθή εκτύπωση κάνοντας κλικ εδώ.
  </a>
  </b-alert>
    <b-form-group v-if="options">
        <label for="template-select">Πρότυπο εκτύπωσης:</label>
        <select v-if="templates.length >0 " id="template-select" v-model="selectedTemplate" class="custom-select mb-3" @change="changeOption">
            <option v-for="item in templates" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>

        <label for="orientation-select">Προσανατολισμός εκτύπωσης:</label>
        <select id="orientation-select" v-model="options.orientation" class="custom-select mb-3" :disabled="options.onePagePerDay">
            <option value='normal'>Κατακόρυφα (portrait) </option>
            <option value='landscape'>Οριζόντια (landscape)</option>
        </select>

        <div style="background-color:#FEF7ED;padding:5px 20px;margin-bottom:10px;">
            <h5> Γενικές ρυθμίσεις</h5>
            <b-form-checkbox id="showDietitianName" v-model="options.showDietitianName" name="showDietitianName">Εμφάνιση του ονόματός σας</b-form-checkbox>
            <b-form-checkbox id="showDate" v-model="options.showDate" name="showDate">Εμφάνιση ημερομηνίας</b-form-checkbox>
          <b-form-checkbox id="showPlanName" v-model="options.showDietPlanName" name="showPlanName">Εμφάνιση ονόματος πλάνου</b-form-checkbox>
           <b-form-checkbox id="showNotes" v-model="options.showNotes" name="showNotes">Εμφάνιση σημειώσεων (σε νέα σελίδα)</b-form-checkbox>
          <b-form-checkbox id="showLogo" v-model="options.showLogo" name="showLogo">Εμφάνιση λογοτύπου</b-form-checkbox>
        </div>


        <div style="background-color:#f4f5f7;padding:5px 20px;margin-bottom:10px;">
            <h5><i class="fas fa-utensils"></i> Διατροφικό πλάνο</h5>
            <b-form-checkbox id="onePagePerDay" v-model="options.onePagePerDay" name="onePagePerDay">Εμφάνιση μιας ημέρας ανά σελίδα</b-form-checkbox>

            <b-form-checkbox id="showQuantity" v-model="options.showQuantities" name="showQuantity">Εμφάνιση ποσοτήτων τροφίμων</b-form-checkbox>
            <b-form-checkbox id="showAnalysis" v-model="options.showAnalysis" name="showAnalysis">Εμφάνιση της ανάλυσης του πλάνου</b-form-checkbox>
            <b-form-checkbox v-if="dietPlan.dietPlanFunc.useExercise" id="showExercises" v-model="options.showExercises" name="showExercises">Εμφάνιση της εβδομαδιαίας άσκησης</b-form-checkbox>
        </div>


        <div style="background-color:#E8F8F5;padding:5px 20px;">
            <h5>Λίστα για τα ψώνια</h5>
        <b-form-checkbox id="shoppingList" v-model="options.shoppingList.show" name="showshoppingList">Εκτύπωση λίστας τροφίμων για ψώνια;</b-form-checkbox>

            <div v-if="options.shoppingList.show">
                <b-form-checkbox id="recipesShopping" v-model="options.shoppingList.showRecipes" name="recipesShoppingL">Εκτύπωση σύνθετων τροφίμων;</b-form-checkbox>
                <b-form-checkbox id="personalNotes" v-model="options.shoppingList.showNotForgetList" name="personalNotesL">Εκτύπωση λίστας "μην ξεχάσω...";</b-form-checkbox>
            </div>
        </div>

      <div class="col-md-12 text-center mt-3">
        <b-button variant="success" class="mt-1" :disabled="savingAsPdf" @click="printDietPlan(printTypes.print)">
          <b-spinner v-if="savingAsPdf" small></b-spinner> <span v-else><i class="icon-printer"></i>
             Εκτύπωση</span></b-button>

        <b-button variant="primary" :disabled="savingAsPdf" class="ml-2 mt-1" @click="printDietPlan(printTypes.save)"> <b-spinner v-if="savingAsPdf" small></b-spinner> <span v-else><i class="fas fa-file-pdf"></i>
             Λήψη ως PDF</span></b-button>

        <b-button :variant="showEmailForm ? 'primary' : 'outline-primary'" class=" ml-2 mt-1 " :disabled="savingAsPdf || emailSent || isEducational" @click="showEmailForm = !showEmailForm">
          <i class="fe-mail"></i> Αποστολή με e-mail <b-badge v-if="emailSent" variant="success">Έχει αποσταλεί</b-badge>
        </b-button>

      </div>


      <send-to-email-form :email="email" :show-email-form="showEmailForm" :email-sent="emailSent"
                          :saving-as-pdf="savingAsPdf" @send="printDietPlan(printTypes.email)"></send-to-email-form>

    </b-form-group>
    <div v-if="options" :style="isPrinting ? 'display:block;' : 'display:none;'">
        <div id="print-diet-plan" ref="print-diet-plan" class="print-diet-plan" >
          <Header :options="options" :user="user" :is-printing-window="true" :diet-plan-name="dietPlan.name"/>
          <div class="diet-plan-table-wrapper">
              <food-table :options="options" :show-names="!dietPlan.showOptions" :one-page-per-day="options.onePagePerDay"></food-table>
          </div>

          <div v-if="!dietPlan.isTextOnly && options.showExercises && dietPlan.dietPlanFunc.useExercise" class="pagebreak"></div>
          <div v-if="!dietPlan.isTextOnly && options.showExercises && dietPlan.dietPlanFunc.useExercise" class="use-exercise-wrapper">
            <h1 style="font-size:16px!important;">Εβδομαδιαία άσκηση</h1>
          </div>
          <print-diet-plan-exercises v-if="!dietPlan.isTextOnly && options.showExercises && dietPlan.dietPlanFunc.useExercise" :diet-plan-info="dietPlan"></print-diet-plan-exercises>

          <div v-if="!dietPlan.isTextOnly && options.showAnalysis" class="pagebreak"> </div>
          <print-diet-plan-analysis v-if="!dietPlan.isTextOnly && options.showAnalysis" :options="options" :is-landscape="options.orientation === 'landscape' && !options.onePagePerDay"></print-diet-plan-analysis>

          <div v-if="!dietPlan.isTextOnly && options.shoppingList.show" class="pagebreak"></div>
          <shopping-list v-if="!dietPlan.isTextOnly" :options="options" :show="options.shoppingList.show"
                         :show-not-forget-list="options.shoppingList.showNotForgetList" :show-recipes="options.shoppingList.showRecipes"/>

          <div v-if="options.showNotes && dietPlan.notes" class="pagebreak"></div>
          <div v-if="options.showNotes && dietPlan.notes">
              <Header :options="options" :user="user" :is-printing-window="true" :diet-plan-name="dietPlan.name"/>
              <div class="p-2" style="padding:5px 15px;"><h2 style="font-size:14px;">{{ this.options.translations.texts.notes }}</h2><hr/><div style="font-size:11px;" v-html="dietPlan.notes"/></div>
          </div>
        </div>

    </div>
</div>

</template>

<style lang="scss">
    #print-diet-plan{
        max-height:85vh;
        overflow-y:auto;
        #dietPlan-table{
            .table-meal{
                cursor:initial;
                &:hover{
                    transform:none;
                    box-shadow:none;
                }
            }
            .table-meal-day-name{
                cursor:initial;
            }
        }
    }
</style>
