<template>

  <div v-if="show" style="padding:20px; font-size:12px;" class="shopping-list-wrapper">
    <div style="background-color:#FEF7ED">
      <h3 style="padding-left:20px;padding-top:20px;">{{options.translations.texts.shoppingList}}</h3>
      <div style="display: flex;flex-wrap: wrap;padding-bottom: 20px;">

        <div v-for="item in shoppingList" :key="item" style="width: 28%;padding-bottom: 7px;margin-left:15px;">
          &#9634; {{item}}
        </div>
      </div>
    </div>

    <div v-if="showRecipes && recipesShoppingList.size > 0" style="background-color:#f4f5f7;" class="recipes-list">
      <h3 style="padding-left:20px;padding-top:20px;"> {{ options.translations.texts.dontForget }}</h3>
      <div style="display:flex;flex-wrap:wrap;padding-bottom:20px;">
        <div v-for="item in recipesShoppingList" :key="item" style="width:28%;padding-bottom: 7px;margin-left:15px;">
          &#9634; {{item}}
        </div>
      </div>

    </div>
    <div v-if="showNotForgetList" style="background-color:#E8F8F5;" class="not-forget-list">
      <h3 style="padding-left:20px;padding-top:20px;">{{ options.translations.texts.doNotForget }}</h3>
      <div style="display:flex;flex-wrap:wrap;padding-bottom:20px;">
        <div v-for="item in [0,1,2,3,4,5]" :key="item" style="width:28%;padding-bottom: 7px;margin-left:15px;">
          &#9634; <hr>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default{

  props:{
    options: {
      type: Object,
      required: true
    },
    show:{
      type: Boolean,
      defaultValue: true
    },
    showRecipes: {
      type: Boolean,
      defaultValue: true
    },
    showNotForgetList: {
      type: Boolean,
      defaultValue: true
    }
  },
  computed:{
    ...mapState({
      shoppingList: state => state.dietPlan.shoppingList,
      recipesShoppingList: state => state.dietPlan.recipesShoppingList
    })
  },


}

</script>
