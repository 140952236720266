<template>

  <div v-if="showEmailForm && !emailSent" class="col-12 p-2 mt-3">

    <div class="row">
      <div class="col-12">
        <label>Θέμα e-mail </label>
        <b-form-input v-model="email.subject"/>

      </div>
      <div class="col-12 mt-2">
        <label>E-mail πελάτη       </label>
        <b-form-input v-model="email.to"/>

      </div>
      <div class="col-12 mt-2">
        <label>Σημειώσεις </label>
        <b-form-textarea v-model="email.content"/>
      </div>
    </div>


    <div class="text-right mt-3">
      <b-button variant="success" class=" ml-2 " :disabled="savingAsPdf || emailSent" @click="$emit('send')">
        <b-spinner v-if="savingAsPdf" small></b-spinner> <span v-else><i class="fe-mail"></i> Αποστολή
            </span>
      </b-button>
    </div>

  </div>
</template>

<script>
export default{

  props:{
    email: Object,
    showEmailForm: Boolean,
    emailSent: Boolean,
    savingAsPdf: Boolean
  }

}
</script>