'<script>
    import Vue from "vue";
    import {mapState} from "vuex";
    import downloadCsv from 'vue-json-csv'

    export default{
        components: {downloadCsv},
        props: {
          showNames: {
                type: Boolean,
               defaultValue: true
            },
            edits: {
                default: () => {
                    return {}
                },
                type: Object
            }
        },

        data(){
            return{
                loadingDietPlan: true,
                dayNamesAlt: ['1η Επ.', '2η Επ.', '3η Επ.', '4η Επ.', '5η Επ.', '6η Επ.', '7η Επ.'],
                diagrams: {
                    stroke:{
                        width: 2,
                        curve: 'smooth'
                    }
                },
                pieOptions:{
                    chart: {
                        width: 250,
                        type: 'pie',
                    },
                    labels: ['Φρέσκες', 'Αμυλούχες','Λίπη', 'Ζωϊκές', 'Σύνθετα τρόφιμα', 'Ποτά / Γενικά'],
                    colors:['#1abc9c', '#3155A6','#f7b84b','#f1556c', '#d3d3d3', '#0891b2'],
                    tooltip:{
                        y: {
                            formatter: (value) => { return value + ' τρόφιμα' },
                        },
                    },
                    legend:{
                        position: 'bottom'
                    },
                    stroke:{
                        width:0
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 220
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                foodStatistics: [],
                driObj: {
                  age: null,
                  gender: null
                }
            }

        },

        computed:{
          ...mapState({
            foods: state => state.food._foods,
            days: state => state.pdf.dietPlan,
            dietPlanInfo: state => state.pdf.dietPlanInfo,
            loadingDone: state => state.pdf.loadingDone,
            dris: state => state.food._dris
          })
        },
        watch: {
          loadingDone(val){
            if(val) {
              this.displayAndCalculateFoods();
              this.driObj = this.formatDriObject(this.dietPlanInfo.dietPlanFunc.sex, this.dietPlanInfo.dietPlanFunc.age);
            }
          }
        },
        async created(){
            await this.fetchFoods(); // load foods from vuex store or await for api call!
            // this.foods = this.$store.state.food._foods; // assign to local var
            // if(!this.foods){ // error call in case foods weren't fetched from API and do not exist locally (hmm?)
            //     this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των τροφίμων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            // }
            if(this.loadingDone){
              this.displayAndCalculateFoods();
            }
            this.driObj = this.formatDriObject(this.dietPlanInfo.dietPlanFunc.sex, this.dietPlanInfo.dietPlanFunc.age);
        },


        methods:{
            getOptions(type){
                let color;
                let tooltipVal;
                // let customFormatter;

                if(type === 'CALORIES'){
                    color =  ['#3155A6'];
                    tooltipVal = 'kcal';
                }

                if(type==='CARBS'){
                    color = ['#1abc9c'];
                    tooltipVal = 'g';
                }

                if(type==='PROTEINS'){
                    color = ['#f7b84b'];
                    tooltipVal = 'g';
                }

                if(type==='FATS'){
                    color=['#f1556c'];
                    tooltipVal='g';
                }

                if(type==='CARBS-PROTEINS'){
                    color=['#1abc9c', '#f7b84b'];
                    tooltipVal='g';
                }

                let diagramOpt = {
                    chart: {
                        toolbar: {
                            show: false,
                            tools: {
                                download: false,
                                selection: false,
                                zoom: false,
                                zoomin: false,
                                zoomout: false,
                                pan: false,
                                reset:false,
                                customIcons: []
                            },
                        },

                    },
                    tooltip:{
                        y: {
                            formatter: (value) => { return value + tooltipVal },
                        },
                    },
                    stroke: this.diagrams.stroke,
                    xaxis: {
                        categories: this.showNames ? this.dayNamesAlt : this.dayNamesShort
                    },
                    yaxis: {
                        forceNiceScale: true
                    },
                    animations:{
                        enabled: false
                    }
                };

                diagramOpt.chart.id = type;
                diagramOpt.colors = color;
                return diagramOpt;
            },
            getSeries(type){
                if(type==='CALORIES'){
                    return [{
                        name: 'Θερμίδες',
                        data:  [...this.days.map(x => {return x.totalStats.calories })]
                    }]
                }else if(type==='PROTEINS'){
                    return [{
                        name: 'Πρωτεϊνη',
                        data:  [...this.days.map(x => {return x.totalStats.proteins })]
                    }]
                }else if(type==='CARBS'){
                    return [{
                        name: 'Υδατάνθρακες',
                        data:  [...this.days.map(x => {return x.totalStats.carbs })]
                    }]
                }else if(type==='FATS'){
                    return [{
                        name: 'Λίπη: ',
                        data:  [...this.days.map(x => {return x.totalStats.fats })]
                    }]
                }else if(type==='CARBS-PROTEINS'){
                    return [{
                        name: 'Υδατάνθρακες',
                        data:  [...this.days.map(x => {return x.totalStats.carbs })]
                    },
                    {
                        name: 'Πρωτεϊνες',
                        data: [...this.days.map(x => {return x.totalStats.proteins })]
                    }]
                }else if(type==='FOOD-PIE'){
                    return this.foodStatistics
                }

            },

            displayAndCalculateFoods(){
                this.loadingDietPlan=true;
                let foodStats = [0,0,0,0,0,0];
                for(let i=0; i<7;i++){
                    let dayStats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};
                    for(let j=0;j<this.dietPlanInfo.dietPlanMealOptions.totalMeals;j++){
                        let totalStats = this.days[i][j].statistics;

                        // calculate total foods per day
                        for(let fd of this.days[i][j].foods){
                          fd.category = fd.food.category;
                          foodStats[fd.category-1] += 1;
                        }

                        dayStats.calories += totalStats.calories;
                        dayStats.carbs += totalStats.carbs;
                        dayStats.proteins += totalStats.proteins;
                        dayStats.fats += totalStats.fats;

                        if(totalStats.microNutrients) {
                          for (let nutrient of this.microNutrientFields) {
                            if (!totalStats.microNutrients[nutrient]) continue;
                            if (!dayStats.microNutrients[nutrient]) dayStats.microNutrients[nutrient] = 0;
                            dayStats.microNutrients[nutrient] += totalStats.microNutrients[nutrient];
                          }
                        }

                    }
                    dayStats.calories = dayStats.calories.toFixed(0);
                    dayStats.carbs = dayStats.carbs.toFixed(0);
                    dayStats.proteins = dayStats.proteins.toFixed(0);
                    dayStats.fats = dayStats.fats.toFixed(0);
                    Vue.set(this.days[i], 'totalStats', dayStats);

                }
                this.foodStatistics = foodStats;
                this.loadingDietPlan=false;

            },
          getAverage(type){
              let total = 0;
              for(let day of this.days){
                total += parseFloat(day.totalStats[type]);
              }
              // only count days with calories > 0 (don't count empty days)
              let totalDays = this.days.filter(x => parseFloat(x.totalStats['calories']) > 0).length

              return (total / totalDays).toFixed(2);
          },
          getMacroCSVDATA(){
            let csvData = [];

            for(let i=0;i<this.dayNames.length;i++) {
              if (parseFloat(this.days[i].totalStats.calories) === 0) continue;

              let item = this.days[i].totalStats;
              const calories = (item.carbs*4) + (item.proteins*4) + (item.fats * 9);
              let micronutrientItem = {
                'Ημέρα': this.dayNames[i],
                'Ενέργεια (kcal)': calories,
                'Υδατάνθρακες (g)': item.carbs,
                'Υδατάνθρακες (% ημέρας)': this.calculateDayPercentage(calories, item.carbs, 4),
                'Πρωτεϊνες (g)': item.proteins,
                'Πρωτεϊνες (% ημέρας)': this.calculateDayPercentage(calories, item.proteins, 4),
                'Λίπη (g)': item.fats,
                'Λίπη (% ημέρας)': this.calculateDayPercentage(calories, item.fats, 9),
              };
              csvData.push(micronutrientItem);
            }
            return csvData;
          },
          calculateDayPercentage(totalValue, macroValue, multiplier){
            if(parseFloat(macroValue) < 1)  return 0;
            return +(((parseFloat(macroValue) * multiplier) / parseFloat(totalValue)) * 100).toFixed(2)
          },
          getCSVData(includeDRI){
            let csvData = [];

            for(let i=0;i<this.dayNames.length;i++){
              if(parseFloat(this.days[i].totalStats.calories) === 0) continue;

              let micronutrientItem = {
                'Ημέρα': this.dayNames[i]
              };
              for(let j=0;j<this.microNutrientFields.length;j++){
                let nutrient = this.microNutrientFields[j];
                micronutrientItem[this.microNutrientTranslation[nutrient]] = this.days[i].totalStats.microNutrients[nutrient] ? this.days[i].totalStats.microNutrients[nutrient].toFixed(2) : 0;
                if(includeDRI) micronutrientItem[this.microNutrientTranslation[nutrient] + ' (DRI %)']  =  this.days[i].totalStats.microNutrients[nutrient] ? this.calculateNutrientDri(nutrient, this.days[i].totalStats.microNutrients[nutrient], false) : '-';

              }
              csvData.push(micronutrientItem);
            }
            return csvData;
          },
          getMacroFileName(){
            return ('export-macronutrients-' + this.formatDate(new Date()) + '.csv').trim()
          },
          getFileName(){
              return ('export-micronutrients-' + this.formatDate(new Date()) + '.csv').trim()
          },
          calculateNutrientDri(nutrient, value, showLabel){
              if(this.dris[nutrient]){

                let dri;
                try{
                  dri = this.dris[nutrient][this.driObj.gender][this.driObj.age]['dri']
                }catch(e){
                  return '-'
                }
                let percDri = 100 * value / dri

                return +percDri.toFixed(0) + (showLabel ?  '% (' + dri + ')' : 0);
              }else{
                return '-'
              }
          },
          findNutrientUpperLimit(nutrient){
            if(this.dris[nutrient]){
              try{
                return this.dris[nutrient][this.driObj.gender][this.driObj.age]['up'] || '-'
              }catch(e){
                return '-'
              }

            }else{
              return '-'
            }
          }
        },
    }
</script>

<style lang="scss">
#program-analysis{

  .nutrient-item{
    .nutrient-name{
      font-size:0.8rem!important;
      margin-right:2rem;
      max-width:200px;
      min-width:200px;
      display:inline-block;
    }
    .nutrient-value{
      display:inline-block;
    }
  }
  .nutrient-wrapper{
    max-height:450px;
    overflow-y:scroll;
  }
  .nav-bordered{
    border-color:transparent;
  }
  .micronutrients-table{
    th{
      font-weight:900;
      width:25%;
    }
    tr{
      height:2.5em;
      td{
        font-weight:300;
      }
    }

  }
}
</style>

<template>

    <div id="program-analysis" class="card-body">
        <b-overlay :show="loadingDietPlan" rounded="sm">
          <b-tabs>
            <b-tab title="Μακροθρεπτικά">
              <download-csv class="btn btn-light" :data="getMacroCSVDATA()" :name="getMacroFileName()"><i class="fe-download"/> Αποθήκευση ως CSV</download-csv>
              <div v-if="!loadingDietPlan">
                <div class="row">.
                  <div class="col-md-12">
                    <div class="row mb-3 p-2">
                      <div class="col-lg-3 col-md-6 p-1" >
                        <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                          <h4>{{getAverage('calories')}} kcal</h4>
                          <span class="text-muted">Μέσος όρος ενέργειας <br>(μόνο ημέρες με τρόφιμα)</span>
                        </div>

                      </div>
                      <div class="col-lg-3 col-md-6 p-1" >
                        <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                          <h4>{{getAverage('proteins')}} g</h4>
                          <span class="text-muted">Μέσος όρος πρωτεϊνών  <br>(μόνο ημέρες με τρόφιμα)</span>
                          </div>
                      </div>
                      <div class="col-lg-3 col-md-6 p-1">
                        <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                          <h4>{{getAverage('carbs')}} g</h4>
                          <span class="text-muted">Μέσος όρος υδατανθράκων  <br>(μόνο ημέρες με τρόφιμα)</span>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6 p-1">
                        <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                          <h4>{{getAverage('fats')}} g</h4>
                          <span class="text-muted">Μέσος όρος λιπών  <br>(μόνο ημέρες με τρόφιμα)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <h5 style="min-height: 35px;"> Ενέργεια</h5>
                    <apexchart height="300px" type="line" :options="getOptions('CALORIES')" :series="getSeries('CALORIES')"></apexchart>
                  </div>
                  <div class="col-md-4">
                    <h5><img src="@assets/images/icons/icon-protein-dark.png" alt="icon-apple" width="45px"/>Υδατάνθρακες  / <img src="@assets/images/icons/icon-amulouxa-dark.png" alt="icon-apple" width="45px"/>Πρωτεϊνες</h5>
                    <apexchart height="300px" type="line" :options="getOptions('CARBS-PROTEINS')" :series="getSeries('CARBS-PROTEINS')"></apexchart>
                  </div>
                  <div class="col-md-4">
                    <h5>Στατιστικά τροφίμων</h5>
                    <apexchart height="300px" type="pie" :options="pieOptions" :series="getSeries('FOOD-PIE')"></apexchart>

                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <h5><img src="@assets/images/icons/icon-protein-dark.png" alt="icon-apple" width="45px"/>Πρωτεϊνες</h5>
                    <apexchart height="300px" type="line" :options="getOptions('PROTEINS')" :series="getSeries('PROTEINS')"></apexchart>
                  </div>
                  <div class="col-md-4">
                    <h5><img src="@assets/images/icons/icon-amulouxa-dark.png" alt="icon-apple" width="45px"/> Υδατάνθρακες</h5>
                    <apexchart height="300px" type="line" :options="getOptions('CARBS')" :series="getSeries('CARBS')"></apexchart>
                  </div>
                  <div class="col-md-4">
                    <h5><img src="@assets/images/icons/icon-fats-dark.png" alt="icon-apple" width="45px"/> Λίπη</h5>
                    <apexchart height="300px" type="line" :options="getOptions('FATS')" :series="getSeries('FATS')"></apexchart>
                  </div>
                </div>

              </div>
            </b-tab>
            <b-tab title="Μικροθρεπτικά">
              <div v-if="!loadingDietPlan" class="tabs">
              <download-csv class="btn btn-light mr-2" :data="getCSVData(false)" :name="getFileName()" ><i class="fe-download"/> Αποθήκευση ως CSV (χωρίς DRI)</download-csv>
                <download-csv class="btn btn-outline-primary" :data="getCSVData(true)" :name="getFileName()"><i class="fe-download"/> Αποθήκευση ως CSV (με DRI %)</download-csv>
              <b-tabs justified nav-class="nav-tabs nav-bordered" vertical>

                  <b-tab v-for="(day,index) in dayNames" :key="day" :title="day">

                    <div class="nutrient-wrapper pl-md-5">
                      <table class="micronutrients-table">
                        <tr>
                          <th>Μικροθρεπτικό</th>
                          <th>Ποσότητα</th>
                          <th>DRIs(%)</th>
                          <th>Ανεκτά Ανώτατα Επίπεδα Πρόσληψης (UP)</th>
                        </tr>
                        <tr v-if="days[index].totalStats.microNutrients && days[index].totalStats.microNutrients[nutrient]"
                            v-for="(nutrient,index2) of microNutrientFields" :key="index + '-'+index2" class="nutrient-item">
                            <td>{{microNutrientTranslation[nutrient]}}: </td>
                            <td >{{days[index].totalStats.microNutrients[nutrient].toFixed(2)}}</td>
                            <td > {{calculateNutrientDri(nutrient, days[index].totalStats.microNutrients[nutrient], true)}}</td>
                          <td > {{findNutrientUpperLimit(nutrient)}}</td>


                        </tr>
                      </table>
                    </div>




                  </b-tab>
                </b-tabs>

              </div>

            </b-tab>

          </b-tabs>

        </b-overlay>
    </div>

</template>
'