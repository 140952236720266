<script>
    import dietPlan from './dietPlan'
    import dietPlanFunctions from "./dietPlanFunctions";
    import dietPlanExercises from "@components/exercises";
    import dietPlanMacronutrients from "./dietPlanMacronutrients";
    import dietPlanAnalysis from "./dietPlanAnalysis";
    import DietPlanRecipes from "@views/dietPlan/details/print/DietPlanRecipes";
    import VueTagsInput from "@johmun/vue-tags-input";
    import Vue from "vue"
    import {mapState} from "vuex";
    import DietPlanNotes from "@views/dietPlan/details/dietPlanNotes";
    import CloudModal from "@views/dietPlan/details/CloudModal";
    import PrintDietPlanNew from "@views/dietPlan/details/print/printDietPlanNew";
    import DietPlanSettings from "@views/dietPlan/details/dietPlanSettings";
    import AutoGenerateDietPlan from "@components/modals/AutoGenerateDietPlan";

    export default{
        components: {
          AutoGenerateDietPlan,
          DietPlanSettings,
          PrintDietPlanNew,
          DietPlanNotes,
            dietPlan, dietPlanFunctions,dietPlanExercises, dietPlanMacronutrients, dietPlanAnalysis, VueTagsInput, DietPlanRecipes,
        CloudModal},
        props:{
            edits: {
                default: () => { return {}},
                type: Object
            },
            athleteId:{
                default: null,
                type: String
            },
            athleteView:{
                default: false,
                type: Boolean
            }
        },

        data(){
            return {
                dietPlanId: null,
                dietPlanKey: 0,
                dietPlanTabId: 0,
                autocompleteTags: [],
                dietPlanInfo: {
                  dietPlanFunc: {requiredKcal: 1, caloricNeeds: []},
                  dietPlanMealOptions: {
                    totalMeals: 5,
                    mealsOrder: [0,1,2,3,4,5,6],
                    mealNames: {0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null}
                  }
                },
                isDietPlanLoaded: false,
                isTagsListLoaded: false,
                isSportsListLoaded: false,
                tag: '',
                tags: [],
                validation: [{
                    classes: 'min-length',
                    rule: tag => tag.text.length < 2,
                }],
                tabs: ['#plan', '#functions', '#macronutrients', '#micronutrients', '#exercise', '#settings', '#analysis', '#notes'],
                copyToAthlete: {
                    athletes: [],
                    selectedAthlete: null,
                    replaceAthleteMeasurements: true
                },
                isLoading: false,
                showCarbAlert: false,
                microNutrients: {},
                initialMicroNutrients: null,
                foodToAvoid:{
                  short:null,
                  long: null
                },
              showCloudModal: false
            }
        },
        computed: {
          ...mapState({
            user: state => state.auth.currentUser,
            loadingDone: state => state.pdf.loadingDone,
            showTooltips: state => state.dietPlan.showTooltips,
            findDislikedFoods: state => state.dietPlan.findDislikedFoods
          }),
            filteredItems() {
                return this.autocompleteTags.filter(i => {
                    return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
                });
            },
            microNutrientsChanged(){
                return this.initialMicroNutrients ? this.initialMicroNutrients !== JSON.stringify(this.microNutrients) : false;
            }
        },

        mounted() {
            // Get the previous tab from the url
            this.dietPlanTabId = this.tabs.findIndex(tab => tab === this.$route.hash);
        },

      watch:{
          '$route.params.dietPlanId'(val){
            if(this.$route.query.athleteChanged) return;
            this.dietPlanId = val;
            this.fetchCurrentPlanTags();
            this.fetchDietPlan();
          },
          // changed athleteId so we need to re-fetch things.
          athleteId(){
            this.fetchQuestionnaire();
            this.fetchCurrentPlanTags();
            this.fetchDietPlan();

          }
      },

        async created(){
            this.dietPlanId = this.$route.params.dietPlanId;
            await this.fetchDPTags();
            this.autocompleteTags = this.$store.state.tags._dietPlanTags;
            await this.fetchSports();

            if(!this.foodMeasures){
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των μεζούρων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            }
            if(!this.autocompleteTags){
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των ετικετών απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            }
            if(!this.isSportsListLoaded){
                this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των αθλημάτων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
            }

            if(this.athleteView) this.fetchQuestionnaire();
            this.fetchCurrentPlanTags();
            await this.fetchDietPlan();

        },
        methods:{
            changeTooltipsValue(val){

              this.$store.dispatch('dietPlan/changeShowTooltipValue', val);
            },
            changeDislikedFoodsValue(val){
              this.$store.commit('dietPlan/UPDATE_SHOW_DISLIKED_FOODS', val);
            },
            fetchCurrentPlanTags(){
                this.$axios.get(`/dietPlan/${this.dietPlanId}/tag`).then(result => {
                    this.tags = result.data;
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                })
            },

            fetchQuestionnaire(){
              this.$axios.get(`/athlete/${this.athleteId}/questionnaire`).then(result => {
                this.foodToAvoid.long = result.data.q21_7;
              }).catch();
              this.$axios.get(`/athlete/${this.athleteId}/short-questionnaire`).then(result => {
                this.foodToAvoid.short = result.data.q29;
              }).catch();
            },

            async fetchDPTags(){
                if(!this.$store.state.tags._dietPlanTags) {
                    await this.$store.dispatch('tags/fetchCacheDietPlanTags');
                    this.isTagsListLoaded = true;
                }else{
                    await this.$store.dispatch('tags/refreshDPTagsCache');
                    this.isTagsListLoaded = true;
                }
            },

            async fetchSports(){
                if(!this.$store.state.sports._sports) {
                    await this.$store.dispatch('sports/fetchCacheSports');


                }else{
                    await this.$store.dispatch('sports/refreshCacheSports');
                }

                this.isSportsListLoaded = true;
            },

            async fetchDietPlan(){
                let params = {};
                if(this.athleteView) params.athleteId = this.athleteId;

              this.$axios.get(`/dietPlan/${this.dietPlanId}`, {params: {athleteId: this.athleteId}}).then(result=>{

                    this.dietPlanInfo = result.data.dietPlan;
                    this.dietPlanInfo.dietPlanFunc.caloricNeeds = result.data.exerciseCaloricNeeds;

                    // setup default meal options if the result from the server is null!
                    if(!this.dietPlanInfo.dietPlanMealOptions) {
                      this.dietPlanInfo.dietPlanMealOptions = {
                        totalMeals: 5,
                        mealsOrder: [0,1,2,3,4,5,6],
                        mealNames: {0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null}
                      }
                    }
                    // (take care of this) this.autoGenerationOptions.calories = this.dietPlanInfo.dietPlanFunc.requiredKcal
                    this.title = (result.data.athlete.name ? result.data.athlete.name + ' - ': '') + this.dietPlanInfo.name;


                    if(this.athleteView && result.data.athlete && result.data.athlete.id){
                        this.$store.commit('ui/setActiveItem', {value: result.data.athlete.name, index: 0});
                    }

                    this.$store.commit('ui/setActiveItem', {
                      value: this.dietPlanInfo.name,
                      index: this.athleteView ? 1 : 0
                    });

                    this.isDietPlanLoaded = true;

                    // initialize dietPlanInfo.microNutrients fields, if not initialized.
                    for(let nutrient of this.microNutrientFields){
                        if(!this.dietPlanInfo.microNutrients) this.dietPlanInfo.microNutrients = {};
                        if(!this.dietPlanInfo.microNutrients[nutrient]){
                            Vue.set(this.dietPlanInfo.microNutrients, nutrient, false);
                        }
                    }
                    if(!this.dietPlanInfo.microNutrients['isEnabled'])  Vue.set(this.dietPlanInfo.microNutrients, 'isEnabled', false);
                    Vue.set(this.microNutrients, 'selected', this.dietPlanInfo.microNutrients) ;
                    this.initialMicroNutrients = JSON.stringify(this.microNutrients);

                    this.$store.dispatch('pdf/cacheDietPlanInfo', this.dietPlanInfo);
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });

            },

            deleteDietPlan(){
                let message = {
                    title: 'Θέλετε σίγουρα να διαγράψετε το διατροφικό πλάνο;',
                    body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτό το διατροφικό πλάνο.'
                };
                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Διαγραφή',
                    cancelText: 'Ακύρωση',
                    backdropClose: true
                }).then((dialog) => {
                    this.$axios.delete(`/dietPlan/${this.dietPlanId}`).then(result => {
                        dialog.close();
                        this.$notify({group: 'athlisis-notifications', type: 'success', title: this.$t('success.title'), text: 'Το διατροφικό πλάνο διαγράφηκε επιτυχώς!'});
                        if(this.athleteView) this.$router.push('/athletes/'+this.athleteId +'#dietplans');
                        else this.$router.push({name: 'diet-plans'});

                    }).catch(e => {
                        this.$notify({group: 'athlisis-notifications', type: 'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                    });
                }).catch(()=>{});
            },
            printDietPlanNew(){
              this.$refs['print-diet-plan-new'].show();
            },

            deleteTag(obj){
                this.$axios.delete(`/dietPlan/${this.dietPlanId}/tag/${obj.tag.tagId}`).then(result => {
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα διαγράφηκε επιτυχώς!'});
                    this.tags.splice(obj.index, 1);
                }).catch( e  => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            addTag(obj){
                this.$axios.post(`/dietPlan/${this.dietPlanId}/tag`, {text: obj.tag.text}).then(result => {

                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Η ετικέτα προστέθηκε επιτυχώς!'});
                    result.data.text = obj.tag.text;
                    this.tag='';
                    this.tags.push(result.data);
                    this.$store.dispatch('tags/fetchCacheDietPlanTags'); // re-fetch and store data!

                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η προσθήκη της ετικέτας απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            saveAsGlobalTemplate(){

                this.$axios.put(`/dietPlan/${this.dietPlanId}/saveAsTemplate`).then(result => {
                    this.dietPlanInfo.isSystem = !this.dietPlanInfo.isSystem ;
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },


            openPushToCloudModal(){
              this.showCloudModal = true;
            },


            copyDietPlan(toAthlete, athleteId){
                let message = {
                    title: 'Θέλετε σίγουρα να αντιγράψετε το διατροφικό πλάνο;',
                    body: 'Θα δημιουργηθεί ένα ακριβές αντίγραφο του διατροφικού πλάνου το οποίο μπορείτε να επεξεργαστείτε ξεχωριστά.'
                };
                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Αντιγραφή προτύπου',
                    cancelText: 'Ακύρωση',
                    backdropClose: true
                }).then((dialog) => {

                    this.$axios.post(`/dietPlan/${this.dietPlanId}/copy`, (toAthlete && athleteId )? {toAthlete: true, athleteId: athleteId, replaceFuncs: this.copyToAthlete.replaceAthleteMeasurements} : null).then(result => {
                        dialog.close();
                        if(result.data.hasAthlete){this.$router.push({ name: 'athlete-diet-plan', params: {dietPlanId: result.data.planId, athleteId: result.data.athleteId }, query: {athleteChanged: true}} );}
                        else if(this.athleteView) this.$router.push({ name: 'athlete-diet-plan', params: {dietPlanId: result.data.planId, athleteId: this.athleteId} } );
                        else this.$router.push({name: 'diet-plan', params: {dietPlanId: result.data.planId}});
                        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το διατροφικό πλάνο αντιγράφηκε σε νέο επιτυχώς.'});
                    }).catch(e=>{
                        dialog.close();
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                    })
                }).catch( () =>{});
            },

            copyDietPlanToAthleteModal(){
                if(this.copyToAthlete.athletes.length < 1){
                    this.$axios.get(`/athlete/athlete-names`).then(result => {
                        this.copyToAthlete.athletes = result.data.athletes.rows;
                        this.copyToAthlete.athletes.map(x =>{
                             x.item_data = x.firstName + ' ' + x.lastName + ' | ' + x.mobileNum;
                            return x;
                        });
                    }).catch(e =>{
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η φόρτωση των αθλητών απέτυχε. Δοκιμάστε ξανά!'});
                    });
                }
                this.$bvModal.show('copy-to-athlete');

            },

            copyDietPlanToTemplates(){
                let message = {
                    title: 'Θέλετε σίγουρα να αποθηκεύσετε το πλάνο ως πρότυπο;',
                    body: 'Θα δημιουργηθεί ένα ακριβές αντίγραφο του διατροφικού πλάνου το οποίο μπορείτε να επεξεργαστείτε ξεχωριστά.'
                };
                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Αντιγραφή προτύπου',
                    cancelText: 'Ακύρωση',
                    backdropClose: true
                }).then((dialog) => {

                    this.$axios.post(`/dietPlan/${this.dietPlanId}/template`).then(result => {
                        dialog.close();
                        this.$router.push({name: 'diet-plan', params: {dietPlanId: result.data.planId}});
                        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το διατροφικό πλάνο αποθηκεύτηκε επιτυχώς ως πρότυπο.'});
                    }).catch(e=>{
                        dialog.close();
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                    })
                }).catch( () =>{});
            },

            updateDietPlan(){

                this.$axios.put(`/dietPlan/${this.dietPlanId}`, {notes: this.dietPlanInfo.notes, name: this.dietPlanInfo.name,
                    showOptions: this.dietPlanInfo.showOptions, isKeto: this.dietPlanInfo.isKeto,
                  is_diabetes: this.dietPlanInfo.is_diabetes, insulin_ratio: this.dietPlanInfo.insulin_ratio,
                    isVegan: this.dietPlanInfo.isVegan, isFasting: this.dietPlanInfo.isFasting, isCarbohydration: this.dietPlanInfo.isCarbohydration,
                    carbohydrationDay: this.dietPlanInfo.carbohydrationDay,
                  shareToMobile: this.dietPlanInfo.shareToMobile}).then(result => {

                    this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: 'Το διατροφικό πλάνο ανανεώθηκε επιτυχώς!'});

                    this.$store.commit('ui/setActiveItem', {value: this.dietPlanInfo.name, index: this.athleteView ? 1 : 0});

                    this.edits.isEdited = false;
                    // replace nutrients that are displayed. Diet Plan nutrients are changed server-side
                    if(this.dietPlanInfo.isKeto) {
                        this.dietPlanInfo.dietPlanFunc.nutrients.carbs.total = 10;
                        this.dietPlanInfo.dietPlanFunc.nutrients.proteins.total = 20;
                        this.dietPlanInfo.dietPlanFunc.nutrients.fats.total = 70;
                    }
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });

            },

            openDietPlanGenerationModal(){
              this.$refs['generate-diet-plan'].show();
            },

            dietPlanGenerated(){
              this.dietPlanKey++;
              this.$refs['generate-diet-plan'].hide();
            },

            autoUpdateDietPlan() {
                this.isLoading = true;
                this.edits.isEdited = true;
                setTimeout(() => {
                    this.updateDietPlan();
                    this.isLoading = false
                }, 500)
            },

            autoUpdateDietPlanNotes(notes){
              this.dietPlanInfo.notes = notes;
              this.autoUpdateDietPlan();
            },

            addHashToLocation() {
                let tabHash = this.tabs[this.dietPlanTabId];
                history.pushState(
                    {},
                    null,
                    this.$route.path + tabHash
                )
            },

            saveMicroNutrientSettings(){
                this.$axios.put(`/dietPlan/${this.dietPlanId}/functions/micronutrients`, {microNutrients: this.microNutrients.selected})
                    .then(res => {
                        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Οι ρυθμίσεις μικροθρεπτικών αποθηκεύτηκαν επιτυχώς!'});
                        this.initialMicroNutrients = JSON.stringify(this.microNutrients);
                    }).catch(() => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                })
            }

        },
    }
</script>
<style lang="scss">
    @import '@src/design/custom/pages/_dietPlanList.scss';
    label{
        padding-right:10px;
    }
    .meal-percentages input{
      text-align: center;
      padding:0 5px;
    }
</style>
<template>
    <div class="row dietPlanInfo" :key="dietPlanId">
        <div class="col-md-12">
            <div class="card">
                <b-tabs v-model="dietPlanTabId" content-class="mt-1" nav-class="nav-tabs nav-bordered" @input="addHashToLocation" >
                    <b-alert :show="dietPlanInfo.isSystem" variant="warning" class="ml-3 mr-3">
                        Πρότυπο διατροφικό πλάνο του συστήματος, δεν μπορείτε να το επεξεργαστείτε. Αντιγράψτε το σε πελάτη ή σε νέο.
                    </b-alert>
                    <b-tab href="#dietplan">
                        <template v-slot:title>Διατροφικό πλάνο</template>
                        <div v-if="isDietPlanLoaded" id="weekly-program" class="card-body">
                            <div class="row">
                                <div class="col-md-5 plan-info">
                                    <h3><span v-if="dietPlanInfo.isSystem" class="text-muted">Πρότυπο συστήματος</span> {{dietPlanInfo.name}}
                                        <b-button v-show="!dietPlanInfo.isSystem" v-b-tooltip.hover variant="outline-warning" size="sm"  title="Επεξεργασία" @click="dietPlanTabId = 5">
                                        <i class="icon-settings"></i></b-button></h3>
<!--                                    <b-button v-if="user.isAdmin" v-b-tooltip.hover variant="primary" class="btn mt-2 mb-4" title="πρότυπο" @click="saveAsGlobalTemplate">-->
<!--                                        <span v-if="dietPlanInfo.isSystem"><i class="fa fa-trash-alt"></i> Αφαίρεση από πρότυπο</span>-->
<!--                                        <span v-if="!dietPlanInfo.isSystem"><i class="fa fa-check-circle"></i> Ορισμός ως πρότυπο συστήματος</span>-->
<!--                                    </b-button>-->

                                  <b-button v-b-tooltip.hover variant="primary" class="btn mt-2 mr-2 mb-4" title="Κοινοποίηση στο aCloud" @click="openPushToCloudModal">
                                    <span><i class="fa fa-check-circle"></i> Κοινοποίηση στο aCloud</span>
                                  </b-button>

                                  <b-button v-if="!dietPlanInfo.isSystem && !dietPlanInfo.isTextOnly" v-b-tooltip.hover variant="outline-primary" class="btn mt-2 mr-2 mb-4" title="Αυτόματη δημιουργία διατροφικού πλάνου" @click="openDietPlanGenerationModal">
                                    <span><i class="fa fa-brain"></i> Αυτόματη δημιουργία</span>
                                  </b-button>


                                    <p v-if="!dietPlanInfo.isSystem">Ετικέτες:
                                        <vue-tags-input v-model="tag" :tags="tags" :delete-on-backspace="false" :autocomplete-items="filteredItems"
                                                        placeholder="Προσθήκη..." :validation="validation" @before-deleting-tag="deleteTag" @before-adding-tag="addTag">
                                            <template slot="autocomplete-header"><strong>Επιλέξτε μια υπάρχουσα ετικέτα ↓</strong></template>
                                        </vue-tags-input>
                                    </p>

                                  <div class="d-flex align-items-center pb-3">
                                    <b-form-checkbox v-if="!dietPlanInfo.isTextOnly"  id="tooltips" :checked="showTooltips" name="tooltips" class="flex-shrink-0"
                                                     switch size="md" inline @change="changeTooltipsValue">Προεπισκόπηση μακροθρεπτικών γευμάτων
                                    </b-form-checkbox>
                                    <b-form-checkbox v-if="athleteView" id="find-food-matches" :checked="findDislikedFoods" name="find-food-matches" class="flex-shrink-0"
                                                     switch size="md" inline @change="changeDislikedFoodsValue">Εύρεση τροφίμων που δεν αρέσουν στον πελάτη
                                    </b-form-checkbox>
                                  </div>

                                  <b-alert variant="warning" v-if="athleteView && findDislikedFoods" show>
                                    <span class="text-xs">Η εύρεση δεν λειτουργεί με ακρίβεια και ενδέχεται να έχει εσφαλμένες ενδείξεις ή ελλείψεις.</span>
                                    <p class="mb-0">Αναζήτηση για: <strong>{{foodToAvoid.short}} {{foodToAvoid.long}}</strong></p>
                                  </b-alert>

                                </div>
                                <div class="col-md-7">
                                    <div class="text-lg-right mt-2 mt-lg-0 action-btn">
                                      <b-button v-b-tooltip.hover variant="outline-dark" title="Συνταγές διατροφικού πλάνου" class="btn mr-1"
                                                @click="$refs['dp-recipes'].openPDFModal()"><i class="fas fa-utensils"></i></b-button>
                                        <b-button v-b-tooltip.hover variant="outline-dark" title="Αποστολή με e-mail / Αποθήκευση ως PDF" class="btn mr-1"
                                                  @click="printDietPlanNew"><i class="fe-mail"></i>
                                        <b-badge variant="primary" size="xs" class="ml-1">Νέα έκδοση (v3)</b-badge></b-button>
                                        <b-button v-if="athleteId" v-b-tooltip.hover variant="outline-dark" class="btn mr-1" title="Αποθήκευση ως πρότυπο" @click="copyDietPlanToTemplates"><i class="fe-save"></i></b-button>
                                        <b-dropdown id="copy-dropdown" variant="outline-dark" class="mr-1" v-b-tooltip.hover title="Αντιγραφή" right>
                                          <template v-slot:button-content><i class='fe-copy'></i></template>
                                          <b-dropdown-item  @click="copyDietPlan">Αντιγραφή σε νέο πρότυπο</b-dropdown-item>
                                          <b-dropdown-item @click="copyDietPlanToAthleteModal">Αντιγραφή σε πελάτη</b-dropdown-item>
                                        </b-dropdown>
                                        <b-button v-if="!dietPlanInfo.isSystem" v-b-tooltip.hover variant="danger" class="btn mr-1" title="Διαγραφή" @click="deleteDietPlan"><i class="fe-trash"></i></b-button>
                                    </div>

                                </div>
                            </div>

                            <b-alert :show="!dietPlanInfo.isTextOnly && !dietPlanInfo.dietPlanFunc.requiredKcal && !dietPlanInfo.isSystem" variant="warning">Δεν έχετε εισάγει τις θερμίδες και τα μακροθρεπτικά στοιχεία του πλάνου.
                                <span class="link" @click="dietPlanTabId = 1"><strong>Συμπληρώστε τις εξισώσεις ή εισάγετε χειροκίνητα!</strong></span>
                            </b-alert>

                            <b-alert :show="dietPlanInfo.isCarbohydration" variant="primary" style="padding:5px;">
                                <img src="@assets/images/icons/icon-carbohydration.png" width="35px" alt="icon-carbohydration"/>
                                Έχετε ενεργοποιημένη την υδατανθράκωση!
                            </b-alert>

                            <b-alert :show="dietPlanInfo.isKeto" variant="primary" style="padding:5px;">
                                <img src="@assets/images/icons/keto-icon.png" width="35px" alt="icon-keto"/>
                                Έχετε ενεργοποιημένη την κέτωση!
                            </b-alert>

                            <diet-plan :key="dietPlanKey" :diet-plan-info="dietPlanInfo"
                                       :food-to-avoid="foodToAvoid"
                                        ></diet-plan>
                        </div>

                    </b-tab>

                    <b-tab v-if="!dietPlanInfo.isTextOnly" href="#functions">
                        <template v-slot:title><i class="icon-equalizer"></i> Εξισώσεις</template>
                        <diet-plan-functions v-if="dietPlanTabId === 1 && isDietPlanLoaded" :diet-plan-info="dietPlanInfo" :edits="edits"></diet-plan-functions>
                    </b-tab>

                    <b-tab  v-if="!dietPlanInfo.isTextOnly">
                        <template v-slot:title><i class="fas fa-chart-pie"></i> Κατανομή μακροθρεπτικών</template>
                        <diet-plan-macronutrients v-if="dietPlanTabId === 2 && isDietPlanLoaded" :diet-plan-info="dietPlanInfo" :edits="edits"></diet-plan-macronutrients>
                    </b-tab>

                    <b-tab  v-if="!dietPlanInfo.isTextOnly">
                        <template v-slot:title><i class="fas fa-chart-pie"></i> Μικροθρεπτικά</template>
                        <div v-if="microNutrients.selected && Object.keys(microNutrients.selected).length > 0 && dietPlanTabId === 3" class="card-body">

                            <div class="row">
                                <b-form-checkbox v-model="microNutrients.selected['isEnabled']" >Ενεργοποίηση παρακολούθησης μικροθρεπτικών;</b-form-checkbox>

                                <div v-if="microNutrients.selected.isEnabled" class="col-md-12">
                                    Παρακαλώ επιλέξτε ποια μικροθρεπτικά επιθυμείτε να παρακολουθήσετε στο παράθυρο γεύματος
                                </div>

                                <div v-if="microNutrients.selected.isEnabled" class="col-md-7 bg-light p-3" style="border-radius:12px">
                                  <div class="col-md-12 py-2">
                                    <p><strong>Γενικά μικροθρεπτικά</strong></p>
                                  </div>
                                  <div class="row">
                                    <div v-for="nutrient in bothCategoriesMicronutrients" :key="nutrient" class="col-md-4">
                                      <b-form-checkbox v-model="microNutrients.selected[nutrient]">{{microNutrientTranslation[nutrient]}}</b-form-checkbox>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 py-2">
                                      <p><strong>Μικροθρεπτικά μόνο στη λίστα Τριχοπούλου</strong></p>
                                    </div>
                                    <div v-for="nutrient in trixopoulouMicronutrients" :key="nutrient" class="col-md-4">
                                      <b-form-checkbox v-model="microNutrients.selected[nutrient]">{{microNutrientTranslation[nutrient]}}</b-form-checkbox>
                                    </div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-md-12 py-2">
                                      <p><strong>Μικροθρεπτικά μόνο στη λίστα USDA</strong></p>
                                    </div>
                                    <div v-for="nutrient in usdaMicronutrients" :key="nutrient" class="col-md-4">
                                      <b-form-checkbox v-model="microNutrients.selected[nutrient]">{{microNutrientTranslation[nutrient]}}</b-form-checkbox>
                                    </div>
                                  </div>



                                </div>
                                <div class="col-md-12 mt-3" >

                                  <save-button @click="saveMicroNutrientSettings" :disabled="!microNutrientsChanged"></save-button>
                                </div>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab v-if="!dietPlanInfo.isTextOnly">
                        <template v-slot:title><i class="icon-energy"></i> Άσκηση</template>
                        <div v-if="!dietPlanInfo.dietPlanFunc.useExercise" class="card-body">
                            <b-alert :show="!dietPlanInfo.dietPlanFunc.useExercise" variant="warning">Για να επιλέξετε αθλήματα, πρέπει να έχετε ενεργοποιημένη την προχωρημένη εξίσωση.
                                <span class="link" @click="dietPlanTabId = 1"><strong>Αλλαγή ρυθμίσεων</strong></span>
                            </b-alert>
                        </div>

                        <diet-plan-exercises v-if="dietPlanTabId === 4 && isDietPlanLoaded && isSportsListLoaded && dietPlanInfo.dietPlanFunc.useExercise" :diet-plan-info="dietPlanInfo"/>
                    </b-tab>


                    <b-tab>
                        <template v-slot:title><span class="icon-settings"></span> Ρυθμίσεις</template>
                        <div v-if="((dietPlanInfo.isTextOnly && dietPlanTabId === 1) || dietPlanTabId === 5) && isDietPlanLoaded" class="card-body">
                          <diet-plan-settings :diet-plan-info="dietPlanInfo"
                                              :is-loading="isLoading"
                                              @autoUpdateDietPlan="autoUpdateDietPlan"
                                              @updateDietPlan="updateDietPlan"
                                              @updateTab="val => this.dietPlanTabId = val"
                          @mealsCountUpdated="dietPlanKey++"></diet-plan-settings>
                        </div>

                    </b-tab>

                    <b-tab  v-if="!dietPlanInfo.isTextOnly">
                        <template v-slot:title><i class="fe-bar-chart-2"></i> Ανάλυση</template>
                        <div v-if="dietPlanTabId === 6 && isDietPlanLoaded">
                            <diet-plan-analysis :show-names="dietPlanInfo.showOptions"></diet-plan-analysis>
                        </div>

                    </b-tab>

                    <b-tab>
                      <template v-slot:title><span class="icon-doc"></span> Σημειώσεις</template>
                      <div v-if="((dietPlanInfo.isTextOnly && dietPlanTabId === 2) || dietPlanTabId === 7) && isDietPlanLoaded">
                        <b-overlay :show="isLoading" rounded="sm">
                         <diet-plan-notes :content="dietPlanInfo.notes" :edits="edits" @contentChanged="autoUpdateDietPlanNotes"/>
                        </b-overlay>
                      </div>

                    </b-tab>




                </b-tabs>
            </div>
        </div>
       <diet-plan-recipes ref="dp-recipes" v-if="loadingDone" :diet-plan="dietPlanInfo" style="display:inline-block;"></diet-plan-recipes>


<!--       <diet-plan-to-pdf ref="dp-to-pdf" v-if="loadingDone" :athlete-id="athleteId" class="mr-1" style="display:inline-block;"></diet-plan-to-pdf>-->
        <b-modal id="copy-to-athlete" ref="copy-to-athlete" size="medium" title="Αντιγραφή πλάνου σε πελάτη" hide-footer body-class="p-3">
            <div class="row">
                <div class="col-md-12">
                    <v-select
                        v-model="copyToAthlete.selectedAthlete"
                        placeholder="Επιλογή πελάτη..."
                        :options = "copyToAthlete.athletes"
                        label="item_data"
                        :reduce="athlete => athlete.id">
                    </v-select>
                </div>
                <div class="col-md-12 pt-3">
                    <b-form-checkbox id="replaceFunctions" v-model="copyToAthlete.replaceAthleteMeasurements" name="replaceFunctions">Αντικατάσταση ύψους/βάρους του πλάνου με του πελάτη;</b-form-checkbox>
                </div>

            </div>
            <b-form-group id="button-group" class="mt-2 text-right">
                <b-button type="submit" variant="primary" class="btn mt-2" :disabled="!copyToAthlete.selectedAthlete" @click="copyDietPlan(true, copyToAthlete.selectedAthlete)"><i class="icon-plus"></i> Προσθήκη σε πελάτη</b-button>
            </b-form-group>
        </b-modal>


      <b-modal id="generate-diet-plan" ref="generate-diet-plan" size="lg" title="Αυτόματη δημιουργία διατροφικού πλάνου" hide-footer body-class="p-3">

        <auto-generate-diet-plan :diet-plan-info="dietPlanInfo" @dietPlanGenerated="dietPlanGenerated"></auto-generate-diet-plan>

      </b-modal>

        <b-modal id="print-diet-plan-new" ref="print-diet-plan-new" size="md" title="Εκτύπωση διατροφικού πλάνου" hide-footer body-class="p-3">
          <print-diet-plan-new :diet-plan="dietPlanInfo" :athlete-id="athleteId"></print-diet-plan-new>
        </b-modal>

      <b-modal v-model="showCloudModal" id="cloud-modal" ref="cloud-modal" size="md" title="Κοινοποίηση στο athlisis cloud" hide-footer>


        <cloud-modal v-if="!isEducational && showCloudModal" :diet-plan-id="dietPlanId" />
        <educational-unavailable v-else/>
      </b-modal>
    </div>
</template>
