<script>
    import addFoodModal from './addFoodModal/addFoodModal'
    import Vue from 'vue'
    import VueContext from 'vue-context';
    import mealTemplateList from "../list/mealTemplateList";
    import {mapState} from "vuex";
    import localforage from "localforage";
    import VueTagsInput from "@johmun/vue-tags-input";


    import DietPlanMixin from "@src/mixins/DietPlanMixin";



    const clearText = text => {
      return text.trim()
          .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
    }


    const pluralMatches = {
      'α': 'ες',
      'ας': 'ες',
      'η': 'εις',
      'ου': 'ουδες',
      'ος': 'οι',
      'ο': 'α',
      'ι': 'ια'
    }
    const checkIfIsMatchingByPluralAndStartingPhrase = (initial, other) => {
      try{
        for(const [key, value] of Object.entries(pluralMatches)){

          if(initial.endsWith(key) && other.endsWith(value)){

            const hasSameStart = initial.substring(0, initial.length - key.length) === other.substring(0, other.length - value.length);
            if(hasSameStart) return true;
          }
          if(other.endsWith(key) && initial.endsWith(value)){
            const hasSameStart = initial.substring(0, initial.length - value.length) === other.substring(0, other.length - key.length);
            if(hasSameStart) return true;
          }
        }
      }catch(e){
        return false;
      }

    }

    export default {
        components: {addFoodModal, VueContext, mealTemplateList, VueTagsInput},

      mixins: [DietPlanMixin],
        props: {
            dietPlanInfo:{
                default: () => {return {}},
                type: Object
            },
          // eslint-disable-next-line vue/require-default-prop
            foodToAvoid:{
              defaultValue: null,
              type: Object
            }
        },

        data() {
            return {
                dietPlanId: null,
                days: [],
                dietMeals: [],


                mealFoods: [],
                addFoodModel: {
                    id: null,
                    error: null
                },
                dayStats: [{stats:{}}, {stats:{}}, {stats:{}}, {stats:{}}, {stats:{}}, {stats:{}}, {stats:{}}],
                // foods: {},
                copiedMealId: null,
                copiedDay: null,
                loadingDietPlan: true,
                saveTemplate:{
                    id: null,
                    name: null,
                    savingTemplate: false,
                    type: null,
                    tags: [],
                    tag: ''
                },
                insertTemplate:{
                    toId: null,
                    category: null
                }
            }
        },
        computed:{
          ...mapState({
            foods: state => state.food._foods,
            showTooltips: state => state.dietPlan.showTooltips,
            mealTemplateTags: state => state.tags._mealTemplateTags,
            findDislikedFoods: state => state.dietPlan.findDislikedFoods
          }),
          filteredTags() {
            if(!this.mealTemplateTags) return [];
            return this.mealTemplateTags.filter(i => {
              return i.text.toLowerCase().indexOf(this.saveTemplate.tag.toLowerCase()) !== -1;
            });
          },

          tooltipIds(){
            let array = [];

            for(let i =0; i< 7; i++){
              for(let j=0;j<this.dietPlanInfo.dietPlanMealOptions.totalMeals;j++){
                array.push({
                  idx1: i,
                  idx2: j
                })
              }
            }
            return array;
          }
        },

      beforeMount() {
        this.$store.dispatch('pdf/resetLoadingState', false);
      },

      async created(){
            this.dietPlanId = this.$route.params.dietPlanId;
            this.$store.dispatch('dietPlan/clearShoppingLists');
            this.$store.dispatch('pdf/cacheDietPlanId', this.dietPlanId);
            await this.fetchFoods(); // load foods from vuex store or await for api call!
            this.fetchMealAndFoods();
            this.initializeLocalForageItems();
        },


        methods:{

            matchesDislikedFoods(food){

              let foodsToAvoid = [];
              let foodName = clearText(food.food.name);
              if(this.foodToAvoid && this.foodToAvoid.short){
                foodsToAvoid.push(...this.foodToAvoid.short.split(",").map(x => clearText(x)));
              }
              if(this.foodToAvoid && this.foodToAvoid.long){
                foodsToAvoid.push(...this.foodToAvoid.long.split(",").map(x => clearText(x)))
              }

              foodName = foodName.split(" "); // split text by mulitple words in it.
              for(let foodAvoid of foodsToAvoid){
                for(let food of foodName){
                  if(food === foodAvoid) return true; // full match
                  if(checkIfIsMatchingByPluralAndStartingPhrase(foodAvoid, food)) return true;
                }
              }
              return false;
            },
            initializeLocalForageItems(){
              localforage.getItem('food.copiedMealId').then(res=>{
                if(res) this.copiedMealId = res;
              });
              localforage.getItem('food.copiedDay').then(res => {
                if(res) this.copiedDay = res;
              })
            },

            fetchMealAndFoods(){
                this.$axios.get(`/dietPlan/${this.dietPlanId}/meals`).then(result=>{
                    this.categorizeMeals(result.data.dietPlanMeals);
                });
            },

            categorizeMeals(meals){
                for(let i=0;i<7;i++){
                    this.days[i] = meals.filter(x => { return x.day===i});
                    this.dietMeals.push(this.days[i]);
                }
                this.displayAndCalculateFoods();
            },
            //
            displayAndCalculateFoods(){
                for(let i=0; i<7;i++){
                    for(let j=0;j<this.dietPlanInfo.dietPlanMealOptions.totalMeals;j++){
                        let totalStats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};
                        // sort food list by ORDER first, ID second.

                        if(this.days[i][j].foods) this.days[i][j].foods.sort( (a,b) => {
                          const aId = a.id;
                          const bId = b.id;
                          const aOrder = a.order === null ? 999 : a.order;
                          const bOrder = b.order === null ? 999 : b.order;
                          if(aOrder === 999 && bOrder === 999) return aId - bId;
                          return aOrder - bOrder;
                        });

                        for(let fd of this.days[i][j].foods){
                            let quantity = fd.quantity;
                            fd.food = JSON.parse(JSON.stringify(this.foods.find(food => food.id === fd.foodId )));
                            fd.food.kcal *= quantity;
                            fd.food.carbohydrates *= quantity;
                            fd.food.proteins *=quantity;
                            fd.food.fats *= quantity;
                            fd.food.quantity *= quantity;
                            fd.category = fd.food.category;

                            totalStats.calories += fd.food.kcal;
                            totalStats.carbs += fd.food.carbohydrates;
                            totalStats.proteins += fd.food.proteins;
                            totalStats.fats += fd.food.fats;
                            // calculate micro-nutrients
                            if(fd.food.list !== 0){
                                for(let nutrient of this.microNutrientFields){
                                    if(!fd.food.microNutrients[nutrient]) continue;
                                    if(!totalStats.microNutrients[nutrient] ) totalStats.microNutrients[nutrient] = 0;
                                    totalStats.microNutrients[nutrient] += quantity * parseFloat(fd.food.microNutrients[nutrient]);
                                }
                            }
                        }
                        Vue.set(this.days[i][j], 'statistics', totalStats);
                    }
                }
                // this.$emit('calculatedStatistics', )
                this.loadingDietPlan=false;
                this.$store.dispatch('pdf/cacheDietPlanForPdf', this.dietMeals);
                this.$store.dispatch('pdf/resetLoadingState', true); // mark lists as loaded
                this.$store.dispatch('dietPlan/updateShoppingList', {dietPlanMeals: this.days});

            },

            fetchMealFoods(mealId, i, j){

                this.$axios.get(`/dietPlan/${this.dietPlanId}/meals/${mealId}/foods`).then(result => {
                    // update meal food stats!
                    let mealFoods = result.data.mealFoods;
                    let totalStats = result.data.stats;

                    for(const meal of mealFoods){
                        meal.food = JSON.parse(JSON.stringify(this.foods.find(food => food.id === meal.foodId )));
                        meal.food.kcal *= meal.quantity;
                        meal.food.carbohydrates *= meal.quantity;
                        meal.food.proteins *=meal.quantity;
                        meal.food.fats *= meal.quantity;
                        meal.food.quantity *= meal.quantity;
                        meal.category = meal.food.category;

                        totalStats.calories += meal.food.kcal;
                        totalStats.carbs += meal.food.carbohydrates;
                        totalStats.proteins += meal.food.proteins;
                        totalStats.fats += meal.food.fats;

                        if(parseInt(meal.food.list) !== 0){
                            for(let nutrient of this.microNutrientFields){
                                if(!meal.food.microNutrients[nutrient]) continue;
                                if(!totalStats.microNutrients[nutrient]) totalStats.microNutrients[nutrient] = 0;
                                totalStats.microNutrients[nutrient] += meal.quantity * parseFloat(meal.food.microNutrients[nutrient]);
                            }
                        }
                    }

                    Vue.set(this.days[i][j], 'foods', mealFoods);
                    Vue.set(this.days[i][j], 'statistics', totalStats);
                    this.loadingDietPlan = false;
                    this.$store.dispatch('pdf/cacheDietPlanId', this.dietPlanId);
                    this.$store.dispatch('dietPlan/updateShoppingList', {dietPlanMeals: this.days});
                }).catch(e => {
                  console.error(e);
                  this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση του πλάνου απέτυχε. Δοκιμάστε ξανά!'});
                });



            },

            fetchDietPlanMealsById(id, i, j, notes){
                this.loadingDietPlan=true;
                if(notes) this.days[i][j].notes = notes;
                else this.days[i][j].notes = null;
                this.fetchMealFoods(id, i ,j);
            },

            calculateDailyStatistics(day){
                this.dayStats[day].stats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};
                for (let j = 0; j < this.dietPlanInfo.dietPlanMealOptions.totalMeals; j++) {
                    this.dayStats[day].stats.calories += this.days[day][j].statistics.calories;
                    this.dayStats[day].stats.carbs += this.days[day][j].statistics.carbs;
                    this.dayStats[day].stats.proteins += this.days[day][j].statistics.proteins;
                    this.dayStats[day].stats.fats += this.days[day][j].statistics.fats;

                    // calculate nutrients
                    for(let nutrient of this.microNutrientFields){
                        if(!this.days[day][j].statistics.microNutrients) this.days[day][j].statistics.microNutrients = {};

                        if(this.days[day][j].statistics.microNutrients[nutrient]){
                            if(!this.dayStats[day].stats.microNutrients[nutrient]) this.dayStats[day].stats.microNutrients[nutrient] = 0;
                            this.dayStats[day].stats.microNutrients[nutrient] += this.days[day][j].statistics.microNutrients[nutrient];
                        }
                    }
                }
            },

            addFoodModal(meal){
                // @Todo: fix it
                this.calculateDailyStatistics(meal.day);
                this.$refs['add-food-meal-modal'].show();
                this.addFoodModel.id = meal.id;
            },


            async foodMealModalClosed(id){
                // get meal Day & Type, then refetch it so it updates on global view!
                this.fetchMeal(id).then(result =>{
                    this.fetchDietPlanMealsById(id, result.data.day, result.data.type,result.data.notes);
                });
            },

            async fetchMeal(id){
                return this.$axios.get(`/dietPlan/${this.dietPlanId}/meals/${id}`);
            },

            async clickedMenu (text, item) {

                if(text === 'Αντιγραφή'){
                    this.copiedMealId = item.mealId;
                    return localforage.setItem('food.copiedMealId', item.mealId );
                }

                if(text === 'Επικόλληση'){
                    localforage.getItem('food.copiedMealId').then(res =>{
                      if(!res) return;
                      return this.pasteMeal(item.mealId, item.day, item.type, res, false);
                    })
                }

                if(text === 'Επικόλληση χωρίς εκκαθάριση'){
                  localforage.getItem('food.copiedMealId').then(res =>{
                    if(!res) return;
                    return this.pasteMeal(item.mealId, item.day, item.type, res, true);
                  })
                }

                if(text === 'Εκκαθάριση'){
                    this.clearMeal(item.mealId, item.day, item.type);
                }

                if(text === 'Αποθήκευση ως πρότυπο'){
                    this.saveTemplate.id = item.mealId;
                    this.saveTemplate.type = item.type;
                    this.$refs['save-as-meal-template'].show();
                }

                if(text === 'Εισαγωγή από πρότυπο'){
                    this.insertTemplate.toId = item.mealId;
                    this.insertTemplate.category = item.type;
                    this.$refs['insert-from-template'].show();
                }
            },

            async clickedDayMenu(text, item){
                if(text === 'Αντιγραφή ημέρας'){
                    const toSave = {
                      dietPlanId: this.dietPlanId,
                      day: item.day
                    }
                    this.copiedDay = {...toSave};
                    return localforage.setItem('food.copiedDay', toSave);
                }

                if(text === 'Επικόλληση ημέρας'){
                  localforage.getItem('food.copiedDay').then(res => {
                    if(res) this.pasteDay(item.day, res);
                  });
                }

                if(text === 'Εκκαθάριση ημέρας') this.clearDay(item.day);

            },

            pasteMeal(mealToId, day, type, copiedMeal, keep){
                this.$axios.post(`/dietPlan/${this.dietPlanId}/copyPasteMeal`, {mealFromId: copiedMeal, mealToId:mealToId, keep: keep}).then(result => {
                    this.fetchDietPlanMealsById(mealToId, day, type, result.data.notes);
                }).catch(e=>{
                  if(e.response && e.response.data && e.response.data.message){
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                      text: e.response.data.message});
                  }else{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                      text: 'Η επικόλληση απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
                  }
                });
            },

            pasteDay(toDay, copiedDay){
                this.loadingDietPlan= true;
                let data = {
                    fromDietPlanId: copiedDay.dietPlanId,
                    fromDay: copiedDay.day,
                    toDietPlanId: this.dietPlanInfo.id,
                    toDay: toDay
                };

                this.$axios.post(`/dietPlan/${this.dietPlanId}/pasteDay`, data).then(result => {
                    for(let m of result.data.mealIds){
                        this.fetchDietPlanMealsById(m.id, m.day, m.type, m.notes);
                    }

                }).catch(e=>{
                  if(e.response && e.response.data && e.response.data.message){
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                      text: e.response.data.message});
                  }else{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'),
                      text: 'Η επικόλληση απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
                  }
                }).finally(()=>{

                  this.loadingDietPlan= false;
                });
            },

            clearDay(day){
                this.$axios.delete(`/dietPlan/${this.dietPlanId}/day/${day}`).then(result => {

                    for(let m of result.data.meals){
                        this.fetchDietPlanMealsById(m.id, m.day, m.type, null);
                    }
                });
            },

            clearMeal(mealId, day, type){

                this.$axios.delete(`/dietPlan/${this.dietPlanId}/meals/${mealId}/clear`).then(result => {
                    this.fetchDietPlanMealsById(mealId, day, type, null);
                });

            },

            saveMealAsTemplate(){
                this.saveTemplate.savingTemplate = true;
                this.$axios.post(`/meals/save-template`, this.saveTemplate).then(result => {
                    this.$notify({group: 'athlisis-notifications', duration: 5000, type:'success', title: this.$t('success.title'), text: 'Το πρότυπο αποθηκεύτηκε επιτυχώς!!'});
                    this.saveTemplate.savingTemplate= false;
                    this.saveTemplate.name = '';
                    this.$store.dispatch('tags/fetchCacheMealTemplateTags'); // re-fetch and store data!
                    this.$refs['save-as-meal-template'].hide();

                }).catch(e => {
                    this.saveTemplate.savingTemplate= false;
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η αποθήκευση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            getInsulinRatio(idx1, idx2){

              let statistics = this.days[idx1][idx2].statistics;

              let amount = statistics['carbs'] / this.dietPlanInfo.insulin_ratio;
              return (Math.round(amount * 10) / 10).toString().replace('.', ',');

            },
            getTooltipDayValue(idx1, idx2,value){
              let statistics = this.days[idx1][idx2].statistics;
              let val = statistics[value]? statistics[value].toFixed(2) : '-';
              let postfix = value === 'calories'? 'kcal' : 'g'
              return val + ' ' + postfix ;

            },
          closeAddFoodModalDialog(){
              this.$refs['add-food-meal-modal'].hide();
          }
        },
    }
</script>
<template>
    <div class="diet-plan-table-wrapper">
        <b-overlay :show="loadingDietPlan" rounded="sm">
        <table v-if="foods" id="dietPlan-table" class="table table-responsive table-borderless" style="table-layout: fixed; ">
            <tbody>
            <tr v-for="(row, idx1) in dietMeals" :key="idx1">

                <th class="table-meal-day-name" @contextmenu.prevent="$refs.dayMenu.open($event, { mealId: dietMeals[idx1].id, day: idx1 })">
                    <div v-if="dietPlanInfo.isCarbohydration && idx1 === parseInt(dietPlanInfo.carbohydrationDay)">
                        <img src="@assets/images/icons/icon-carbohydration.png" width="35px" alt="icon-carbohydration"/>
                        <br>
                    </div>

                    {{dietPlanInfo.showOptions ? dayNamesAlt[idx1] : dayNames[idx1]}}</th>

                <td v-for="idx2 in getValidMealsOrder(dietPlanInfo)" :key="idx2" :id="'meal-'+idx1 +''+ idx2" class="table-meal" @click="addFoodModal(dietMeals[idx1][idx2])" @contextmenu.prevent="$refs.menu.open($event, { mealId: dietMeals[idx1][idx2].id, day: idx1, type: idx2 })">
                    <div class="row-header day">
                      {{
                        getMealName(dietPlanInfo, dietMeals[idx1][idx2].type)
                      }}
<!--                        {{ dayMeals[dietMeals[idx1][idx2].type] }}-->
                    </div>
                    <div class="row-content">
                        <div v-for="mealfood in dietMeals[idx1][idx2].foods" :key="mealfood.id">
                            <div v-if="(dietPlanInfo.isVegan && !mealfood.food.isVegan) ||
                             (dietPlanInfo.isFasting && !mealfood.food.isFasting)
                                      || (findDislikedFoods && matchesDislikedFoods(mealfood))" :id="'mealfood-' + mealfood.id"
                                 class="table-meal-food bg-danger text-white invalid-food">

                                <i class="icon-trash"></i><del> {{mealfood.food.name}}:  {{ formatFoodUnits(mealfood.food)}}</del>

                            </div>
                            <div v-else class="table-meal-food" :class="'meal-category-' + mealfood.category">
                                {{formatName(mealfood)}}:  {{ formatFoodUnits(mealfood.food)}}
                            </div>
                        </div>

                        <div v-if="dietMeals[idx1][idx2].notes && !dietPlanInfo.isTextOnly" class="meal-category-notes">

                            <strong>Σημειώσεις:</strong> {{dietMeals[idx1][idx2].notes}}
                        </div>
                        <div v-if="dietMeals[idx1][idx2].notes && dietPlanInfo.isTextOnly" class="meal-category-text-only-notes" v-html="dietMeals[idx1][idx2].notes"/>

                      <div v-if="dietPlanInfo.is_diabetes" class="border-top mt-2 py-1">
                        <div class="pl-1">Μονάδες ινσουλίνης: {{getInsulinRatio(idx1, idx2)}}</div>
                      </div>

                    </div>

                </td>
            </tr>
            </tbody>
        </table>

          <b-tooltip variant="primary" v-if="foods && !loadingDietPlan && showTooltips" v-for="tooltip in tooltipIds"
                     :target="'meal-'+ tooltip.idx1 + '' + tooltip.idx2"  :key="'tooltip-meal-'+ tooltip.idx1 + '' + tooltip.idx2">


            Ενέργεια: <strong>{{getTooltipDayValue(tooltip.idx1, tooltip.idx2, 'calories')}}</strong><br>
            Υδατάνθρακες: <strong>{{getTooltipDayValue(tooltip.idx1, tooltip.idx2, 'carbs')}}</strong><br>
            Πρωτεϊνες:<strong> {{getTooltipDayValue(tooltip.idx1, tooltip.idx2, 'proteins')}}</strong><br>
            Λίπη : <strong>{{getTooltipDayValue(tooltip.idx1,tooltip.idx2, 'fats')}}</strong>

          </b-tooltip>

        </b-overlay>
        <b-modal  id="add-food-meal-modal" ref="add-food-meal-modal" :size="dietPlanInfo.isTextOnly ? 'lg' : 'full'" title="Επεξεργασία γεύματος"
                 scrollable hide-footer :centered="dietPlanInfo.isTextOnly"  body-class="p-3" @hidden="foodMealModalClosed(addFoodModel.id)">

            <add-food-modal :diet-plan-id="dietPlanId" :meal-id="addFoodModel.id"
                            :foods="foods"
                            :is-text-only="dietPlanInfo.isTextOnly"
                            :micro-nutrients="dietPlanInfo.microNutrients"
                            :diet-plan-func="dietPlanInfo.dietPlanFunc"
                            :diet-plan-stats="dayStats" :is-fasting="dietPlanInfo.isFasting"
                            :is-vegan="dietPlanInfo.isVegan"
                            :is-carbohydration="dietPlanInfo.isCarbohydration"
                            :carbohydration-day="parseInt(dietPlanInfo.carbohydrationDay)"
                            :food-to-avoid="foodToAvoid"
                            @closeDialog="closeAddFoodModalDialog"></add-food-modal>
        </b-modal>

        <b-modal id="insert-from-template" ref="insert-from-template" size="full" title="Εισαγωγή από πρότυπο" hide-footer  @hidden="foodMealModalClosed(insertTemplate.toId)">
            <meal-template-list
                :is-text-only="dietPlanInfo.isTextOnly ? 'true' : 'false'"
                :open-category="insertTemplate.category" :should-insert="true" :insert-id="insertTemplate.toId"
                                @exit="$refs['insert-from-template'].hide()"></meal-template-list>
        </b-modal>


        <b-modal id="save-as-meal-template" ref="save-as-meal-template" size="small" title="Αποθήκευση ως πρότυπο γεύμα" hide-footer>
            <b-overlay :show="saveTemplate.savingTemplate" rounded="sm">
                <b-form @submit.prevent="saveMealAsTemplate">
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group id="template-name" label="Όνομα προτύπου" label-for="template-name">
                                <b-form-input id='template-name' v-model="saveTemplate.name"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <label for="type-select" class="mr-2">Τύπος γεύματος:</label>
                            <select id="type-select" v-model="saveTemplate.type" class="custom-select">
                                <option value='0'>Πρωινό</option>
                                <option value='1'>Δεκατιανό</option>
                                <option value='2'>Μεσημεριανό</option>
                                <option value='3'>Απογευματινό</option>
                                <option value='4'>Βραδινό</option>
                            </select>
                        </div>

                      <div class="col-12">
                        <vue-tags-input v-model="saveTemplate.tag" :tags="saveTemplate.tags" :delete-on-backspace="false" :autocomplete-items="filteredTags"
                                        placeholder="Προσθήκη..." @tags-changed="newTags => saveTemplate.tags = newTags">
                          <template slot="autocomplete-header"><strong>Επιλέξτε μια υπάρχουσα ετικέτα ↓</strong></template>
                        </vue-tags-input>
                      </div>

                        <div class="col-md-12">
                            <b-form-group id="button-group" class="mt-2 text-right">
                                <b-button type="submit" variant="primary" class="btn btn-success mt-2"><i class="fe-plus"></i> Αποθήκευση ως πρότυπο</b-button>
                            </b-form-group>
                        </div>


                    </div>
                </b-form>
            </b-overlay>
        </b-modal>

        <vue-context ref="menu">
            <template v-if="!dietPlanInfo.isSystem" slot-scope="child">
                <li>
                    <a @click.prevent="clickedMenu($event.target.innerText, child.data)">Αντιγραφή</a>
                </li>
                <li>
                    <a @click.prevent="clickedMenu($event.target.innerText, child.data)">Επικόλληση</a>
                </li>
              <li>
                <a @click.prevent="clickedMenu($event.target.innerText, child.data)">Επικόλληση χωρίς εκκαθάριση</a>
              </li>
                <li>
                    <a @click.prevent="clickedMenu($event.target.innerText, child.data)">Αποθήκευση ως πρότυπο</a>
                </li>
                <li>
                    <a @click.prevent="clickedMenu($event.target.innerText, child.data)">Εισαγωγή από πρότυπο</a>
                </li>
                <li>
                    <a @click.prevent="clickedMenu($event.target.innerText, child.data)">Εκκαθάριση</a>
                </li>
            </template>
        </vue-context>

        <vue-context ref="dayMenu">
            <template v-if="!dietPlanInfo.isSystem" slot-scope="child">
                <li>
                    <a @click.prevent="clickedDayMenu($event.target.innerText, child.data)">Αντιγραφή ημέρας</a>
                </li>
                <li v-if="copiedDay !== null">
                    <a @click.prevent="clickedDayMenu($event.target.innerText, child.data)">Επικόλληση ημέρας</a>
                </li>
                <li>
                    <a @click.prevent="clickedDayMenu($event.target.innerText, child.data)">Εκκαθάριση ημέρας</a>
                </li>
            </template>
        </vue-context>
    </div>

</template>
<style lang="scss">
    @import  '~vue-context/src/sass/vue-context';
    .v-context{
        a{
            cursor:pointer;
        }
        .invalid-food{
            opacity:0.5!important;
        }
    }
    .meal-category-text-only-notes{
      max-height:150px;
      overflow-y:auto;
    }
</style>
