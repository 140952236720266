<script>
    export default{
        props: {
            dietPlanInfo: {
                default: () => {
                    return {}
                },
                type: Object
            },
            edits: {
                default: () => {
                    return {}
                },
                type: Object
            }
        },

        data(){
            return{
                hasError: false,
                errorMsg: '',
                hasChanged: false
            }
        },

        computed:{
            isKeto(){
                return this.dietPlanInfo.isKeto;
            }
        },

        beforeDestroy() {
            if(this.edits.isFuncEdited) this.updateDietPlanMacronutrients();
        },

        methods:{

            updateDietPlanMacronutrients(){
                this.hasError = false;
                let nutrients = this.dietPlanInfo.dietPlanFunc.nutrients;
                if(parseFloat(nutrients.carbs.total) + parseFloat(nutrients.fats.total) + parseFloat(nutrients.proteins.total) !== parseFloat('100')){
                    this.hasError = true;
                    this.errorMsg = 'Το άθροισμα της συνολικής κατανομής σας δεν είναι 100%';
                    return;
                }

                if(parseFloat(this.calcPercentage('FAT')) !== parseFloat('100')){
                    this.hasError = true;
                    this.errorMsg = 'Το άθροισμα του λίπους δεν είναι 100%';
                    return;
                }

                if(parseFloat(this.calcPercentage('CARB')) !== parseFloat('100')){
                    this.hasError = true;
                    this.errorMsg = 'Το άθροισμα των υδατανθράκων δεν είναι 100%';
                    return;
                }

                if(parseFloat(this.calcPercentage('PROTEIN')) !== parseFloat('100')){
                    this.hasError = true;
                    this.errorMsg = 'Το άθροισμα των πρωτεϊνών δεν είναι 100%';
                    return;
                }

                if(this.isKeto && parseFloat(nutrients.fats.total) < 60){
                    this.hasError = true;
                    this.errorMsg = 'Όταν έχετε ενεργοποιημένη την κέτωση, δεν μπορεί το ποσοστό από τα λίπη να είναι κάτω από 60%';
                    return;
                }

                if(this.isKeto && parseFloat(nutrients.carbs.total) > 10){
                    this.hasError = true;
                    this.errorMsg = 'Όταν έχετε ενεργοποιημένη την κέτωση, δεν μπορεί το ποσοστό από τους υδατάνθρακες να είναι πάνω από 10%';
                    return;
                }


                this.$axios.put(`/dietPlan/${this.dietPlanInfo.id}/functions/nutrients`, {nutrients: nutrients}).then(result => {
                    this.edits.isFuncEdited = false;
                    this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: 'Το διατροφικό πλάνο ανανεώθηκε επιτυχώς!'});
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            markAsDirty(){
                this.edits.isFuncEdited = true;
            },
            calcPercentage(type) {
                let nutrient = this.dietPlanInfo.dietPlanFunc.nutrients;

                if (type === 'FAT') nutrient = nutrient.fats;
                if (type === 'CARB') nutrient = nutrient.carbs;
                if (type === 'PROTEIN') nutrient = nutrient.proteins;

                if(!parseFloat(nutrient.breakfast)) nutrient.breakfast = 0;
                if(!parseFloat(nutrient.dekatiano)) nutrient.dekatiano = 0;
                if(!parseFloat(nutrient.mesimeriano)) nutrient.mesimeriano = 0;
                if(!parseFloat(nutrient.apogevmatino)) nutrient.apogevmatino = 0;
                if(!parseFloat(nutrient.vradino)) nutrient.vradino = 0;

                return (parseFloat(nutrient.breakfast) + parseFloat(nutrient.dekatiano)
                    + parseFloat(nutrient.mesimeriano) + parseFloat(nutrient.apogevmatino)
                    + parseFloat(nutrient.vradino)).toFixed(0);
            },
            calculateNeeds(val, isFat){
                let kcal =  this.dietPlanInfo.dietPlanFunc.requiredKcal * val / 100;

                // fats are kcal divided by 9. carbs + proteins are divided by 4
                return isFat ? (kcal/9).toFixed(2) : (kcal/4).toFixed(2);
            },

            validateValue(value, isFat){
                value = this.replaceCommaWithDot(value);
                if(this.isKeto && isFat){
                    if(value < 60){
                        value = 60
                    }
                }
                return value;
            }
        }
    }
</script>

<template>

    <div id="program-macronutrients" class="card-body">
        <b-form v-if="dietPlanInfo.dietPlanFunc"  @submit.prevent="updateDietPlanMacronutrients" @input="markAsDirty">
            <div class="macronutrient-setup">
                <div class="row total-stats pb-3">
                    <div class="col-md-12">
                        <b-alert :show="isKeto" variant="primary" style="padding:5px;">
                            <img src="@assets/images/icons/keto-icon.png" width="35px" alt="icon-keto"/>
                            Έχετε ενεργοποιημένη την κέτωση!
                        </b-alert>
                    </div>
                    <div class="col-md-4">
                        <h5><i class="fas fa-chart-pie p-2"></i> Γενικά ποσοστά κατανομής μακροθρεπτικών συστατικών</h5><br>
                    </div>

                    <div class="col-md-2">
                        <label>Υδατάνθρακες ({{calculateNeeds(dietPlanInfo.dietPlanFunc.nutrients.carbs.total, false)}}g):</label>
                        <b-input-group append="%">
                            <b-form-input v-if="!dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" v-model="dietPlanInfo.dietPlanFunc.nutrients.carbs.total" type="text" placeholder="Ποσοστό υδατανθράκων"
                                           :state="!isKeto || (isKeto && dietPlanInfo.dietPlanFunc.nutrients.carbs.total <= 10)"
                                           :disabled="dietPlanInfo.isSystem"></b-form-input>
                          <b-form-input v-else type="text" :value="dietPlanInfo.dietPlanFunc.nutrients.carbs.total.toFixed(2)"
                                        disabled></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="col-md-2">
                        <label>Πρωτεϊνες ({{calculateNeeds(dietPlanInfo.dietPlanFunc.nutrients.proteins.total, false)}}g):</label>
                        <b-input-group append="%">
                            <b-form-input v-if="!dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" v-model="dietPlanInfo.dietPlanFunc.nutrients.proteins.total" type="text" placeholder="Ποσοστό πρωτεϊνών"
                                          :disabled="dietPlanInfo.isSystem"></b-form-input>

                          <b-form-input v-else type="text" :value="dietPlanInfo.dietPlanFunc.nutrients.proteins.total.toFixed(2)"
                                        disabled></b-form-input>
                        </b-input-group>
                    </div>
                    <div class="col-md-2">
                        <label>Λίπη ({{calculateNeeds(dietPlanInfo.dietPlanFunc.nutrients.fats.total, true)}}g):</label>
                        <b-input-group append="%">

                            <b-form-input v-if="!dietPlanInfo.dietPlanFunc.useMacroPerKgCalculations" v-model="dietPlanInfo.dietPlanFunc.nutrients.fats.total"
                                          type="text"
                                          :state="!isKeto || (isKeto && dietPlanInfo.dietPlanFunc.nutrients.fats.total >= 60)"
                                          placeholder="Ποσοστό λίπους"
                                          :disabled="dietPlanInfo.isSystem"></b-form-input>
                          <b-form-input v-else type="text" :value="dietPlanInfo.dietPlanFunc.nutrients.fats.total.toFixed(2)"
                                        disabled></b-form-input>
                        </b-input-group>
                    </div>


                </div>

                <hr>
                <div class="col-md-12 pb-3">
                    <div>
                        <label for="enableMealGoals"> <strong>Ενεργοποίηση κατανομής μακροθρεπτικών για στοχοθέτηση κάθε γεύματος</strong> </label>
                        <b-form-checkbox id="enableMealGoals" v-model="dietPlanInfo.dietPlanFunc.nutrients.enableMealGoals" name="enableMealGoals" switch size="lg" inline :disabled="dietPlanInfo.isSystem"></b-form-checkbox>
                    </div>

                 </div>
                <div v-show="dietPlanInfo.dietPlanFunc.nutrients.enableMealGoals" class="custom-meal-nutrients pt-1 ">
                    <div class="row amulouxa pb-3 align-items-baseline">
                        <div class="col-md-2">
                            <h5><img src="@assets/images/icons/icon-amulouxa-dark.png" alt="icon-apple" width="45px"/> Υδατάνθρακες</h5>
                            <div :class="(parseInt(calcPercentage('CARB')) === 100) ? 'accurate' : 'inaccurate' " class="text-center">{{calcPercentage('CARB')}} / 100 </div>
                        </div>
                        <div class="col-md-2">
                            <label>Πρωϊνό:</label>
                            <b-input-group append="%">
                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.carbs.breakfast" type="text" placeholder="Ποσοστό υδατανθράκων" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-md-2">
                            <label>Δεκατιανό:</label>
                            <b-input-group append="%">
                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.carbs.dekatiano" type="text" placeholder="Ποσοστό πρωτεϊνών"  :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-md-2">
                            <label>Μεσημεριανό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.carbs.mesimeriano" type="text" placeholder="Ποσοστό λίπους"  :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-md-2">
                            <label>Απογευματινό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.carbs.apogevmatino" type="text" placeholder="Ποσοστό λίπους"  :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-md-2">
                            <label>Βραδινό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.carbs.vradino" type="text" placeholder="Ποσοστό λίπους"  :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                    </div>


                    <div class="row protein pb-3 align-items-baseline">
                        <div class="col-md-2">
                            <h5><img src="@assets/images/icons/icon-protein-dark.png" alt="icon-apple" width="45px"/>Πρωτεϊνες</h5>
                            <div :class="(parseInt(calcPercentage('PROTEIN')) === 100) ? 'accurate' : 'inaccurate' " class="text-center">{{calcPercentage('PROTEIN')}} / 100 </div>
                        </div>
                        <div class="col-md-2">
                            <label>Πρωϊνό:</label>
                            <b-input-group append="%">
                                <b-form-input  v-model="dietPlanInfo.dietPlanFunc.nutrients.proteins.breakfast" type="text" placeholder="Ποσοστό υδατανθράκων" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-md-2">
                            <label>Δεκατιανό:</label>
                            <b-input-group append="%">
                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.proteins.dekatiano" type="text" placeholder="Ποσοστό πρωτεϊνών" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-md-2">
                            <label>Μεσημεριανό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.proteins.mesimeriano" type="text" placeholder="Ποσοστό λίπους" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-md-2">
                            <label>Απογευματινό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.proteins.apogevmatino" type="text" placeholder="Ποσοστό λίπους" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-md-2">
                            <label>Βραδινό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.proteins.vradino" type="text" placeholder="Ποσοστό λίπους" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                    </div>


                    <div class="row fats pb-3 align-items-baseline">
                        <div class="col-md-2">
                            <h5><img src="@assets/images/icons/icon-fats-dark.png" alt="icon-apple" width="45px"/> Λίπη</h5>
                            <div :class="(parseInt(calcPercentage('FAT')) === 100) ? 'accurate' : 'inaccurate' " class="text-center">{{calcPercentage('FAT')}} / 100 </div>
                        </div>
                        <div class="col-md-2">
                            <label>Πρωϊνό:</label>
                            <b-input-group append="%">
                                <b-form-input  v-model="dietPlanInfo.dietPlanFunc.nutrients.fats.breakfast" type="text" placeholder="Ποσοστό υδατανθράκων" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-md-2">
                            <label>Δεκατιανό:</label>
                            <b-input-group append="%">
                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.fats.dekatiano" type="text" placeholder="Ποσοστό πρωτεϊνών" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                        <div class="col-md-2">
                            <label>Μεσημεριανό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.fats.mesimeriano" type="text" placeholder="Ποσοστό λίπους" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-md-2">
                            <label>Απογευματινό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.fats.apogevmatino" type="text" placeholder="Ποσοστό λίπους" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>

                        <div class="col-md-2">
                            <label>Βραδινό:</label>
                            <b-input-group append="%">

                                <b-form-input v-model="dietPlanInfo.dietPlanFunc.nutrients.fats.vradino" type="text" placeholder="Ποσοστό λίπους" :disabled="dietPlanInfo.isSystem"></b-form-input>
                            </b-input-group>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <b-alert v-model="hasError" variant="danger" dismissible>{{errorMsg}}</b-alert>
                </div>
            </div>

            <b-form-group v-show="edits.isFuncEdited" id="buttons" class="mt-2 text-right">

              <save-button type="submit"></save-button>
            </b-form-group>
        </b-form>
    </div>

</template>
<style lang="scss">
    #program-macronutrients{
        .custom-meal-nutrients{
            padding-right:5rem;

        }
        input{
            padding:0.3rem;
            text-align: center;
        }
        .input-group-text{
            padding:0.3rem;
        }

        .inaccurate{
            padding:0.3rem;
            color:#7d2c38;
            background-color:#fcdde2;
        }
        .accurate{
            padding:0.3rem;
            color:#155724;
            background-color:#d4edda;
        }
    }


</style>
