var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.onePagePerDay)?_c('table',{staticClass:"table table-responsive table-borderless",staticStyle:{"table-layout":"fixed"},attrs:{"id":"dietPlan-table"}},[_c('tbody',{attrs:{"id":"print-diet-plan-table"}},[_c('td',{staticClass:"table-meal-day-name",staticStyle:{"border":"0!important","background-color":"transparent!important"}}),_vm._l((_vm.getActualHeaders),function(id){return _c('td',{key:id,staticClass:"meal-type-name",style:(`background-color:${_vm.options.colors.tableHeader};
            color:${_vm.options.colors.text.leftTitles};
              letter-spacing: ${_vm.options.letterSpacing.leftTitles}px;font-size:${_vm.options.sizes.text.leftTitles}px;padding:10px!important;`)},[_vm._v(" "+_vm._s(_vm.getMealName(_vm.dietPlanInfo, id))+" ")])}),_vm._l((_vm.getDietPlanDays),function(day,index){return _c('tr',{key:`day-`+index,style:(_vm.getTRStyles(index))},[_c('th',{staticClass:"table-meal-day-name",style:(`background-color:${_vm.options.colors.tableHeader};color:${_vm.options.colors.text.leftTitles};
              letter-spacing: ${_vm.options.letterSpacing.leftTitles}px;font-size:${_vm.options.sizes.text.leftTitles}px;`)},[_vm._v(" "+_vm._s(_vm.showNames ? _vm.options.translations.dayNames[day.day] : _vm.dayNamesAlt[day.day]))]),_vm._l((_vm.getActualRows(day.rows)),function(col,idx2){return _c('td',{key:idx2,staticClass:"table-meal",style:(_vm.getTDStyles(index, idx2))},[_c('div',{class:_vm.options.showCategoryColors ? 'category-colored' :'',staticStyle:{"padding":"3px"}},[_vm._l((_vm.dietPlan[day.day][col.type].foods),function(mealfood){return _c('div',{key:mealfood.id,staticClass:"table-meal-food",class:'meal-category-' + mealfood.category,style:(`font-size: ${_vm.options.sizes.text.foodText}px;color: ${_vm.options.colors.text.foodText};
                                    letter-spacing: ${_vm.options.letterSpacing.foodText}px; padding-bottom: ${_vm.options.bottomSpacing.foodText}px}`)},[_vm._v(" "+_vm._s(_vm.formatName(mealfood))+" "),(_vm.options.showQuantities && !mealfood.hideQuantity)?_c('span',[_vm._v(_vm._s(_vm.formatFoodUnits(mealfood.food)))]):_vm._e()])}),(_vm.dietPlan[day.day][col.type].notes && !_vm.dietPlanInfo.isTextOnly)?_c('div',{staticClass:"table-meal-food"},[_c('strong',[_vm._v("Σημειώσεις:")]),_vm._v(" "+_vm._s(_vm.dietPlan[day.day][col.type].notes)+" ")]):_vm._e(),(_vm.dietPlan[day.day][col.type].notes && _vm.dietPlanInfo.isTextOnly)?_c('div',{style:(`font-size: ${_vm.options.sizes.text.foodText}px!important`),domProps:{"innerHTML":_vm._s(_vm.dietPlan[day.day][col.type].notes)}}):_vm._e(),(_vm.dietPlanInfo.is_diabetes)?_c('div',{staticClass:"border-top mt-2 py-1"},[_c('div',{staticClass:"pl-1 table-meal-food",style:(`font-size: ${_vm.options.sizes.text.foodText}px!important;color: ${_vm.options.colors.text.foodText};`)},[_vm._v(" Μονάδες ινσουλίνης: "+_vm._s(_vm.getInsulinRatio(day.day, col.type)))])]):_vm._e()],2)])})],2)})],2)]):_c('div',_vm._l((_vm.getDietPlanDays),function(day,index){return _c('div',{key:`day-`+index},[(index > 0)?_c('div',{staticStyle:{"page-break-before":"always","clear":"both"}}):_vm._e(),_c('section',{staticClass:"pdf-item"},[_c('div',{staticClass:"table-meal-day-name",style:(`padding:10px!important;padding-right:initial!'}important;width:100%!important;background-color:${_vm.options.colors.tableHeader};color:${_vm.options.colors.text.leftTitles};
            letter-spacing: ${_vm.options.letterSpacing.leftTitles}px;font-size:${_vm.options.sizes.text.leftTitles}px;`)},[_c('span',[_vm._v(_vm._s(_vm.showNames ? _vm.options.translations.dayNames[day.day] : _vm.dayNamesAlt[day.day]))])]),_vm._l((_vm.getActualRows(day.rows)),function(col,idx2){return _c('div',{key:idx2,staticClass:"table-meal",staticStyle:{"padding-top":"20px","padding-left":"20px"}},[_c('h3',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.getMealName(_vm.dietPlanInfo,col.type )))]),_c('hr'),_vm._l((_vm.dietPlan[day.day][col.type].foods),function(mealfood){return _c('div',{key:mealfood.id,style:(`font-size: ${_vm.options.sizes.text.foodText}px;color: ${_vm.options.colors.text.foodText};
                                letter-spacing: ${_vm.options.letterSpacing.foodText}px; padding-bottom: ${_vm.options.bottomSpacing.foodText}px}`)},[_vm._v(" "+_vm._s(_vm.formatName(mealfood))+" "),(_vm.options.showQuantities  && !mealfood.hideQuantity)?_c('span',[_vm._v(_vm._s(_vm.formatFoodUnits(mealfood.food)))]):_vm._e()])}),(_vm.dietPlan[day.day][col.type].notes && !_vm.dietPlanInfo.isTextOnly)?_c('div',{style:(`font-size: ${_vm.options.sizes.text.foodText}px`)},[_c('strong',[_vm._v("Σημειώσεις:")]),_vm._v(" "+_vm._s(_vm.dietPlan[day.day][col.type].notes)+" ")]):_vm._e(),(_vm.dietPlan[day.day][col.type].notes && _vm.dietPlanInfo.isTextOnly)?_c('div',{style:(`font-size: ${_vm.options.sizes.text.foodText}px!important`),domProps:{"innerHTML":_vm._s(_vm.dietPlan[day.day][col.type].notes)}}):_vm._e(),(_vm.dietPlanInfo.is_diabetes)?_c('div',{staticClass:"border-top mt-2 py-1"},[_c('div',{staticClass:"pl-1",style:(`font-size: ${_vm.options.sizes.text.foodText}px!important;color: ${_vm.options.colors.text.foodText};`)},[_vm._v(" Μονάδες ινσουλίνης: "+_vm._s(_vm.getInsulinRatio(day.day, col.type)))])]):_vm._e()],2)})],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }