<template>
  <div>
    <b-modal ref="pdf-modal" title="Λίστα συνταγών στο διατροφικό πλάνο" size="md" hide-footer >
      <div v-if="isOpened" class="row dp-recipes-list p-3">

        <div v-for="recipe in recipes" :key="recipe.id" class="dp-recipe-item col-12 mb-2 p-2"
             :class="selectedRecipes.includes(recipe.id) ? 'active' :''"
             @click="toggleFromPrintList(recipe.id)">
          <div class="row align-items-center">
            <div class="col-9">
              <strong>{{recipe.name}}</strong>
            </div>
            <div class="col-3">
              <b-button variant="light" class="float-right" @click="printRecipe(recipe.id)"><i class="icon-printer"></i></b-button>
            </div>
          </div>

        </div>
        <div v-if="recipes.length === 0" class="col-12 mb-2 p-2">
          Δεν βρέθηκαν συνταγές σε αυτό το διατροφικό πλάνο!
        </div>
        <div v-else class="col-md-12 p-0 mt-4">

          <div>
            <b-button variant="primary" class="mt-1" :disabled="selectedRecipes.length === 0 || printLoading" @click="printRecipes(printTypes.print)">
              <span class="icon-printer"></span> Εκτύπωση</b-button>
            <b-button variant="outline-primary" :disabled="selectedRecipes.length === 0 || printLoading" class="ml-2 mt-1"  @click="printRecipes(printTypes.save)">
              <span class="fas fa-file-pdf"></span>
              Αποθήκευση</b-button>
            <b-button :variant="showEmailForm ? 'primary' : 'outline-primary'" class=" ml-2 mt-1 "
                      :disabled="selectedRecipes.length === 0 || printLoading || emailSent || isEducational" @click="showEmailForm = !showEmailForm">
              <span class="fe-mail"></span> Αποστολή με e-mail <b-badge v-if="emailSent" variant="success">Επιτυχία</b-badge>
            </b-button>
          </div>



          <send-to-email-form v-show="selectedRecipes.length > 0" :email="email" :show-email-form="showEmailForm" :email-sent="emailSent"
                              :saving-as-pdf="printLoading" @send="printRecipes(printTypes.email)"></send-to-email-form>


        </div>
      </div>
    </b-modal>

    <div v-show="isPrinting" id="print-area" ref="print-area">

      <div v-for="(recipe,index) in recipeData" :key="recipe.id">
      <div v-if="index > 0" style="page-break-before: always;clear:both;"></div>
        <print-header v-show="isPrinting" :title="'Συνταγή: <strong>' + recipe.name +'</strong>'"/>

        <div class="note-body">
          <div style="text-align:center;padding:1em 0;">
            <img v-if="recipe.image" :src="recipe.image" style="border-radius:12px;"/>
          </div>

          <h3>Υλικά:</h3>
          <b-table striped bordered hover :items="recipe.foods" :fields="fields" style="text-align:left!important;">
            <!-- A custom formatted column -->
            <template #cell(name)="data">{{data.item["mealFoods.hasAltName"] ? data.item["mealFoods.altName"]: data.item.name}}</template>
            <template #cell(quantity)="data"><strong>{{getQuantity(data.item)}}</strong></template>
          </b-table>
          <h3>Οδηγίες εκτέλεσης:</h3>
          <div v-html="recipe.execution"></div>
        </div>
      </div>

    </div>

  </div>
</template>
<style lang="scss">
.dp-recipes-list{
  > .col-12{
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0, rgba(27, 31, 35, 0.15) 0 0 0 1px;
    border-radius:8px;
  }
  max-height:450px;
  overflow-y:auto;

  .dp-recipe-item{
    cursor:pointer;
    &.active{
      background:$color_primary;
      color:#fff!important;
    }
  }
}
</style>
<script>

import {mapState} from "vuex";
import PrintMixin from "@src/mixins/PrintMixin";
import printHeader from '@components/print/header';
import SendToEmailForm from "@components/print/SendToEmailForm";

export default{

  components: {SendToEmailForm, printHeader},
  data(){
    return{
        fields: [
          { key: 'name', label: 'Όνομα' },
          { key: 'quantity', label: 'Ποσότητα' }
        ],
        isOpened: false,
        isPrinting: false,
      recipeData: [],
        isDownloading: false,
      selectedRecipes: [],
      showEmailForm: false,
      savingAsPdf: false,
      emailSent: false,

    }
  },
  mixins: [PrintMixin],
  computed: {
    ...mapState({
      user: state => state.auth.currentUser,
      dietPlan: state => state.pdf.dietPlan,
      dietPlanInfo: state => state.pdf.dietPlanInfo
    }),
    isLandscapePrint(){
      return this.options.orientation === 'landscape' && !this.options.onePagePerDay
    },
    recipes(){
      let recipes = new Set()
      this.dietPlan.map( day => {
        day.map( meal => {
          for(let food of meal.foods){
            // PUT OBJECTS IN RECIPES STRINGIFIED TO KEEP UNIQUENESS
            if(food.food.list === 3){
              recipes.add(JSON.stringify({
                name: food.food.name,
                id: food.food.recipeId}));
              }
          }
        })
      })
      // RETURN ARRAY WITH PARSED OBJECTS
      return Array.from(recipes).map(x => JSON.parse(x));
    }
  },
  methods:{
    getQuantity(food){
      return food['mealFoods.quantity'] * food.quantity + ' gr';
    },
    async fetchRecipe(recipeId, multiSelected = false){
      return this.$axios.get(`/recipes/${recipeId}`, ).then(result => {
        if(multiSelected) this.recipeData.push(result.data)
        else this.recipeData = [result.data];
        return result.data
      }).catch(e => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: 'Κάτι πήγε λάθος!',
          text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
      });

    },
    openPDFModal(){
      this.isOpened = true;
      this.$refs["pdf-modal"].show()
    },

    printRecipe(id){
       this.fetchRecipe(id, false).then(res=>{
         this.isPrinting = true;

         this.$nextTick(()=>{
           let divToPrint = this.$refs['print-area'].outerHTML;
           this.isPrinting=false;
           // remove empty paragraphs.
           divToPrint = divToPrint.replaceAll("<p><br></p>", "");
           divToPrint = divToPrint.replaceAll('display: none', "");
           this.print(divToPrint,this.printTypes.print, this.recipeData[0].title, 'recipe');
           this.recipeData = [];
         });
       });
    },

    async printRecipes(type = this.printTypes.print){
      for(let recipe of this.selectedRecipes) {
        await this.fetchRecipe(recipe, true);
      }
      this.isPrinting = true;
      this.$nextTick(()=>{
        let divToPrint = this.$refs['print-area'].outerHTML;
        this.isPrinting=false;
        // remove empty paragraphs.
        divToPrint = divToPrint.replaceAll("<p><br></p>", "");
        divToPrint = divToPrint.replaceAll('display: none', "");
        this.print(divToPrint,type, 'Λίστα συνταγών', 'recipe');
        this.recipeData = [];
      });
    },
    toggleFromPrintList(id){
      let idx = this.selectedRecipes.findIndex( x=> x === id);
      if( idx > -1 ){
        this.selectedRecipes.splice(idx, 1);
      }else{
        this.selectedRecipes.push(id);
      }
    }
  }
}
</script>
