<script>
    import axios from 'axios'
    import Vue from 'vue'
    import {mapState} from "vuex";

    export default{
        props: {
            dietPlanInfo: {
                default: () => {
                    return {}
                },
                type: Object
            },
            isAthlete: {
                default: false,
                type: Boolean
            },
            athleteId: {
                default: null,
                type: String
            }
        },

        data(){
            return{
                hasError: false,
                errorMsg: '',
                dayExercises: [],
                insertExerciseError: '',
                addSportModal: {
                    exerciseId: null,
                    sportId: null,
                    duration: null,
                    day: null
                },
                addingSport: false,
                rendered: false,
                supplementsModal:{
                    sport: null
                }
            }
        },

        computed:{
          ...mapState({
            sports: state => state.sports._sports
          })
        },
        created(){
            // fill
            for(let i=0;i<7;i++){
                this.dayExercises[i] = {};
            }
            this.fillExercisesArray();
        },

        methods:{

            fillExercisesArray(){
                if(!this.isAthlete){

                    for(let i=0;i<7;i++){
                        Vue.set(this.dayExercises, i, this.dietPlanInfo.exercises.find(exercise => {return exercise.day === i}));
                       // this.dayExercises[i] = this.dietPlanInfo.exercises.find(exercise => {return exercise.day === i});
                        this.fetchExerciseSports(this.dayExercises[i].id, i);
                    }
                    this.rendered = true;

                }else {

                    this.$axios.get(`/exercises/athlete/${this.athleteId}/sports/`).then(result => {
                        let exercises = result.data;

                        for(let i=0;i<7;i++){
                            Vue.set(this.dayExercises, i,  exercises.find(exercise => {return exercise.day === i}));
                            this.fetchExerciseSports(this.dayExercises[i].id, i);
                        }
                        this.rendered = true;

                    });
                }


            },

            fetchExerciseSports(exerciseId, day){

                this.$axios.get(`/exercises/${exerciseId}/sports`).then(result =>{
                    let sports = result.data.exerciseSports;
                    for(let sport of sports){
                        sport.sport = JSON.parse(JSON.stringify(this.sports.find(sp => sp.id === sport.sportId )));
                    }

                    Vue.set(this.dayExercises[day], 'sports', sports);

                })

            },

            addExerciseSport(){
                this.addingSport = true;

                let exerciseId = this.addSportModal.exerciseId;
                let sportId = this.addSportModal.sportId;
                let duration = parseInt(this.addSportModal.duration);
                let weight = this.isAthlete ? 0 : this.dietPlanInfo.dietPlanFunc.weight;
                this.insertExerciseError = '';

                if(!sportId){
                    this.insertExerciseError = 'Παρακαλώ εισάγετε άθλημα';
                    this.addingSport = false;
                    return;
                }
                if(!duration || duration <= 0  ){
                    this.insertExerciseError = 'Παρακαλώ εισάγετε τη διάρκεια του αθλήματος';
                    this.addingSport = false;
                    return;
                }

                axios.post(`${process.env.VUE_APP_API}/exercises/${exerciseId}/sports`, {sportId, duration, weight}).then(result => {
                    if(!this.isAthlete) this.dietPlanInfo.dietPlanFunc.caloricNeeds[this.addSportModal.day] = result.data.caloricNeeds;
                    this.addingSport = false;
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το άθλημα προστέθηκε επιτυχώς!'});
                    this.closeAddSportModal();
                }).catch(e=>{
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η προσθήκη του αθλήματος απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },


            removeExerciseSport(exerciseId, exerciseSport, day){
                axios.delete(`${process.env.VUE_APP_API}/exercises/${exerciseId}/sports/${exerciseSport}`).then(result=>{
                    if(!this.isAthlete) this.dietPlanInfo.dietPlanFunc.caloricNeeds[day] = result.data.caloricNeeds;
                    this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το άθλημα αφαιρέθηκε επιτυχώς!'});
                    this.fetchExerciseSports(exerciseId, day);
                }).catch( e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η αφαίρεση του αθλήματος απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            openAddSportModal(exerciseId, day){
                this.insertExerciseError = '';
                this.addSportModal.exerciseId = exerciseId;
                this.addSportModal.day = day;
                this.$refs['add-sport-modal'].show();
            },

            closeAddSportModal(){
                this.$refs['add-sport-modal'].hide();
            },

            sportModalClosed(){
                this.fetchExerciseSports(this.addSportModal.exerciseId, this.addSportModal.day);
                this.addSportModal.exerciseId = null;
                this.addSportModal.day = null;
            },

            getSupplements(list, type){
                if(type===1){
                    return list.supplements.filter(x => x.isBefore === 1);
                }

                if(type === 2){
                    return list.supplements.filter(x => x.isDuring === 1);
                }

                if(type === 3){
                    return list.supplements.filter(x => x.isAfter === 1);
                }
            },
            openSupplementsModal(sport){
                this.supplementsModal.sport = sport;
                this.$refs['supplements-modal'].show();
            }
        }
    }
</script>
<style lang="scss">
    .supplement-card{
        box-shadow: 0.075rem 0.125rem 0.25rem rgba(0, 0, 0, 0.2) !important;
        border-radius: 9px;
        margin-bottom:8px;
        .card-body{
            padding:0.5rem;
        }

    }
</style>

<template>

    <div id="diet-exercises" class="card-body">

        <div v-if="rendered" class="row">
            <div v-for="ex in dayExercises" :key="ex.id" class="col-md-6 exercise">
                <div class="title-exercise">{{dayNames[ex.day]}}  <span v-if="!isAthlete && dietPlanInfo.dietPlanFunc.caloricNeeds[ex.day] > 0">({{ dietPlanInfo.dietPlanFunc.caloricNeeds[ex.day].toFixed(2)}} kcal) </span></div>

                <div v-for="sport in ex.sports" :key="sport.id" class="sport">
                    <div class="row">
                        <div class="col-md-8">
                            <i class="icon-energy"></i> Άθλημα: <strong> {{sport.sport.name}}</strong><br>
                            <i class="icon-clock"></i> Διάρκεια: <strong>{{sport.duration}} λεπτά</strong>


                        </div>
                        <div class="col-md-4">
                            <b-button variant="primary" class="btn-xs mr-1" @click="openSupplementsModal(sport)">Συμπληρώματα</b-button>
                            <b-button variant="danger" class="btn-xs" :disabled="dietPlanInfo.isSystem"  @click="removeExerciseSport(ex.id, sport.id, ex.day)" ><i class="icon-trash"></i></b-button>
                        </div>
                    </div>


                </div>

                <div class="add-extra-sport">
                    <b-button variant="btn-link" :disabled="dietPlanInfo.isSystem" @click="openAddSportModal(ex.id, ex.day)"><i class="icon-plus"></i> Προσθήκη αθλήματος / άσκησης</b-button>
                </div>

            </div>


        </div>

        <div class="row">
            <div class="col-md-12 p-2">
                <b-alert v-model="hasError" variant="danger" dismissible>{{errorMsg}}</b-alert>
            </div>

        </div>

        <b-modal id="supplements-modal" ref="supplements-modal" title="Προτεινόμενα συμπληρώματα" size="xl" hide-footer>
            <div v-if="supplementsModal.sport">
                <div class="row">
                    <div class="col-md-6">
                        <div class="before-workout">
                            <p class="pt-2 pb-2 pl-2"><strong><i class="icon-arrow-left-circle"></i> Πριν την προπόνηση: </strong></p>
                            <b-card v-for="sup in getSupplements(supplementsModal.sport, 1)" :key="sup.id" class="shadow-sm supplement-card mr-1 mb-2">
                                <div class="row align-items-center">
                                    <div class="col-md-2 ">
                                        <img src="@assets/images/BioWhey-Chocolate.jpg" width="50px"/>
                                    </div>
                                    <div class="col-md-3">
                                        {{sup.name}}
                                    </div>
                                    <div class="col-md-7">
                                        <span class="text-muted">Δοσολογία:</span> {{sup.dosage}}
                                    </div>
                                </div>

                            </b-card>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="during-workout">
                            <p class="pt-2 pb-2 pl-2"><strong><i class="icon-arrow-down-circle"></i> Κατά τη διάρκεια της προπόνησης:</strong></p>
                            <b-card v-for="sup in getSupplements(supplementsModal.sport, 2)" :key="sup.id" class="shadow-sm supplement-card mr-1 mb-2">
                                <div class="row align-items-center">
                                    <div class="col-md-2">
                                        <img src="@assets/images/BioWhey-Chocolate.jpg" width="50px"/>
                                    </div>
                                    <div class="col-md-3">
                                        {{sup.name}}
                                    </div>
                                    <div class="col-md-7">
                                        <span class="text-muted">Δοσολογία:</span> {{sup.dosage}}
                                    </div>
                                </div>

                            </b-card>
                        </div>
                        <div class="after-workout">
                            <p class="pt-2 pb-2 pl-2"><strong> <i class="icon-arrow-right-circle"></i> Μετά την προπόνηση </strong></p>
                            <b-card v-for="sup in getSupplements(supplementsModal.sport, 3)" :key="sup.id" class="shadow-sm supplement-card mr-1 mb-2">
                                <div class="row align-items-center">
                                    <div class="col-md-2">
                                        <img src="@assets/images/BioWhey-Chocolate.jpg" width="50px"/>
                                    </div>
                                    <div class="col-md-3"> {{sup.name}} </div>
                                    <div class="col-md-7"><span class="text-muted">Δοσολογία:</span> {{sup.dosage}}</div>
                                </div>

                            </b-card>
                        </div>
                    </div>
                </div>



            </div>
        </b-modal>

        <b-modal id="add-sport-modal" ref="add-sport-modal" title="Προσθήκη αθλήματος" hide-footer body-class="p-3" @hidden="sportModalClosed">
            <b-overlay :show="addingSport" rounded="sm">
                <b-form @submit.prevent="addExerciseSport">
                    <div class="row">
                        <div class="col-md-12">
                            Άθλημα:
                            <v-select
                                v-model="addSportModal.sportId"
                                placeholder="Πληκτρολόγηση αθλήματος..."
                                label = "name"
                                :options = "sports"
                                :value = "addSportModal.sportId"
                                :reduce="sport => sport.id"></v-select>

                        </div>
                        <div class="col-md-12">
                            Διάρκεια σε λεπτά:
                                <b-form-input id="edit-duration" v-model="addSportModal.duration" type="text" placeholder="Διάρκεια προπόνησης σε λεπτά"></b-form-input>
                        </div>
                        <div class="col-md-12 text-right">
                            <br>
                            <b-button type="submit" variant="primary" :disabled="addingSport"><i class="icon-plus"></i> Προσθήκη αθλήματος</b-button>
                        </div>
                        <div class="col-md-12 pt-2">
                            <b-alert v-if="insertExerciseError.length > 0" variant="danger" show>{{insertExerciseError}}</b-alert>
                        </div>

                    </div>

                </b-form>
            </b-overlay>
        </b-modal>
    </div>

</template>
