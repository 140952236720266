<template>

  <div class="row p-4 justify-content-center align-items-center " style="padding-top:0!important">
    <div class="col-md-6">
      <p>Εισάγετε σημειώσεις για το διατροφικό πλάνο που μπορείτε να χρησιμοποιήσετε κατά την εκτύπωση</p>
    </div>

    <div class="col-md-6 text-right">
      <b-button v-b-tooltip.hover variant="outline-dark" class="btn mr-1" title="Αποθήκευση ως πρότυπο" @click="saveAsTemplate"><i class="fe-save"></i></b-button>
      <b-button v-b-tooltip.hover variant="outline-dark" class="btn mr-1" title="Εισαγωγή από πρότυπο" @click="insertFromTemplate"><i class="fe-arrow-down"></i></b-button>
    </div>

    <div class="col-md-12 mt-4">

      <quill-editor v-if="hasLoaded" ref="noteEditor" v-model="notes" :options="quillOptions" @change="notesChanged = true"/>

      <div class="text-right mt-2">
        <save-button type="submit" :disabled="!notesChanged" @click="updateNotes"></save-button>
      </div>


    </div>
    <b-modal id="save-template" ref="save-template" size="medium" title="Αποθήκευση ως πρότυπο" hide-footer body-class="p-3">
      <b-form>
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Όνομα πρότυπου:" label-for="edit-name">
              <b-input-group>
                <b-form-input id="edit-name" v-model="name" type="text" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-form-group class="action-btns text-right">
          <save-button @click="saveTemplate" text="Αποθήκευση ως πρότυπο" :disabled="!name"></save-button>
        </b-form-group>

      </b-form>
    </b-modal>
    <b-modal id="insert-from-template" ref="insert-from-template" size="medium" title="Εισαγωγή από πρότυπο" hide-footer body-class="p-3">

      <b-form-checkbox variant="primary" v-model="replaceContent" name="replace-content" switch class="mb-2">
        Αντικατάσταση περιεχομένου;
      </b-form-checkbox>


      <b-input v-model="search" placeholder="Αναζήτηση προτύπου..." class="mb-3 " @input="searchByText"/>
      <div class="notes-results-wrapper">
        <b-card  v-for="note in notesArray" :key="note.id" class="shadow-sm">
          <b-card-text>

            <div class="row justify-content-center align-items-center" style="max-height:400px">
              <div class="col-md-6">
                <span class="small-size">Όνομα προτύπου:</span> {{note.name}}
              </div>
              <div class="col-md-6">
                <span class="small-size"></span>
                <b-button  variant="primary" size="xs" @click="insertTemplate(note)" > Εισαγωγή στις σημειώσεις</b-button>
              </div>
            </div>

          </b-card-text>
        </b-card>
      </div>
      <div class="load-more-btn">
        <b-button v-if="notesArray.length < notesList.length && search === ''" variant='primary' size="sm" @click="loadMore">Φόρτωση περισσότερών...</b-button>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
.ql-editor{
  min-height: 200px;
}
.notes-results-wrapper{
  max-height:500px;
  overflow-y:auto;
}
</style>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {mapState} from "vuex"

export default{

  components: {quillEditor},

  props:{
    edits: {
      default: () => {
        return {}
      },
      type: Object
    },
    content: {
      type:String,
      required: true
    }

  },
  computed:{
    ...mapState({
      notesList: state => state.notes.notes
    })
  },

  data(){
    return {
      notesChanged: false,
      hasLoaded: false,
      notes: '',
      name: '',
      notesArray: [],
      page: 1,
      limit: 5,
      search: '',
      replaceContent: false
    }
  },

  beforeDestroy() {
    if(this.notesChanged) this.$emit('contentChanged', this.notes);
  },

  mounted(){
    this.notes = this.content;
    this.hasLoaded= true;
    this.initializeNotesList();
  },
  methods:{
    initializeNotesList(){
      this.page = 1;
      this.notesArray = this.notesList.slice((this.page-1)*this.limit, (this.page)*this.limit);
    },
    loadNotesList(){
      this.notesArray.push(...this.notesList.slice((this.page-1)*this.limit, (this.page)*this.limit));
    },
    saveAsTemplate(){
      this.$refs['save-template'].show();
    },
    insertFromTemplate(){
      this.initializeNotesList();
      this.$refs['insert-from-template'].show();
    },
    insertTemplate(template){

      if(this.replaceContent){
        this.notes = template.content.replaceAll('"', "");
      }else{
        if(!this.notes) this.notes =  "";
        this.notes += template.content.replaceAll('"', "");
      }

      this.$refs['insert-from-template'].hide();
    },
    saveTemplate(){
      if(this.name.length === 0) return;

      let template = {
        name: this.name,
        content: this.notes
      }
      this.$store.dispatch('notes/addNote', template).then(res => {
        this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το πρότυπο δημιουργήθηκε επιτυχώς!'});
        this.$refs['save-template'].hide()
      }).catch( () => {
        this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η δημιουργία απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});

      });
    },
    updateNotes(){
      this.$emit('contentChanged', this.notes);
      this.notesChanged = false;
    },
    loadMore(){
      this.page++;
      this.loadNotesList();
    },

    searchByText(){
      if(this.search === '') return this.initializeNotesList();
      this.page = 1;
      this.notesArray = this.notesList.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));

    }
  }

}
</script>
